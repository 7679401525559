<template>
    <nav>
        <router-link to="/">MudBots</router-link>
        <!-- Admin-only Links -->
        <template v-if="userEmployeeStore.currentUser?.user.is_superuser">
            <DropdownMenu title="Tasks">
                <router-link to="/ops/">My Tasks
                    <p>Show List of My Tasks which are either completed or active</p>
                </router-link>
                <router-link to="/ops/alltasks">All Tasks
                    <p>Show all Tasks currently in the system assigned to all the User Employees.</p>
                </router-link>
                <router-link to="/ops/create-task/1">Create Employee Tasks
                    <p>Create Task from Template associate with a job and Assign User to task.</p>
                </router-link>
                <router-link to="/ops/create-task-employee/1">Create Personal Task
                    <p>Create Task from Template for personal tasks which wont be associated to any job.</p>
                </router-link>
                <router-link to="/ops/create-task-template">Create Task Template
                    <p>Create the Task template for the Task we are going to create which will add some predefined
                        properties to task</p>
                </router-link>
            </DropdownMenu>
            <DropdownMenu title="Jobs">
                <router-link to="/ops/alljobs">All Jobs
                    <p>Show All Jobs currently present in the system with start date and end date.</p>
                </router-link>
                <router-link to="/ops/create-job/1">Create Job
                    <p>Create Job from Template and Create tasks Associated with that job.</p>
                </router-link>
                <router-link to="/ops/create-job-template" class="job-link">Create Job Template
                    <p>Create the Job template for the job we are going to create which will add some predefined
                        properties
                        to job</p>
                </router-link>
            </DropdownMenu>
            <DropdownMenu title="User">
                <router-link to="/ops/allusers">User List
                    <p>Show list of all Users which are in the System with Basic Information from where we can see the
                        full
                        profile of the user, delete user or can generate pdf Report of user.</p>
                </router-link>
                <router-link to="/ops/create-user">Create User
                    <p>Create User or User Employee who will be able to use system.</p>
                </router-link>
                <router-link to="/ops/Adduseremployee">Employee Salaries
                    <p>Make Changes in the Hourly Pay, Work Center and Status of the employee which is present in the
                        system.
                    </p>
                </router-link>
            </DropdownMenu>
            <DropdownMenu title="Utilities">
                <router-link to="/ops/work-cost-center">Add Work & Cost Center
                    <p>Add the WorkCenter and CostCenter which we are going to add to various fileds</p>
                </router-link>
                <router-link to="/ops/work-and-cost-center">Work & Cost Center
                    <p>See WorkCenters and CostCenters in the system.</p>
                </router-link>
                <router-link to="/ops/gantt-chart">Gantt Chart
                    <p>Get Visual representation of progress Jobs and Tasks with the help of Gantt Chart</p>
                </router-link>
                <router-link to="/ops/Calender">Calendar
                    <p>Scheduling and Reminders of Tasks and jobs with help of Calendar</p>
                </router-link>
                <router-link to="/ops/admindashboard">Costing
                    <p>Get Total Active Hours spent On Tasks and Jobs and also get Costing of all Jobs and Tasks</p>
                </router-link>
                <router-link to="/ops/job-task-template">Job & Task Templates
                    <p>Manage and create new job and task templates.</p>
                </router-link>
                <a href="/admin/" target="_blank">Admin
                    <p>Visit the Admin Backend of the Project</p>
                </a>
            </DropdownMenu>
            <a class="profile-container" href="/app/ops/user-profile" @click.prevent="toggleUserProfile">Profile</a>
            <div v-if="showUserProfile" class="user-profile-popup">
                <AppProfileCard />
            </div>
        </template>
        <!-- Manager-only Links -->
        <template
            v-if="userEmployeeStore.currentUser?.user.is_staff && !userEmployeeStore.currentUser?.user.is_superuser">
            <DropdownMenu title="Tasks">
                <router-link to="/ops/">My Tasks
                    <p>Show List of My Tasks which are either completed or active</p>
                </router-link>
                <router-link to="/ops/alltasks">All Tasks
                    <p>Show all Tasks currently in the system assigned to all the User Employees.</p>
                </router-link>
                <router-link to="/ops/create-task/1">Create Employee Tasks
                    <p>Create Task from Template associate with a job and Assign User to task.</p>
                </router-link>
                <router-link to="/ops/create-task-employee/1">Create Personal Task
                    <p>Create Task from Template for personal tasks which wont be associated to any job.</p>
                </router-link>
                <router-link to="/ops/create-task-template">Create Task Template
                    <p>Create the Task template for the Task we are going to create which will add some predefined
                        properties to task</p>
                </router-link>
            </DropdownMenu>
            <DropdownMenu title="Jobs">
                <router-link to="/ops/alljobs">All Jobs
                    <p>Show All Jobs currently present in the system with start date and end date.</p>
                </router-link>
                <router-link to="/ops/create-job/1">Create Job
                    <p>Create Job from Template and Create tasks Associated with that job.</p>
                </router-link>
                <router-link to="/ops/create-job-template" class="job-link">Create Job Template
                    <p>Create the Job template for the job we are going to create which will add some predefined
                        properties
                        to job</p>
                </router-link>
            </DropdownMenu>
            <DropdownMenu title="User">
                <router-link to="/ops/allusers">User List
                    <p>Show list of all Users which are in the System with Basic Information from where we can see the
                        full
                        profile of the user, delete user or can generate pdf Report of user.</p>
                </router-link>
            </DropdownMenu>
            <DropdownMenu title="Utilities">
                <router-link to="/ops/job-task-template">Job & Task Templates
                    <p>Manage and create new job and task templates.</p>
                </router-link>
                <router-link to="/ops/work-cost-center">Add Work & Cost Center
                    <p>Add the WorkCenter and CostCenter which we are going to add to various fileds</p>
                </router-link>
                <router-link to="/ops/work-and-cost-center">Work & Cost Center
                    <p>See WorkCenters and CostCenters in the system.</p>
                </router-link>
                <router-link to="/ops/gantt-chart">Gantt Chart
                    <p>Get Visual representation of progress Jobs and Tasks with the help of Gantt Chart</p>
                </router-link>
                <router-link to="/ops/Calender">Calendar
                    <p>Scheduling and Reminders of Tasks and jobs with help of Calendar</p>
                </router-link>
            </DropdownMenu>
            <a class="profile-container" href="/app/ops/user-profile" @click.prevent="toggleUserProfile">Profile</a>
            <div v-if="showUserProfile" class="user-profile-popup">
                <AppProfileCard />
            </div>
        </template>
        <!-- Non-Admin Link -->
        <template
            v-if="!userEmployeeStore.currentUser?.user.is_superuser && !userEmployeeStore.currentUser?.user.is_staff">
            <DropdownMenu title="Tasks">
                <router-link to="/ops/">My Tasks
                    <p>Show List of My Tasks which are either completed or active</p>
                </router-link>
                <router-link to="/ops/create-task-employee/1">Create Task
                    <p>Create Task from Template for personal tasks which wont be associated to any job.</p>
                </router-link>
            </DropdownMenu>
            <router-link to="/ops/Calender">Calendar</router-link>
            <a class="profile-container" href="/app/ops/user-profile" @click.prevent="toggleUserProfile">Profile</a>
            <div v-if="showUserProfile" class="user-profile-popup">
                <AppProfileCard />
            </div>
        </template>
    </nav>
</template>
<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import { useOpsSupplementStore } from '@/stores/OpsSupplement';
import { useTaskStore } from '@/stores/Tasks';
import { useEmployeeStore } from '@/stores/UserEmployee';
import DropdownMenu from '@/components/DropdownMenu.vue'
import AppProfileCard from '@/components/AppProfileCard.vue';

const opsSupp = useOpsSupplementStore();
const taskStore = useTaskStore();
const userEmployeeStore = useEmployeeStore();
const showUserProfile = ref(false);

opsSupp.fetch();
taskStore.fetch();
taskStore.getAllTasks();

const dropdowns = ref({
    tasks: false,
    jobs: false,
    utilities: false,
    users: false
});

userEmployeeStore.fetch()
    .catch(() => {
        window.location.href = "/accounts/login/?next=" + window.location.pathname;
    });

const toggleDropdown = (dropdownName, event) => {
    event.stopPropagation();
    dropdowns.value[dropdownName] = !dropdowns.value[dropdownName];
    for (const key in dropdowns.value) {
        if (key !== dropdownName) {
            dropdowns.value[key] = false;
        }
    }
};

const closeAllDropdowns = () => {
    for (const dropdown in dropdowns.value) {
        dropdowns.value[dropdown] = false;
    }
};

const handleOutsideClick = (event) => {
    const dropdownElements = document.querySelectorAll('.dropdown');
    let isClickInsideDropdown = false;

    dropdownElements.forEach((dropdownElement) => {
        if (dropdownElement.contains(event.target)) {
            isClickInsideDropdown = true;
        }
    });
    if (!isClickInsideDropdown) {
        closeAllDropdowns();
    }
};

const toggleUserProfile = (event) => {
    event.stopPropagation();
    showUserProfile.value = !showUserProfile.value;
};

const handleClickOutside = (event) => {
    if (!event.target.closest('.profile-container')) {
        showUserProfile.value = false;
    }
};

onMounted(() => {
    document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside);
});
</script>

<style scoped>
nav {
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: nowrap;
    background-color: rgba(236, 236, 236, 0.7);
    padding: 20px 40px;
    z-index: 1000;
    width: 100%;
    position: relative;
    align-items: center;
    border-bottom: #0a0a0a;
}

nav .profile-container {
    margin-left: auto;
    position: relative;
    order: 2;
}

a,
router-link {
    display: inline-block;
    padding: 10px 20px;
    margin: 10px 10px 10px 10px;
    border-radius: 25px;
    background-color: #f7f4f4;
    text-align: left;
    text-decoration: none;
    font-weight: 600;
    transition: all 0.3s ease;
    transform: scale(1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

a:hover,
router-link:hover {
    color: #FFFFFF;
    background-color: rgb(75, 82, 219);
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

a:active,
router-link:active {
    transform: scale(0.95);
}

a>p,
router-link>p {
    font-size: 0.8rem;
    color: #333;
    margin-top: 4px;
    text-decoration: none;
}

a:hover>p,
router-link:hover>p {
    color: #ffffff;
}

.profile-container {
    display: flex;
    position: static;
    flex-shrink: 0;

}

.user-profile-popup {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 10;
}
</style>