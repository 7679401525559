<template>
  <span>{{ formattedDuration }}</span>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, defineProps } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';

const props = defineProps<{
  duration: string,
  eventType: string,
  taskId: number
}>();

const elapsedTime = ref(0);
const timerId = ref<number | null>(null);

function parseDurationToSeconds(durationStr: string): number {
  const [hours, minutes, seconds] = durationStr.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}

const formattedDuration = computed(() => {
  const hours = Math.floor(elapsedTime.value / 3600);
  const minutes = Math.floor((elapsedTime.value % 3600) / 60);
  const seconds = elapsedTime.value % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
});

function updateElapsedTime() {
  elapsedTime.value += 1;
}

function startTimer() {
  if (timerId.value !== null) clearInterval(timerId.value);
  localStorage.setItem(`startTime-${props.taskId}`, Date.now().toString());
  timerId.value = setInterval(updateElapsedTime, 1000);
}

function stopTimer() {
  if (timerId.value !== null) {
    clearInterval(timerId.value);
    timerId.value = null;
    const currentTime = Date.now();
    localStorage.setItem(`elapsedTime-${props.taskId}`, elapsedTime.value.toString());
    localStorage.setItem(`stopTime-${props.taskId}`, currentTime.toString());
  }
}

function resumeWithActiveTimer() {
  const savedElapsedTime = parseInt(localStorage.getItem(`elapsedTime-${props.taskId}`) || '0');
  const savedUnloadTime = parseInt(localStorage.getItem(`unloadTime-${props.taskId}`) || '0');
  const current = Date.now();
  const adjustedElapsedTime = savedElapsedTime + Math.floor((current - savedUnloadTime) / 1000);

  elapsedTime.value = adjustedElapsedTime;
  if (timerId.value !== null) clearInterval(timerId.value);
  timerId.value = setInterval(updateElapsedTime, 1000);
}

watch(() => props.duration, (newDuration) => {
  elapsedTime.value = parseDurationToSeconds(newDuration);
}, { immediate: true });

watch(() => props.eventType, (newEventType) => {
  if (newEventType === 'ST' || newEventType === 'RE') {
    startTimer();
  } else {
    stopTimer();
  }
}, { immediate: true });

onMounted(() => {
  const isRunning = props.eventType === 'ST' || props.eventType === 'RE';
  if (isRunning) {
    resumeWithActiveTimer();
  } else {
    elapsedTime.value = parseDurationToSeconds(props.duration);
  }
});

function saveState() {
  const currentTime = Date.now();
  localStorage.setItem(`elapsedTime-${props.taskId}`, elapsedTime.value.toString());
  localStorage.setItem(`unloadTime-${props.taskId}`, currentTime.toString());
  if (timerId.value !== null) {
    clearInterval(timerId.value);
    timerId.value = null;
  }
}

onBeforeRouteLeave(() => {
  saveState();
});

window.addEventListener('beforeunload', () => {
  saveState();
});
</script>


