<template>
    <div v-for="event in paginatedEvents" :key="event.id">
        <div class="row badge-row">
            <div class="col-2">
                <div v-if="event.event_type === 'ST'" class="col-2 badge-container">
                    <span class="badge badge-pill bg-success">Started</span>
                </div>
                <div v-if="event.event_type === 'PA'" class="col-2 badge-container">
                    <span class="badge badge-pill bg-warning">Paused</span>
                </div>
                <div v-if="event.event_type === 'IN'" class="col-2 badge-container">
                    <span class="badge badge-pill bg-danger">Interrupted</span>
                </div>
                <div v-if="event.event_type === 'RE'" class="col-2 badge-container">
                    <span class="badge badge-pill bg-info">Resumed</span>
                </div>
                <div v-if="event.event_type === 'CO'" class="col-2 badge-container">
                    <span class="badge badge-pill bg-primary">Concluded</span>
                </div>
            </div>

            <div class="col-2 date-time-badge">
                <DateTimeBadge :time="event.timestamp" />
            </div>
            <div class="col-2 user-badge">
                <UserBadge :user-id="event.employee" />
            </div>
            <div class="col-2 text-start description">
                <b>
                    <HideOverflowDescription :text="event.notes" />
                </b>
            </div>
        </div>
    </div>
    <nav class="pagination-controls">
        <ul class="pagination">
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <button class="page-link" @click="changePage(currentPage - 1)"
                    @click.prevent="changePage(currentPage - 1)">Previous
                </button>
            </li>
            <li class="page-item" v-for="page in totalPages" :key="`page-${page}`"
                :class="{ active: currentPage === page }">
                <button class="page-link" @click="changePage(page)">{{ page }}
                </button>
            </li>
            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                <button class="page-link" @click="changePage(currentPage + 1)">Next
                </button>
            </li>
        </ul>
    </nav>
</template>

<script setup lang="ts">
import { TaskEvent } from '@/stores/Tasks';
import { useEmployeeStore } from '@/stores/UserEmployee';
import { computed, ComputedRef, Ref, defineProps, ref } from 'vue';
import UserBadge from '@/components/UserBadge.vue';
import DateTimeBadge from '@/components/DateTimeBadge.vue';
import HideOverflowDescription from '@/components/HideOverflowDescription.vue';

const employeeStore = useEmployeeStore();
const props = defineProps<{
    events: TaskEvent[]; // Now expects an array, not a single event
}>();


const currentPage: Ref<number> = ref(1);
const eventsPerPage: Ref<number> = ref(10);
const totalEvents: ComputedRef<number> = computed(() => props.events?.length);
const totalPages: ComputedRef<number> = computed(() => Math.ceil(totalEvents.value / eventsPerPage.value));

const paginatedEvents = computed(() => {
    if (!props.events) {
        return []; // Return an empty array if events are undefined
    }
    const startIndex = (currentPage.value - 1) * eventsPerPage.value;
    const endIndex = startIndex + eventsPerPage.value;
    return props.events.slice(startIndex, endIndex);
});


const changePage = (newPage: number) => {
    if (newPage === currentPage.value || newPage < 1 || newPage > totalPages.value) {
        return; // Don't do anything if it's the current page or out of bounds
    }
    currentPage.value = newPage;
};

</script>

<style scoped>
.badge-row {
    width: 70vw;
    border: 1px solid #eaeaea;
    margin: 1rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    justify-items: center;
    align-items: center;
    display: flex;
    gap: 1rem;
}

.badge-pill {
    padding: 0.5em 0.75em;
    border-radius: 999px;
    color: white;
    font-weight: bold;
}

.bg-success {
    background-color: #28a745;
}

.bg-warning {
    background-color: #ffc107;
}

.bg-danger {
    background-color: #dc3545;
}

.bg-info {
    background-color: #007bff;
}

.bg-primary {
    background-color: #007bff;
}

.pagination-controls {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    align-items: center;
}

.pagination {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0px;
}

.pagination .page-item {
    margin: 0 5px;
}

.pagination .page-link {
    border: 1px solid #007bff;
    padding: 5px 10px;
    color: #007bff;
    cursor: pointer;
}

.pagination .page-link:hover {
    background-color: #007bff;
    color: white;
}

.pagination .disabled .page-link {
    color: #ccc;
    cursor: not-allowed;
    border: 1px solid #ccc;
}

.pagination .active .page-link {
    background-color: #007bff;
    color: white;
}
</style>
