<template>
  <div class="home">
    <div class="jobWrapper">
      <div v-if="jobTemplate">
        <h1>{{ jobTemplate.name }}</h1>
        <p>{{ jobTemplate.description }}</p>
        <h2>Task Templates:</h2>
        <ul>
          <li v-for="taskTemplate in jobTemplate.task_templates" :key="taskTemplate.id">
            <h3>{{ taskTemplate.name }}</h3>
            <p>{{ taskTemplate.description }}</p>
          </li>
        </ul>
        <h2>Linked Jobs:</h2>
        <div v-if="isLoadingJobs">Loading Jobs...</div>
        <JobList v-else :jobs="computedJobs"></JobList>
        <div>
          <router-link :to="{ name: 'createJob', params: { templateId: jobTemplate.id.toString() } }">Create Job from
            Template</router-link>
          <a :href="'/admin/ops/jobtemplate/' + jobTemplate.id.toString() + '/change/'">Edit Job Template</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import JobList from '@/components/JobList.vue'; // Import JobList component
import { useJobStore } from '@/stores/Jobs';
import { storeToRefs } from 'pinia';

const jobStore = useJobStore();
const { isLoadingJobs } = storeToRefs(jobStore);
const route = useRoute();
const jobTemplateId = ref(parseInt(route.params.id as string));

const jobTemplate = computed(() => {
  return jobStore.getJobTemplates.find(template => template.id === jobTemplateId.value);
});

const linkedJobs = computed(() => {
  return jobStore.all_jobs.filter(job => job.job_template === jobTemplateId.value);
});

computed(() => {
  const allJobs = jobStore.all_jobs;
  console.log("Jobs in JobTemplate from store (JobTemplate.vue):", allJobs);
  return allJobs;
});

const computedJobs = computed(() => jobStore.all_jobs);

onMounted(async () => {
  console.log("Component mounted. Starting data fetch...");
  jobStore.getAllJobTemplates();
  jobStore.getAllJobs();
});

</script>

<style scoped>
.home {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white !important;
  min-height: 100vh;
}

.jobWrapper {
  display: flex;
  width: 100%;
  align-items: flex-start;
}
</style>