<template>
    <div class="footer-content">
        <div class="footer-top">
            <div class="footer-logo-section">
                <img src="@/assets/mudbots-master-logo-white-lg.png" alt="Mudbots Logo" class="footer-logo">
                <p class="footer-brand-description">
                    Leading the innovation in 3D concrete printing, MudBots ® is America's pioneer in providing
                    versatile
                    concrete printers, from compact to large-scale models. Unveiled at the 2019 Vegas World of Concrete,
                    we
                    set
                    the global standard. Featured worldwide, MudBots ® transforms your vision into reality. Think it,
                    and
                    MudBots ® will print it.
                </p>
            </div>
            <div class="footer-columns">
                <div class="footer-column"></div>
                <div class="footer-column">
                    <h5>OFFICE HOURS</h5>
                    <p>09:00 - 05:00 MST<br>Mountain Standard Time</p>
                </div>
                <div class="footer-column">
                    <h4>CALL US</h4>
                    <p>800-733-2302<br>Monday - Friday</p>
                </div>
                <div class="footer-column">
                    <h4>LOCATION</h4>
                    <p>40 Iron St<br>UT 84074</p>
                </div>
            </div>
        </div>
        <p class="footer-legal">
            © 2024 MudBots 3D Concrete Printing, LLC. All rights reserved. <a href="https://www.mudbots.com/"
                target="_blank" rel="noopener noreferrer">Visit our official site</a>.
        </p>
    </div>
</template>

<style scoped>
.footer-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding: 50px 50px 20px 50px;
    background-color: #343a40;
    color: white;
    flex-wrap: wrap;
}

.footer-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.footer-logo-section {
    flex-direction: column;
    align-items: left;
    margin-right: 50px;
}

.footer-logo {
    max-width: 150px;
    margin-left: 30px;
    margin-right: 30px;
}

.footer-brand-description {
    justify-content: start;
    align-items: flex-start;
    margin-top: 10px;
    margin-left: 30px;
    color: #fff;
    font-size: 0.9rem;
    max-width: 450px;
}

.footer-columns {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-grow: 1;
    max-width: calc(100% - 250px);
}

.footer-column {
    margin: 0 10px;
    flex-basis: 25%;
}

.footer-column h4,
.footer-column h5 {
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #FFFFFF;
}

.footer-column p {
    margin: 0;
    font-size: 0.9rem;
}

.footer-legal {
    margin-top: 20px;
    text-align: center;
    color: #aaa;
    font-size: 0.8rem;
    width: 100%;
}

.footer-legal a {
    color: #fff;
    text-decoration: none;
}

.footer-legal a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        text-align: center;
    }

    .footer-columns {
        flex-direction: column;
        align-items: center;
    }

    .footer-column {
        flex-basis: 100%;
    }
}
</style>
