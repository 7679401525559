<template>
  <div class="dropdown" @click.stop="toggleDropdown">
    <button class="dropbtn">{{ title }} <span class="arrow-down"></span></button>
    <div class="dropdown-content" v-show="isOpen">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue';

const props = defineProps({
  title: String
});

const isOpen = ref(false);

const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
};

const close = () => {
  isOpen.value = false;
};

</script>

<style scoped>
.arrow-down {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  align-content: center;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.dropbtn {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px 10px 10px 10px;
  border-radius: 25px;
  background-color: transparent;
  color: black;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
  transform: scale(1);
  border: none;
  cursor: pointer;
}

nav .dropbtn:hover {
  border-radius: 25px;
  background-color: rgba(236, 236, 236, 0.47);
  transform: scale(1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

nav .dropbtn.active {
  transform: scale(0.95);
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgb(231, 231, 231);
  border-radius: 10px;
  width: 360px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
  padding: 10px;
}

.dropdown-content p {
  font-size: 0.8rem;
  color: #333;
  margin-top: 4px;
  text-decoration: none;
}

.dropdown-content p:hover {
  font-size: 0.8rem;
  color: #ffffff;
  margin-top: 4px;
  text-decoration: none;
}
</style>
