<template>
  <div class="home" data-bs-theme="light">
    <div v-if="isCreatingTask" class="loading-overlay">
      <div class="loading-container"></div>
    </div>
    <div class="taskWrapper">
      <h1>Create New Task from Task Template</h1>
      <br>
      <strong>
        <p>
          A Task template represents a repeatable task. When creating a new task from the template, the new task retains
          a
          reference to its template. Task templates are used within job templates to define the tasks needed for the
          job.
        </p>
      </strong>
      <hr>
      <br>
      <h2>Job Details</h2>
      <div class="row">
        <span class="col-3"><label for="jobTemplateType">Job Type<span class="required-asterisk">*</span> :
          </label></span>
        <span class="col">
          <select id="jobTemplateType" class="form-select" v-model="selectedJobId" @change="updateDisplayedJob"
            @input="clearErrorMsg" placeholder="Select a job to view details." required>
            <option value="" disabled selected>Select a Job</option>
            <option v-for="jt in allJobs" :key="jt.id" :value="jt.id">
              {{ jt?.name }}
            </option>
          </select>
          <div v-if="!selectedJobId">
            <p>Select a job to view details.</p>
          </div>
        </span>
      </div>
      <div v-if="!job">
        <p>Error: Unable to load job.</p>
      </div>
      <div v-else>
        <div class="row my-1">
          <span class="col-3 my-1">Job Name: </span>
          <span class="col">{{ job ? job.name : '' }}</span>
        </div>
        <div class="row my-1">
          <span class="col-3 my-1">Job Description: </span>
          <span class="col">{{ job ? job.description : '' }}</span>
        </div>
      </div>
      <br>
      <hr>
      <br>
      <h2>Task Template Details</h2>
      <br>
      <div class="row my-1">
        <span class="col-3"><label for="templateType">Template Type<span class="required-asterisk">*</span>:
          </label></span>
        <span class="col"><select id="templateType" class="form-select" v-model="selectedTaskTemplateId"
            @change="updateDisplayedTaskTemplate" style="--bs-bg-opacity: 0.9;" required>
            <option value="" disabled selected>Select a template</option>
            <option v-for="tmpl in allTaskTemplates" :key="tmpl.id" :value="tmpl.id">
              {{ tmpl?.name }}
            </option>
          </select>
          <div class="row" v-if="!selectedTaskTemplateId">
            <p>Select a task template to view details.</p>
          </div>
        </span>
      </div>
      <div v-if="!template">
        <p>Error: Unable to load task template.</p>
      </div>
      <div v-else>
        <div class="row my-1">
          <span class="col-3 my-1">Template Name: </span>
          <span class="col">{{ template ? template.name : '' }}</span>
        </div>
        <div class="row my-1">
          <span class="col-3 my-1">Template Description: </span>
          <span class="col">{{ template ? template.description : '' }}</span>
        </div>
        <div class="row my-1">
          <span class="col-3 my-1">Template Work Center: </span>
          <span class="col">{{ template && template.work_center ? template.work_center.name : 'N/A' }}</span>
        </div>
        <div class="row my-1">
          <span class="col-3 my-1">Template Cost Center: </span>
          <span class="col">{{ template && template.cost_center ? template.cost_center.name : 'N/A' }}</span>
        </div>
      </div>
      <br>
      <hr>
      <br>
      <h2>Task Details</h2>
      <div v-for="(task, index) in tasks" :key="index">
        <h3>Task {{ index + 1 }}</h3>
        <div class="input-group mb-3">
          <span class="col-3 input-group-text">Task Name <span class="required-asterisk">*</span></span>
          <input class="form-control task-name-input" type="text" v-model="task.name" required>
        </div>
        <div class="input-group mb-3">
          <span class="col-3 input-group-text">Task Description <span class="required-asterisk">*</span></span>
          <textarea class="form-control task-description-input" v-model="task.description" required></textarea>
        </div>
        <div class="input-group mb-3">
          <label class="col-3 input-group-text">Employee <span class="required-asterisk">*</span></label>
          <select v-model="task.assignedEmployee" class="form-select" required>
            <option value="" disabled selected>Select an Employee</option>
            <option v-for="employee in filteredEmployees" :key="employee.id" :value="employee.id">
              {{ employee.displayName }}
            </option>
          </select>
        </div>
        <div class="input-group mb-3">
          <span class="col-3 input-group-text">Priority <span class="required-asterisk">*</span></span>
          <select v-model="task.priority" class="form-select" required>
            <option value="" disabled selected>Select Priority</option>
            <option value="1">Low</option>
            <option value="2">Medium</option>
            <option value="3">High</option>
          </select>
        </div>
        <div class="input-group mb-3">
          <span class="col-3 input-group-text" for="expectedStartDate">Expected Start <span
              class="required-asterisk">*</span></span>
          <input id="expectedStartDate" v-model="task.expected_start" type="date" class="form-control" required>
        </div>
        <div class="input-group mb-3">
          <span class="col-3 input-group-text" for="expectedConclusionDate">Expected Conclusion <span
              class="required-asterisk">*</span></span>
          <input id="expectedConclusionDate" v-model="task.expected_concluded" type="date" class="form-control"
            required>
        </div>
        <button class="btn btn-danger remove-task-btn" @click="removeTask(index)">Remove Task</button>
      </div>
      <button class="btn btn-info add-task-btn" @click="addTask">➕ Add Task</button>
      <button class="btn btn-primary create-task-btn" @click="createTasks" :disabled="!isFormValid">Create
        Tasks</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Task, Job, TaskTemplate } from '@/types/Ops';
import { fetchAllTaskTemplates, fetchAllJobs, postToUrl } from '@/api/api';
import { useJobStore } from '@/stores/Jobs';
import { useEmployeeStore } from '@/stores/UserEmployee';
import { ref, watch, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const isCreatingTask = ref(false);
const TaskTemplateId = ref(parseInt(route.params.templateId as string));
const allJobs = ref<Job[]>([]);
const selectedJobId = ref<number | null>(null);
const errorMsg = ref('');
const employeeStore = useEmployeeStore();
const allTaskTemplates = ref<TaskTemplate[]>([]);
const selectedTaskTemplateId = ref<number | null>(null);

const job = ref<Job>({
  id: 0,
  name: '',
  description: '',
  build_order_reference: '',
  tasks: [],
  work_center: null,
  completed_by: '',
  status: '',
});

const tasks = ref<Task[]>([
  {
    id: 0,
    name: '',
    description: '',
    employees: null,
    assignedEmployee: null,
    expected_start: '',
    expected_concluded: '',
    priority: 2,
  }
]);

const template = ref<TaskTemplate>({
  id: 0,
  name: '',
  description: '',
  work_center: null,
  cost_center: null,
  job_template: 0,
  job_template_order: 0
});

watch(() => route.params, (nextParam, lastParam) => {
  TaskTemplateId.value = parseInt(nextParam.templateId as string);
});

const clearErrorMsg = () => {
  errorMsg.value = '';
};

onMounted(async () => {
  try {
    allJobs.value = await fetchAllJobs();
    allTaskTemplates.value = await fetchAllTaskTemplates();
    const jobStore = useJobStore();
    await jobStore.getAllJobs();
    await employeeStore.fetch();
  } catch (error) {
    console.error("Error fetching jobs:", error);
  }
});

const filteredEmployees = computed(() => {
  return employeeStore.employees?.map(employee => {
    let rolePrefix;
    if (employee.user.is_superuser) {
      rolePrefix = "AD"; // Admin
    } else if (employee.user.is_staff) {
      rolePrefix = "MA"; // Manager
    } else {
      rolePrefix = "EM"; // Employee
    }
    const displayName = `${rolePrefix} - ${employee.user.full_name}`;
    return {
      ...employee,
      rolePrefix,
      displayName,
    };
  }) || [];
});

const updateDisplayedJob = () => {
  const selectedJob = allJobs.value.find(t => t.id === selectedJobId.value);
  if (selectedJob) {
    job.value = selectedJob;
  }
};

const displayedJob = computed(() => {
  return selectedJobId.value
    ? job.value
    : {
      name: '',

    };
});

const updateDisplayedTaskTemplate = () => {
  const selectedTemplate = allTaskTemplates.value.find(t => t.id === selectedTaskTemplateId.value);
  if (selectedTemplate) {
    template.value = selectedTemplate;
  }
};

watch(selectedTaskTemplateId, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    updateDisplayedTaskTemplate();
  }
});

watch(selectedJobId, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    updateDisplayedJob();
  }
});

const resetTask = () => {
  tasks.value = [
    {
      id: 0,
      name: '',
      description: '',
      employees: null,
      value: '',
      work_center: null,
      cost_center: null,
      job: 0,
      started: undefined,
      started_by: 0,
      concluded: undefined,
      concluded_by: 0,
      successful: false,
      conclusion_notes: '',
      events: [],
      perpetual: false,
      task_template: 0,
      job_order: 0,
      task: undefined,
      completed: '',
      expected_start: '',
      expected_concluded: ''
    }
  ];
};

const addTask = () => {
  tasks.value.push({
    id: 0,
    name: '',
    description: '',
    employees: null,
    assignedEmployee: null,
    expected_start: '',
    expected_concluded: '',
    priority: 2,
  });
};


const removeTask = (index: number) => {
  tasks.value.splice(index, 1);
};

const isFormValid = computed(() => {
  return tasks.value.every(task =>
    (task.name?.trim() || '') &&
    (task.description?.trim() || '') &&
    (task.expected_start?.trim() || '') &&
    (task.expected_concluded?.trim() || '') &&
    task?.assignedEmployee &&
    selectedTaskTemplateId?.value
  );
});

const createTasks = async () => {
  if (!isFormValid.value) {
    errorMsg.value = 'Please fill out all required fields for each task.';
    return;
  }

  isCreatingTask.value = true;
  try {
    for (const task of tasks.value) {
      // console.log(task);
      const newTask = {
        name: task.name,
        description: task.description,
        employees: task.assignedEmployee ? [task.assignedEmployee] : [],
        job: selectedJobId.value,
        expected_start: task.expected_start,
        expected_concluded: task.expected_concluded,
        created_by: employeeStore.currentUser?.id,
        priority: task.priority,
      };

      await postToUrl(`/ops/api/create-task/${selectedTaskTemplateId.value}/`, newTask);
    }
    alert(`Task has been successfully created`);
  } catch (error) {
    alert('Error occurred while creating tasks.');
  } finally {
    isCreatingTask.value = false;
    resetTask();
  }
};


</script>

<style scoped>
.home {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0.1, 0.1, 0.1, 0.2);
  margin: 2% 20%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white !important;
  min-height: 100vh;
}

.taskWrapper {
  max-width: 800px;
  width: 100%;
  padding: 10px 20px;
}

.task-name-input,
.task-description-input {
  font-size: 0.9rem;
}

.required-asterisk {
  color: red;
}

.add-task-btn {
  float: left;
  margin: 10px 0;
  background-color: #90EE90;
}

.create-task-btn {
  display: block;
  margin: 50px auto;
}

.form-control[type="date"] {
  color: black;
  background-color: #ccc;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-container::after {
  content: '';
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>