<template>
  <div class="create-job-template-form" data-bs-theme="light">
    <div class="templateWrapper">
      <h1>Create Job Template</h1>
      <br>
      <strong>
        <p>
          This is a predefined blueprint for a job that is expected to be repeated. Creating a new job from a job
          template
          will keep a link to the original template, which helps in maintaining a standard process and makes job
          creation
          more efficient.
        </p>
      </strong>
      <hr>
      <br>
      <form @submit.prevent="saveJobTemplate">
        <div class="form-group">
          <label for="templateName">Template Name<span class="required-asterisk">*</span></label>
          <input id="templateName" type="text" v-model="jobTemplate.name" required>
        </div>
        <div class="form-group">
          <label for="templateDesc">Template Description<span class="required-asterisk">*</span></label>
          <textarea id="templateDesc" cols="30" rows="5" v-model="jobTemplate.description" required></textarea>
        </div>
        <div class="form-group">
          <label for="partReference">Part Reference<span class="required-asterisk">*</span></label>
          <input id="partReference" class="form-control" v-model.number="jobTemplate.part_reference" type="text"
            required>
        </div>
        <div class="form-group">
          <button class="btn btn-primary create-jobtemplate-btn" type="submit">Save</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { postToUrl } from '@/api/api';

const jobTemplate = ref({
  name: '',
  description: '',
  part_reference: '',

});

const resetForm = () => {
  jobTemplate.value = {
    name: '',
    description: '',
    part_reference: '',
  };
};

const saveJobTemplate = async () => {
  try {
    const response = await postToUrl('/ops/api/jobtemplates/', jobTemplate.value);
    alert("Job Template created successfully!");
    resetForm();
  } catch (error) {
    // console.error("Error creating job template:", error);
    alert("Failed to create Job Template. Please check the data and try again.");
  }
};

</script>

<style scoped>
.create-job-template-form {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0.1, 0.1, 0.1, 0.2);
  margin: 2% 20%;
  padding: 10 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white !important;
  min-height: 100vh;
}

.templateWrapper {
  padding: 10px 20px;
  max-width: 800px;
  width: 100%;
}

.form-group {
  margin-bottom: 15px;
  padding: 0 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.required-asterisk {
  color: red;
}

.create-jobtemplate-btn {
  display: block;
  margin: 50px auto;
}
</style>