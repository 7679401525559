import { fetchFromUrl } from '@/api/api'
import { defineStore } from 'pinia'
import { DescriptableBase } from '@/types/Ops'

interface OpsSupplementState {
    work_centers: DescriptableBase[] | null,
    cost_centers: DescriptableBase[] | null,
}
// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useOpsSupplementStore = defineStore('opsSupplement', {
    state: () => ({ work_centers: null, cost_centers: null } as OpsSupplementState),
    actions: {
        async fetch() {
            const wc = fetchFromUrl<DescriptableBase[]>("/ops/api/workcenters/")
                .then(wcs => {
                    this.work_centers = wcs;
                });
            const cc = fetchFromUrl<DescriptableBase[]>("/ops/api/costcenters/")
                .then(ccs => {
                    this.cost_centers = ccs;
                });
            return Promise.all([wc, cc]);
        },
    },
    getters: {
        getWorkCenterById: (state) => (id: number) => {
            return state.work_centers?.find(wc => wc.id == id);
        },
        getCostCenterById: (state) => (id: number) => {
            return state.cost_centers?.find(cc => cc.id == id);
        },
    }
})