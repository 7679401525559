<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="home" data-bs-theme="light">
    <div class="calendar">
      <h1>Calendar</h1>
      <br>
      <FullCalendar :options="calendarOptions" />
      <Modal v-if="showModal" :title="selectedEvent.title" :show="showModal" @update:show="value => showModal = value">
        <template v-slot:default>
          <ul>
            <li><strong>ID:</strong> {{ selectedEvent.id }}</li>
            <li><strong>Name:</strong> {{ selectedEvent.name }}</li>
            <li><strong>Description:</strong> {{ selectedEvent.description }}</li>
            <li><strong>Expected Start:</strong>
              <DateTimeBadge :time="selectedEvent.expected_start" />
            </li>
            <li><strong>Expected Conclusion:</strong>
              <DateTimeBadge :time="selectedEvent.expected_concluded" />
            </li>
            <li><strong>Started:</strong>
              <DateTimeBadge :time="selectedEvent.started" />
            </li>
            <li><strong>Concluded:</strong>
              <DateTimeBadge :time="selectedEvent.concluded" />
            </li>
          </ul>
        </template>
      </Modal>

    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, computed, ref } from 'vue';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { useCalendarStore } from '@/stores/Calender';
import Modal from '@/components/Modal.vue';
import DateTimeBadge from '@/components/DateTimeBadge.vue';

const calendarStore = useCalendarStore();
const selectedEventDetails = ref(null);
const showModal = ref(false);
const selectedEvent = ref(null);

const handleEventClick = (clickInfo) => {
  const { description, type, isReminder, expected_start, expected_concluded, started, completed } = clickInfo.event.extendedProps;

  const prefix = type.charAt(0).toUpperCase() + type.slice(1); // 
  let title = `${prefix}`;
  if (isReminder) {
    title += ` Reminder: ${clickInfo.event.title}`;
  } else {
    title += `: ${clickInfo.event.title}`;
  }

  selectedEvent.value = {
    title,
    bodyText: description,
    id: clickInfo.event.id,
    name: clickInfo.event.title,
    description: description,
    expected_start: clickInfo.event.expected_start,
    expected_concluded: clickInfo.event.expected_concluded,
    started: clickInfo.event.started,
    concluded: clickInfo.event.completed
  };

  showModal.value = true;
  console.log(clickInfo.event);
};



const calendarOptions = computed(() => {
  let allEventsAndReminders = [];

  calendarStore.events.forEach(event => {
    allEventsAndReminders.push(event);

    if (event.type === 'task') {
      if (event.expected_start) {
        const startReminder = {
          ...event,
          id: `start-${event.id}`,
          title: `Start Reminder: ${event.title}`,
          start: event.expected_start,
          end: event.expected_start,
          color: 'yellow',
          isReminder: true,
        };
        allEventsAndReminders.push(startReminder);
      }
      if (event.expected_concluded) {
        const concludeReminder = {
          ...event,
          id: `conclude-${event.id}`,
          title: `Conclude Reminder: ${event.title}`,
          start: event.expected_concluded,
          end: event.expected_concluded,
          color: 'yellow',
          isReminder: true,
        };
        allEventsAndReminders.push(concludeReminder);
      }
    }
  });

  return {
    plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,listWeek'
    },
    editable: true,
    selectable: true,
    weekends: true,
    themeSystem: 'bootstrap5',
    views: {
      dayGridMonth: {
        dayMaxEventRows: 3
      }
    },
    eventContent: (args) => {
      let eventClass = args.event.extendedProps.type === 'job' ? 'event-job' : 'event-task';
      if (args.event.extendedProps.isReminder) {
        eventClass += ' event-reminder';
      }
      return {
        html: `<div class="fc-event-custom ${eventClass}">
               <div class="fc-event-title">${args.event.title}</div>
             </div>`
      };
    },
    events: allEventsAndReminders,
    eventClick: handleEventClick,
  };
});

onMounted(() => {
  calendarStore.fetchEvents()
});
</script>

<style>
.home {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  background-color: white !important;
  min-height: 100vh;
}

.calendar {
  width: 100%;
  margin: 50px auto;
  padding: 20px;
  border-radius: 25px;
}

.fc-event {
  background-color: transparent !important;
  border: none !important;
}

.fc-event:hover {
  background-color: transparent !important;
  border: none !important;
}

.fc-event-custom {
  background-color: var(--custom-background-color) !important;
  border-color: var(--custom-border-color) !important;
  color: #fff !important;
  border-style: solid;
  border-width: 1px;
  border-radius: 25px;
  height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fc-event-title {
  padding: 2px 5px;
}

.event-job {
  --custom-background-color: #ff9f89;
  --custom-border-color: #ff6f61;
}

.event-task {
  --custom-background-color: #89cff0;
  --custom-border-color: #61a2ff;
}

.event-reminder {
  --custom-background-color: #ffff99;
  --custom-border-color: #ffff99;
  color: black !important;
}

.event-reminder {
  --custom-background-color: #ffff99;
  --custom-border-color: #ffff99;
  color: black !important;
}

:root {
  --fc-event-bg-color: transparent;
  --fc-event-border-color: transparent;
  --bs-link-hover-color: transparent;
  --bs-link-hover-color-rgb: 255, 255, 255;
}

.fc .fc-daygrid-more-link:hover {
  background-color: rgba(75, 82, 219, 1.000);
}
</style>
