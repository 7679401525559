export interface DescriptableBase {
id: number,
name: string,
description: string
}

//User Interfaces

export interface User{
id: number,
password?:string,
full_name:string,
first_name:string,
last_name:string,
email:string,
username:string,
last_login?: Date,
date_joined?:Date,
user_permissions: [string, string][],
is_superuser:number,
is_staff:number,
is_active:number,
}

export interface UserEmployee {
id:number,
user: User,
has_admin: boolean
hourly_pay: number | null,
work_center?: DescriptableBase[] | null,
is_active:number,
}

export interface TaskAssignment {
id: number,
user: number, //UserEmployee's Id
task: number,
}

//Job Interfaces

export interface JobTemplate extends DescriptableBase {
value: TaskTemplate;
part_reference: string,
task_templates: TaskTemplate[]
work_center: number;
}


export interface Job extends DescriptableBase {
id : number,
build_order_reference?: string,
started?: Date,
completed?: Date,
tasks?: Task[],
assigned_to?: number[];
work_center?: DescriptableBase | null;
completed_by?: string;
created_by?: number | null,
status?: string;
total_active_duration?: number; 
total_labor_cost?: number;
job_template?:number;
priority?: number;
}

export enum JobPriority {
    Low = 1,
    Medium = 2,
    High = 3,
}

//Task Interfaces

export interface TaskTemplate extends DescriptableBase {
work_center: DescriptableBase | null,
cost_center: DescriptableBase | null,
job_template: number,
job_template_order: number,
}

export interface Task extends DescriptableBase {
task?: any;
completed?: string;
value?: string;
employees?: number | null;
id: number,                     
work_center?: DescriptableBase | null,
cost_center?: DescriptableBase | null,
job?: number | null,
job_name?: string;
job_order?: number,
created_by?: number | null,
started?: Date | null,
started_by?: number | null,
concluded?: Date | null,
concluded_by?: number | null,
successful?: boolean | null,
conclusion_notes?: string,
assignments?: TaskAssignment[],
assignedEmployee?: number | null,
events?: TaskEvent[],
total_active_duration?: number;
perpetual?: boolean,
task_template?: number | null,
labor_cost?: number;
expected_start?: string | null;
expected_concluded?: string | null;
priority?: number;
}

export enum TaskPriority {
    Low = 1,
    Medium = 2,
    High = 3,
}

export type TaskEventType = "ST" | "PA" | "IN" | "RE" | "CO";
export const WORKING = ["ST", "RE"];

export interface TaskEvent {
id: number,
timestamp?: Date | string,
employee: number, //UserEmployee's ID
task: number,
event_type: TaskEventType,
success?: boolean,
notes: string
}

//Util Interfaces


export interface WorkCenter extends DescriptableBase {
    id: number;
    name: string;
  }
  
export interface CostCenter {
    id: number;
    name: string;
  }
  
export interface GanttTask {
    id: number,
    text: string,
    start_date: string,
    duration: number,
    progress: number,
    end_date: string,
  }


export interface CalendarEvent {
    id: number;
    title: string;
    start: string; 
    end: string;    
    description?: string;
    job?: number | null;
    type: 'job' | 'task';
    taskEvents?: TaskEvent[];  
}
// Add this to your types/Ops.ts or equivalent file
// export type CalendarEventType = JobCalendarEvent | TaskCalendarEvent;

export interface JobCalendarEvent {
    id: number;
    title: string;
    started: string; 
    completed: string;    
    description?: string;
    type: 'job';
}

export interface TaskCalendarEvent {
    id: number;
    title: string;
    started: string; 
    concluded: string;    
    description?: string;
    job?: number;
    type: 'task';
    expected_start?: string;
    expected_concluded?: string;
}