import { defineStore } from 'pinia';
import { fetchAllJobs, fetchAllTaks } from '@/api/api'; // Adjust the import path as necessary
import { Job, Task } from '@/types/Ops'; // Ensure this import path is correct

interface ChartData {
  notStarted: number;
  inProgress: number;
  completed: number;
}

export const useChartStore = defineStore('chart', {
  state: () => ({
    jobsChartData: {
      notStarted: 0,
      inProgress: 0,
      completed: 0,
    } as ChartData,
    tasksChartData: {
      notStarted: 0,
      inProgress: 0,
      completed: 0,
    } as ChartData,
  }),
  actions: {
    async fetchAndProcessJobsData() {
      try {
        // console.log("[PieChart.ts] Fetching Jobs Data...");
        const jobs = await fetchAllJobs();
        // console.log("[PieChart.ts] Jobs Fetched: ", jobs);
        this.jobsChartData = this.processDataJobs(jobs);
        // console.log("[PieChart.ts] Processed Jobs Chart Data: ", this.jobsChartData);
      } catch (error) {
        console.error("Error fetching jobs data:", error);
      }
    },
    async fetchAndProcessTasksData() {
      try {
        // console.log("[PieChart.ts] Fetching Tasks Data...");
        const tasks = await fetchAllTaks();
        // console.log("[PieChart.ts] Tasks Fetched: ", tasks);
        this.tasksChartData = this.processDataTasks(tasks);
        // console.log("[PieChart.ts] Processed Tasks Chart Data: ", this.tasksChartData);
      } catch (error) {
        console.error("Error fetching tasks data:", error);
      }
    },
    processDataJobs(items: Job[] ): ChartData {
      // console.log("[PieChart.ts] Processing Job Data...");
      const chartData: ChartData = {
        notStarted: 0,
        inProgress: 0,
        completed: 0,
      };
      items.forEach(item => {
        const started = item.started ? new Date(item.started) : null;
        const completed = item.completed ? new Date(item.completed) : null;

        if (!started && !completed) {
          chartData.notStarted++;
        } else if (started && !completed) {
          chartData.inProgress++;
        } else if (completed) {
          chartData.completed++;
        }
      });
      // console.log("[PieChart.ts] Processed Chart Data: ", chartData);
      return chartData;
    },

    processDataTasks(items: Task[]): ChartData{
      // console.log("[PieChart.ts] Processing Task Date...");
      const chartData : ChartData = {
        notStarted:0,
        inProgress:0,
        completed:0,
      }
      items.forEach(item =>{
        const started = item.started ? new Date(item.started) : null;
        const completed = item.concluded ? new Date(item.concluded): null;

        if (!started && !completed) {
          chartData.notStarted++;
        } else if (started && !completed) {
          chartData.inProgress++;
        } else if (completed) {
          chartData.completed++;
        }
      });
      // console.log("[PieChart.ts] Processed Chart Data: ", chartData);
      return chartData;
    }
  }
});
