<template>
    <div v-if="thisTask">
        <table class="styled-table" data-bs-theme="light">
            <thead>
                <tr>
                    <th scope="col col-2">ID | Name</th>
                    <th scope="col">Description</th>
                    <th scope="col">Work Center</th>
                    <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <div class="col"><router-link :to="'/ops/task/' + thisTask.id"><b>{{ thisTask.id + " | " +
        thisTask.name }}</b></router-link></div>
                    <div class="col">
                        <h5>{{ truncateDescription(thisTask.description) }}</h5>
                    </div>
                    <div class="col">
                        <h5>{{ wcName }}</h5>
                    </div>
                    <div class="col">
                        <div v-if="otherTaskActive" class="info-message">
                            <router-link :to="'/ops/task/' + activeTask.id">
                                <strong>{{ activeTask.name }}</strong>
                            </router-link> is currently running. To start this task, you must stop the
                            active task.
                        </div>
                        <div v-if="thisTask.successful" class="info-message">
                            <strong>
                                This task has been successfully concluded.
                            </strong>
                        </div>
                        <Button v-if="canStart" @click="startTask" text="Start"></Button>
                        <Button v-if="canStop" @click="pauseTask" text="Pause"></Button><br>
                        <Button v-if="canResume" @click="resumeTask" text="Resume"></Button><br>
                        <Button v-if="canStop" @click="interruptTask" text="Interrupt"></Button><br><br>
                        <Button v-if="canStop" @click="showConcludeModal = true" text="Conclude"></Button>
                        <Modal v-model:show="showConcludeModal" title="Conclude Task" :on-confirm="concludeTask">
                            <template #default>
                                <div class="row">
                                    <div class="col" style="border: 0px;">
                                        <h5>Was the task successful?</h5>
                                    </div>
                                    <div class="col" style="border: 0px;">
                                        <input type="checkbox" v-model="isTaskSuccessful" />
                                    </div>
                                </div>
                                <div>
                                    <h5>Please provide conclusion notes:</h5>
                                    <textarea class="form-control" v-model="conclusionNotes"
                                        placeholder="Enter conclusion notes here"></textarea>
                                </div>
                            </template>
                            <template #footer>
                                <button type="button" class="btn btn-secondary"
                                    @click="showConcludeModal = false">Close</button>
                                <button type="button" class="btn btn-primary" @click="concludeTask">Confirm</button>
                            </template>
                        </Modal>
                    </div>
                </tr>
            </tbody>
        </table>
        <p>
            <Modal v-model:show="showModal" :on-confirm="doInterrupt" title="Enter Notes">
                Reason for interruption :
                <textarea class="form-control" v-model="notes"
                    placeholder="Enter reason for Interruption here"></textarea>
            </Modal>
        </p>
    </div>
</template>

<script setup lang="ts">

import { useTaskStore } from '@/stores/Tasks';
import { Task } from '@/types/Ops';
import { useEmployeeStore } from '@/stores/UserEmployee';
import { useOpsSupplementStore } from '@/stores/OpsSupplement';
import { computed, ref, defineProps, watch, onMounted } from 'vue';
import UserBadge from '@/components/UserBadge.vue';
import Button from '@/components/Button.vue';
import Modal from '@/components/Modal.vue';

const props = defineProps<{ task?: Task, taskId?: number }>();
const employeeStore = useEmployeeStore();
const taskStore = useTaskStore();
const opsSupp = useOpsSupplementStore();

const showModal = ref(false);
const notes = ref("");
const showConcludeModal = ref(false);
const isTaskSuccessful = ref(true);
const conclusionNotes = ref("");
const thisTask = computed(() => props.task ?? taskStore.getTaskById(props.taskId));
const wcName = computed(() => {
    const workCenterId = thisTask.value?.work_center?.id;
    const workCenter = workCenterId ? opsSupp.getWorkCenterById(workCenterId) : null;
    return workCenter ? workCenter.name : "None";
});

onMounted(async () => {
    if (!opsSupp.work_centers) {
        await opsSupp.fetch();
    }

});

const employeeState = computed(() => taskStore.taskEmployeeState(thisTask.value?.id ?? 0, employeeStore.currentUser?.id ?? 0));
const otherTaskActive = computed(() => taskStore.current_task && (taskStore.current_task !== thisTask.value?.id));

const canStart = computed(() => {
    const isConcludedSuccessfully = thisTask.value?.successful ?? false;
    return !otherTaskActive.value && !isConcludedSuccessfully && (!employeeState.value || employeeState.value === "CO");
});

const canStop = computed(() => !otherTaskActive.value && (employeeState.value === "ST" || employeeState.value === "RE"));
const canResume = computed(() => !otherTaskActive.value && (employeeState.value === "PA" || employeeState.value === "IN"));
const interruptTask = () => showModal.value = true;

const activeTask = computed(() => {
    const activeTaskId = taskStore.current_task;
    if (activeTaskId) {
        const activeTask = taskStore.getTaskById(activeTaskId);
        return activeTask ? { id: activeTask.id, name: `${activeTask.id} ${activeTask.name}` } : null;
    }
    return null;
});

const truncateDescription = (description) => {
    const maxLength = 50;
    if (description.length > maxLength) {
        return description.substring(0, maxLength) + '...';
    }
    return description;
}


const handleTaskEvent = (eventType, success, notes) => {
    if (thisTask.value) {
        taskStore.addEvent({
            id: 0,
            timestamp: undefined,
            employee: employeeStore.currentUser?.user.id ?? 0,
            task: thisTask.value.id,
            notes: notes,
            event_type: eventType,
            success: success,
        });
        // console.log(`Handling task event: ${eventType}`);
    }
};

const startTask = () => {
    // console.log('Start button clicked');
    handleTaskEvent('ST', true, '');
};

const pauseTask = () => {
    // console.log('Pause button clicked');
    handleTaskEvent('PA', true, '');
    // window.location.reload();
};

const resumeTask = () => {
    // console.log('Resume button clicked');
    handleTaskEvent('RE', true, '');
    // window.location.reload();
};
const concludeTask = () => {
    if (conclusionNotes.value.trim() === '') {
        alert('You must provide conclusion notes.');
        return false;
    }
    handleTaskEvent('CO', isTaskSuccessful.value, conclusionNotes.value);
    showConcludeModal.value = false;
    return true;
};

const doInterrupt = () => {
    if (notes.value.trim() === '') {
        alert('You must provide notes for interruption.');
        return false;
    }
    handleTaskEvent('IN', false, notes.value);
    showModal.value = false;
    return true;
};


watch(() => props.taskId, (newVal) => {
    // Any required logic when taskId changes
});

</script>

<style scoped>
.styled-table {
    width: 100%;
    border: 1px solid #eaeaea;
    margin-top: 10px;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    justify-items: center;
    font-size: 0.9em;
    border-collapse: separate;
    border-spacing: 0;
}

.styled-table thead tr {
    background-color: #f9f9f9;
    text-align: center;
    border-radius: 10px;
    height: 70px;
}

.styled-table thead tr:first-child th:first-child {
    border-top-left-radius: 10px;
}

.styled-table thead tr:first-child th:last-child {
    border-top-right-radius: 10px;
}

.styled-table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}

.styled-table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}

.styled-table tbody tr td:first-child {
    border-left: 1px solid #ddd;
}

.styled-table tbody tr td:last-child {
    border-right: 1px solid #ddd;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #f3f3f3;
}

.styled-table tbody tr:hover {
    background-color: #ebebeb;
}

.col {
    display: table-cell;
    padding: 10px;
    vertical-align: top;
    border: 1px solid #ddd;
}
</style>