import { fetchFromUrl, postToUrl, patchToUrl, fetchAllJobs } from '@/api/api';
import { defineStore } from 'pinia';
import { JobTemplate, Job } from '@/types/Ops';
import { reactive } from 'vue';

interface JobsState {
  jobTemplates?: JobTemplate[];
  all_jobs?: Job[],
  isLoadingJobs: boolean;
}

export const useJobStore = defineStore('jobs', {
  state: () => ({ jobTemplates: [], all_jobs: [],isLoadingJobs: true, } as JobsState),

  actions: {
    getAllJobs() {
      // console.log("Fetching all jobs using the getAllJobs action...(Jobs.ts)");
      this.isLoadingJobs = true;
      fetchAllJobs()
          .then(jobs => {
            // console.log("Jobs received in the Jobs store(Jobs.ts):", jobs);
              this.all_jobs = jobs;
          })
          .catch(error => {
              console.error("Error fetching all jobs:", error);
          })
          .finally(() => {
            this.isLoadingJobs = false;
          });
    },
      async getAllJobTemplates() {
      try {
        const jobTemplates = await fetchFromUrl<JobTemplate[]>('/ops/api/jobtemplates/');
        this.jobTemplates = jobTemplates;
      } catch (error) {
        console.error("Error fetching job templates:", error);
      }
    },
    async createJob(jobTemplateId: number, job: Job) {
      try {
        const response = await postToUrl<Job>('/ops/template_to_job/' + jobTemplateId + '/', job);
        return response.id;
      } catch (error) {
        console.error("Error creating job:", error);
        throw error;
      }
    },

    async saveJob(jobId: number | string) {
      const job = this.getJobById(jobId);
      console.log("Saving job:", job);
      if (job) {
        try {
          const updatedJob = await patchToUrl('/ops/api/jobs/' + jobId + '/', job);
          const index = this.jobs.findIndex((job) => job.id == updatedJob.id);
          if (index !== -1) {
            this.jobs[index] = updatedJob;
          }
        } catch (error) {
          console.error("Error saving job:", error);
        }
      } else {
        console.warn("Job not found:", jobId);
      }
    },
    async fetch(): Promise<boolean> {
      this.isLoadingJobs = true;
      try {
        const jobs = await fetchFromUrl<Job[]>('/ops/api/jobs/');
        this.all_jobs = jobs;
        this.isLoadingJobs = false;
        return true;
      } catch (error) {
        console.error("Error fetching jobs:", error);
        this.isLoadingJobs = false;
        return false;
      }
    },
    getJobById(id: number): Job | undefined {
      if (!this.all_jobs || this.isLoadingJobs) {
        console.warn('Jobs have not been fetched yet or still loading.');
        return undefined;
      }
      console.log("Fetching job with ID:", id);
      return this.all_jobs.find((job) => job.id === id);
    },
    setAssignment(id: number, assignment: number[]) {
      const job = this.getJobById(id);
      if (job) {
        job.assigned_to = assignment;
      } else {
        console.warn("Job not found for assignment:", id);
      }
    },
    updateJob(updatedJob: Job) {
      const index = this.all_jobs?.findIndex(job => job.id === updatedJob.id);
      if (index !== undefined && index !== -1) {
        this.all_jobs.splice(index, 1, updatedJob);
    } else {
        this.all_jobs.push(updatedJob);
      }
    },
    getAllJobsState() {
      return this.all_jobs;
    }    
  },

  getters: {
    getJobTemplates() {
      return this.jobTemplates;
    },
    getJobs: (state) => state.all_jobs,
  },
});

export interface job {
  id: number;
}

