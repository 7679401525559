<template>
  <div class="work-cost-center-container">
    <section class="work-center-section">
      <h2>Add Work Center</h2>
      <div class="form-group">
        <label for="workCenterName">Name:</label>
        <input id="workCenterName" v-model="workCenter.name" type="text">
      </div>
      <div class="form-group">
        <label for="workCenterDescription">Description:</label>
        <textarea id="workCenterDescription" v-model="workCenter.description" rows="4"></textarea>
      </div>
      <button @click="saveWorkCenter">Save Work Center</button>
    </section>

    <section class="cost-center-section">
      <h2>Add Cost Center</h2>
      <div class="form-group">
        <label for="costCenterName">Name:</label>
        <input id="costCenterName" v-model="costCenter.name" type="text">
      </div>
      <div class="form-group">
        <label for="costCenterDescription">Description:</label>
        <textarea id="costCenterDescription" v-model="costCenter.description" rows="4"></textarea>
      </div>
      <button @click="saveCostCenter">Save Cost Center</button>
    </section>
  </div>
</template>

<!-- ... Script and Style sections remain the same ... -->


<script lang="ts" setup>
import { ref } from 'vue';
import { postToUrl } from '@/api/api'; // Adjust the import path as needed
import { WorkCenter, CostCenter } from './types/Ops';



const initialWorkCenterState = { name: '', description: '' };
const initialCostCenterState = { name: '', description: '' };

const workCenter = ref<WorkCenter>({ ...initialWorkCenterState });
const costCenter = ref<CostCenter>({ ...initialCostCenterState });

const saveWorkCenter = async () => {
  try {
    const response = await postToUrl<WorkCenter>('/ops/api/workcenters/', workCenter.value);
    alert('Work Center saved successfully!');
    workCenter.value = { ...initialWorkCenterState }; // Resetting the form
  } catch (error) {
    alert('Failed to save Work Center.');
  }
};

const saveCostCenter = async () => {
  try {
    const response = await postToUrl<CostCenter>('/ops/api/costcenters/', costCenter.value);
    alert('Cost Center saved successfully!');
    costCenter.value = { ...initialCostCenterState }; // Resetting the form
  } catch (error) {
    alert('Failed to save Cost Center.');
  }
};
</script>

<style>
.work-cost-center-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
}

.work-center-section,
.cost-center-section {
  background-color: #f8f8f8;
  /* Light background for separation */
  padding: 15px;
  margin-bottom: 30px;
  /* Space between sections */
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.form-group textarea {
  width: 100%;
  /* Full width for responsiveness */
  min-height: 100px;
  /* Minimum height to make it larger */
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f0f0f0;
  /* Light gray background */
  color: #333;
  /* Darker text color */
  box-sizing: border-box;
  resize: vertical;
  /* Allow vertical resizing only */
}

.form-group input {
  background-color: #f0f0f0;
  color: #333;
}

h2 {
  color: #333;
  /* Example heading color */
  font-size: 24px;
  margin-bottom: 15px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;

  margin-bottom: 5px;
  color: #666;
  /* Example label color */
  font-weight: bold;
}

.form-group input[type="text"] {

  width: 100%;
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  /* Ensures padding doesn't affect overall width */
}

button {
  background-color: #007bff;
  /* Example button color */
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
  /* Darker shade on hover */
}

/* Additional styles */
</style>