import { defineStore } from 'pinia';
import { ref, Ref } from 'vue';
import { Job, Task } from '@/types/Ops';
import { fetchCalendarData } from '@/api/api';  // Adjust the path to your API utilities

export interface CalendarEvent {
  id: number;
  title: string;
  start: string | number;  // ISO date string
  end: string | number;    // ISO date string
  job?:number;
  type: 'job' |'task';
  expected_start?: string | number;
  expected_concluded?: string | number;
}

export const useCalendarStore = defineStore('calendar', () => {
    const events: Ref<CalendarEvent[]> = ref([]);

  const organizeEvents = (jobs, tasks) => {
    const organizedEvents: CalendarEvent[] = [];
    jobs.forEach(job => {
      organizedEvents.push(job); // Add the job
      // Add tasks that belong to this job
      const jobTasks = tasks.filter(task => task.job === job.id);
      organizedEvents.push(...jobTasks);
    });
    // Add tasks without a job at the end
    const tasksWithoutJob = tasks.filter(task => !task.job);
    organizedEvents.push(...tasksWithoutJob);

    return organizedEvents;
  };

  const fetchEvents = async () => {
    try {
      const data = await fetchCalendarData();
      const jobs = data.jobs.map(jobToCalendarEvent);
      const tasks = data.tasks.map(taskToCalendarEvent);
      events.value = organizeEvents(jobs, tasks);
      // console.log('Calendar events fetched and organized:', events.value);
    } catch (error) {
      console.error('Error fetching calendar events:', error);
    }
  };

  const addEvent = (newEvent: CalendarEvent) => {
    events.value.push(newEvent);
    const jobs = events.value.filter(event => event.type === 'job');
    const tasks = events.value.filter(event => event.type === 'task');
    events.value = organizeEvents(jobs, tasks);
  };
    
const jobToCalendarEvent = (job) => {
    return {
        id: job.id,
        title: job.name,
        start: job.started instanceof Date ? job.started.toISOString() : job.started,
        end: job.completed instanceof Date ? job.completed.toISOString() : job.completed,
        description: job.description,
        type: 'job'
    };
};

const taskToCalendarEvent = (task) => {
  return {
    id: task.id,
    title: task.name,
    start: task.started instanceof Date ? task.started.toISOString() : task.started,
    end: task.concluded instanceof Date ? task.concluded.toISOString() : task.concluded,
    description: task.description,
    job:task.job,
    type: 'task',
    expected_start: task.expected_start instanceof Date ? task.expected_start.toISOString() : task.expected_start,
    expected_concluded: task.expected_concluded instanceof Date ? task.expected_concluded.toISOString() : task.expected_concluded,
  };
};

    return { events, fetchEvents, addEvent, };
});
