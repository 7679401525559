<template>
  <div class="whole-employee" v-if="employeeData">
    <div class="employee-details">
      <div class="profile-header">
        <img src="@/assets/userprofile.png" alt="Employee Photo" class="profile-photo">
        <div class="profile-info">
          <h1>{{ employeeData.user?.full_name }}
            <span class="edit-symbol" @click="navigateToUserView(employeeData.id)">&#9998;</span>
          </h1>
          <div>
            <p class="last-login" style="font-weight: bold; margin: 2px;">Last Login:
              <DateTimeBadge :time="employeeData.user?.last_login" />
            </p>
          </div>
          <hr>
          <div class="profile-details" style="font-size: large;">
            <div class="row py-2">
              <div class="col col-4">
                <strong>Employee Id:</strong>
              </div>
              <div class="col">
                {{ employeeData.id }}
              </div>
            </div>
            <div class="row py-2">
              <div class="col col-4">
                <strong>Email:</strong>
              </div>
              <div class="col">
                {{ employeeData.user?.email }}
              </div>
            </div>
            <div class="row py-2">
              <div class="col col-4">
                <strong>Username:</strong>
              </div>
              <div class="col">
                {{ employeeData.user?.username }}
              </div>
            </div>
            <div v-if="employeeStore.currentUser.user?.is_superuser" class="row py-2">
              <div class="col col-4">
                <strong>Hourly Pay:</strong>
              </div>
              <div class="col">
                <b>$</b> {{ employeeData.hourly_pay }}
              </div>
            </div>
            <div class="row py-2">
              <div class="col col-4">
                <strong>Work Center:</strong>
              </div>
              <div class="col">
                <div v-for="workCenter in employeeData.work_centers" :key="workCenter.id">
                  {{ workCenter.name }}
                </div>
              </div>
            </div>
            <div class="row py-2">
              <div class="col col-4">
                <strong>Date of Joining:</strong>
              </div>
              <div class="col">
                <DateTimeBadge :time="employeeData.user?.date_joined" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isLoadingTaks">
        Loading Tasks...
      </div>
      <div v-else-if="filteredTasksForUser && filteredTasksForUser.length > 0">
        <div v-if="employeeStore.currentUser.user?.is_superuser">
          <TaskList :tasks="filteredTasksForUser"
            :visibleColumns="['id', 'name', 'description', 'job_name', 'started', 'concluded', 'total_active_duration', 'labor_cost', 'assigned', 'actions']">
          </TaskList>
        </div>
        <div v-if="employeeStore.currentUser.user?.is_staff && !employeeStore.currentUser.user?.is_superuser">
          <TaskList :tasks="filteredTasksForUser"
            :visibleColumns="['id', 'name', 'description', 'job_name', 'started', 'concluded', 'total_active_duration', 'assigned', 'actions']">
          </TaskList>
        </div>
      </div>
      <div v-else>
        No tasks to display.
      </div>
      <br>
      <div>
        <router-link to="/ops/allusers" class="apple-button">Back to User List</router-link>
      </div>
    </div>
  </div>
  <div v-else class="loading-container">
    <p></p>
  </div>
</template>

<script setup lang="ts">
import TaskList from "@/components/TaskList.vue";
import DateTimeBadge from "@/components/DateTimeBadge.vue";
import { useEmployeeStore } from '@/stores/UserEmployee';
import { useTaskStore } from '@/stores/Tasks';
import { storeToRefs } from "pinia";
import { onMounted, computed, ref } from "vue";
import { useRoute, useRouter } from 'vue-router';
import { UserEmployee, WorkCenter } from '@/types/Ops';

interface EmployeeDetail extends UserEmployee {
  work_centers?: WorkCenter[];
}


const route = useRoute();
const router = useRouter();
const employeeStore = useEmployeeStore();
const taskStore = useTaskStore();

const { userRole, currentUser, getById } = storeToRefs(employeeStore);
const { all_tasks, isLoadingTaks } = storeToRefs(taskStore);

const employeeData = ref<EmployeeDetail | null>(null);

const navigateToUserView = (userEmployeeId) => {
  router.push({ name: 'edit-employeeDetails', params: { userEmployeeId } });
};

const fetchEmployeeData = async () => {
  const userEmployeeId = Number(route.params.userEmployeeId);
  try {
    const userEmployee = getById.value(userEmployeeId);
    if (userEmployee) {
      employeeData.value = userEmployee;
    } else {
      console.error('UserEmployee not found');
    }
  } catch (error) {
    console.error('Error fetching UserEmployee data:', error);
  }
};


const filteredTasksForUser = computed(() => {
  if (!employeeData.value || !all_tasks.value || all_tasks.value.length === 0) {
    return [];
  }
  return all_tasks.value.filter(task =>
    task.assignments && task.assignments.some(assignment => assignment.user === employeeData.value.id)
  );
});


onMounted(async () => {
  const userEmployeeId = Number(route.params.userEmployeeId);
  if (!userEmployeeId) {
    // Handle invalid user ID case
    console.error('Invalid User ID');
    return;
  }
  try {
    await employeeStore.fetch();
    const employee = getById.value(userEmployeeId);
    if (employee) {
      employeeData.value = employee;
      await taskStore.getAllTasks();
    } else {
      console.error('Employee not found');
    }
  } catch (error) {
    console.error('Error fetching employee data:', error);
  }
});

</script>

<style scoped>
.whole-employee {
  flex-grow: 1;
  margin: 2% 10%;
}

.employee-details {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white !important;
  min-height: 100vh;
}

.profile-header {
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  background-color: rgba(207, 226, 255, 1.000);
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0.1, 0, 0, 0.1);
}

.profile-photo {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  margin-right: 30px;
}

.profile-info {
  flex-grow: 1;
}

.edit-symbol {
  cursor: pointer;
  padding-left: 8px;
  font-size: 0.8em;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-container::after {
  content: '';
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>