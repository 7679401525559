<template>
  <div class="work-and-cost-center">
    <h1>Work and Cost Center</h1>
    <div class="flex-container">
      <!-- Work Centers Section -->
      <div class="table-wrapper">
        <div class="title-with-action">
          <h2>WorkCenters</h2>
          <button class="action-button add" @click="addWorkCenter">➕ Add WorkCenter</button>
        </div>
        <table class="styled-table" v-if="!isLoadingWorkCenters">
          <thead>
            <tr>
              <th>S. No</th>
              <th>Name</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(workCenter, index) in workCenters" :key="`work-${index}`">
              <td>{{ index + 1 }}</td>
              <td>{{ workCenter.name }}</td>
              <td>{{ workCenter.description }}</td>
              <td>
                <button class="action-button delete" @click="deleteWorkCenter(workCenter.id)">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else>Loading WorkCenters...</div>
      </div>

      <!-- Cost Centers Section -->
      <div class="table-wrapper">
        <div class="title-with-action">
          <h2>CostCenters</h2>
          <button class="action-button add" @click="addCostCenter">➕ Add CostCenter</button>
        </div>
        <table class="styled-table" v-if="!isLoadingCostCenters">
          <thead>
            <tr>
              <th>S. No</th>
              <th>Name</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(costCenter, index) in costCenters" :key="`cost-${index}`">
              <td>{{ index + 1 }}</td>
              <td>{{ costCenter.name }}</td>
              <td>{{ costCenter.description }}</td>
              <td>
                <button class="action-button delete" @click="deleteCostCenter(costCenter.id)">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else>Loading CostCenters...</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { fetchWorkCenters, fetchCostCenters, deleteFromUrl } from '@/api/api';

const workCenters = ref([]);
const costCenters = ref([]);
const isLoadingWorkCenters = ref(true);
const isLoadingCostCenters = ref(true);
const router = useRouter();

const fetchWorkAndCostCenters = async () => {
  workCenters.value = await fetchWorkCenters();
  costCenters.value = await fetchCostCenters();
  isLoadingWorkCenters.value = false;
  isLoadingCostCenters.value = false;
};

const addWorkCenter = () => {
  router.push("/ops/work-cost-center");
};

const addCostCenter = () => {
  router.push("/ops/work-cost-center");
};

const deleteWorkCenter = async (id) => {
  if (confirm("Are you sure you want to delete this WorkCenter?")) {
    try {
      await deleteFromUrl(`/ops/api/workcenters/${id}/`);
      alert("WorkCenter deleted successfully.");
      await fetchWorkAndCostCenters();
    } catch (error) {
      alert("Failed to delete WorkCenter.");
      console.error("Error deleting WorkCenter:", error);
    }
  }
};

const deleteCostCenter = async (id) => {
  if (confirm("Are you sure you want to delete this CostCenter?")) {
    try {
      await deleteFromUrl(`/ops/api/costcenters/${id}/`);
      alert("CostCenter deleted successfully.");
      await fetchWorkAndCostCenters();
    } catch (error) {
      alert("Failed to delete CostCenter.");
      console.error("Error deleting CostCenter:", error);
    }
  }
};

onMounted(fetchWorkAndCostCenters);
</script>
<style scoped>
.work-and-cost-center {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0.1, 0.1, 0.1, 0.2);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.flex-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  justify-content: center;
  width: 80%;
}

.table-wrapper {
  margin: 20px;
  margin-top: 30px;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0.1, 0, 0, 0.1);
}

.styled-table {
  width: 100%;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  font-size: 0.9em;
  border: 1px solid #ddd;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px;
}

.styled-table thead tr {
  background-color: rgb(75, 82, 219);
  color: #ffffff;
  text-align: left;
  border-radius: 10px;
  height: 70px;
}

.styled-table thead tr:first-child th:first-child {
  border-top-left-radius: 10px;
}

.styled-table thead tr:first-child th:last-child {
  border-top-right-radius: 10px;
}

.styled-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.styled-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.styled-table tbody tr td:first-child {
  border-left: 1px solid #ddd;
}

.styled-table tbody tr td:last-child {
  border-right: 1px solid #ddd;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #f3f3f3;
}

.styled-table tbody tr:hover {
  background-color: #e8e8e8;
}

.action-button {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px 10px 10px 10px;
  border: none;
  border-radius: 25px;
  background-color: #f7f4f4;
  color: black;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
  transform: scale(1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.action-button.add:hover {
  color: #FFFFFF;
  background-color: rgb(102, 211, 102);
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.action-button.delete:hover {
  color: #FFFFFF;
  background-color: rgb(219, 80, 75);
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.title-with-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
</style>
