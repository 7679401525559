<template>
  <div class="create-user-form" data-bs-theme="light">
    <h2>Create New User</h2>
    <form @submit.prevent="createUser">
      <div class="form-group">
        <label for="firstName">First Name</label>
        <input type="text" id="firstName" v-model="user.first_name" required>
      </div>
      <div class="form-group">
        <label for="lastName">Last Name</label>
        <input type="text" id="lastName" v-model="user.last_name" required>
      </div>
      <div class="form-group">
        <label for="email">Email</label>
        <input id="email" v-model="user.email" type="email" required>
      </div>
      <div class="form-group">
        <label for="username">Username</label>
        <input id="username" v-model="user.username" type="text" required>
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input :type="showPassword ? 'text' : 'password'" id="password" v-model="user.password" required>
        <div class="row">
          <label for="showPassword" class="col">Show Password</label>
          <input type="checkbox" id="showPassword" class="col-1 form-check-input" v-model="showPassword">
        </div>
      </div>
      <div class="form-group">
        <label for="dateOfJoining">Date of Joining</label>
        <input id="dateOfJoining" class="form-control" v-model="user.date_joined" type="date" required>
      </div>
      <br>
      <div class="row form-group">
        <label for="isSuperuser" class="col">Is Admin? <br>
          <p>Designates that this user has all permissions without explicitly assigning them.</p>
        </label>
        <input id="isSuperuser" v-model="user.is_superuser" class="col-1 form-check-input" type="checkbox">
      </div>
      <div class="row form-group">
        <label for="isStaff" class="col">Is Manager? <br>
          <p>Designates the user as Manager(Manager is able to create,view,edit and delete jobs, tasks).</p>
        </label>
        <input id="isStaff" v-model="user.is_staff" class="col-1 form-check-input" type="checkbox">
      </div>
      <div class="row form-group">
        <label for="isActive" class="col">Is Active? <br>
          <p>Designates whether this user should be treated as active. Unselect this instead of deleting accounts</p>
        </label>
        <input id="isActive" v-model="user.is_active" class="col-1 form-check-input" type="checkbox" required>
      </div>
      <div class="row form-group">
        <label class="col inline-label">Do you want to add this user as an employee? <br>
          <p>
            User as an employee means whether this user is going to be employed and going to have a certain hourly pay
          </p>
        </label>
        <div class="col-1">
          <input class="form-check-input" type="radio" id="yes" value="yes" v-model="addAsEmployee">
          <label for="yes">Yes</label>
        </div>
        <div class="col-1">
          <input class="form-check-input" type="radio" id="no" value="no" v-model="addAsEmployee">
          <label for="no">No</label>
        </div>
      </div>

      <!-- Employee-specific fields displayed if 'Yes' is selected -->
      <div v-if="addAsEmployee === 'yes'">
        <div class="form-group">
          <label for="workCenter">Work Center</label>
          <select id="workCenter" class="form-select" v-model="selectedWorkCenterId">
            <option v-for="workCenter in workCenters" :key="workCenter.id" :value="workCenter.id">
              {{ workCenter.name }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="hourly_pay">Hourly Pay <b>($)</b></label>
          <input id="hourly_pay" v-model="employee.hourly_pay" type="number" required>
        </div>
        <div class="form-group">
          <label for="active">Active</label>
          <input id="active" type="checkbox" class="form-check-input" v-model="employee.active">
        </div>
      </div>
      <div class="form-group">
        <button type="submit">Create User</button>
      </div>
    </form>
  </div>
</template>


<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { postToUrl } from '@/api/api';
import { useUserStore } from '@/stores/UserStore';
import { User } from '@/types/Ops';
import { WorkCenter } from '@/types/Ops';
import { fetchWorkCenters } from '@/api/api';

interface UserEmployee {
  user: number;
  hourly_pay: number;
  workCenterId: number;
  active: boolean;
}

interface UserResponse {
  id?: number;
  username: string;
  password: string;
  first_name: string;
  last_name: string;
  email: string;
  is_superuser: boolean;
  is_staff: boolean;
  is_active: boolean;
  date_joined: string;
}

const user = ref<User>({
  // Initial user data
  username: '',
  password: '',
  first_name: '',
  last_name: '',
  email: '',
  is_superuser: false,
  is_staff: false,
  is_active: true,
  date_joined: ''
});
const selectedWorkCenterId = ref<number | null>(null);
const showPassword = ref(false);
const addAsEmployee = ref<string>('');
const employee = ref<UserEmployee>({
  // Initial employee data
  user: 0,
  hourly_pay: 0,
  workCenterId: 0,
  active: false
});
const workCenters = ref<WorkCenter[]>([]);

const resetUserData = () => {
  user.value = {
    username: '',
    password: '',
    first_name: '',
    last_name: '',
    email: '',
    is_superuser: false,
    is_staff: false,
    is_active: true,
    date_joined: ''
  };
  addAsEmployee.value = '';
  selectedWorkCenterId.value = null;
  employee.value = {
    user: 0,
    hourly_pay: 0,
    workCenterId: 0,
    active: false
  };
};

onMounted(async () => {
  workCenters.value = await fetchWorkCenters();
});

const createUser = async () => {
  // console.log('Attempting to create user with data:', user.value);
  try {
    console.log('Sending request to backend...');
    const userResponse = await postToUrl('/ops/api/Createuser/', user.value) as UserResponse;
    // console.log('Response received:', userResponse);
    if (userResponse && userResponse.id) {
      // console.log('User creation successful with ID:', userResponse.id);
      let employeeData;
      if (addAsEmployee.value === 'yes' && selectedWorkCenterId.value !== null && !isNaN(selectedWorkCenterId.value)) {
        employeeData = {
          user: userResponse.id,
          hourly_pay: employee.value.hourly_pay,
          active: employee.value.active,
          work_centers: [Number(selectedWorkCenterId.value)]
        };
      } else {
        employeeData = {
          user: userResponse.id,
          workCenterId: null,
          hourly_pay: null,
          active: null,
          work_centers: []
        };
      }
      const employeeResponse = await postToUrl('/ops/api/create_user_employee/', employeeData);
      if (employeeResponse) {
        console.log('Employee data processed successfully.');
        alert('User and employee data processed successfully!');
      } else {
        console.error('Failed to process employee data:', employeeResponse);
        alert('Failed to process employee data. Please check the details.');
      }
    } else {
      console.error('User creation failed with response:', userResponse);
      alert("Failed to create user. Please check the provided data.");
    }
  } catch (error) {
    console.error('Error creating user:', error);
    alert("Failed to create user. Error: " + error.message);
  } finally {
    resetUserData();
  }
};


const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value;
};

</script>


<style scoped>
.create-user-form {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0.1, 0.1, 0.1, 0.2);
  margin: 2% 30%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white !important;
  min-height: 100vh;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="password"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #FFFFFF;
  color: #000000;
}

button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
</style>