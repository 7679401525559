<template>
  <div class="home">
    <div v-if="!taskStore.isLoadingTaks" class="taskWrapper d-flex flex-row">
      <div class="taskId">
        <h1 class="col-auto">{{ task?.id + " |" }}</h1>
      </div>
      <div class="whole-task">
        <div class="taskContent">
          <div
            v-if="employeeStore.currentUser.user?.is_superuser || (employeeStore.currentUser.user?.is_staff && !employeeStore.currentUser.user?.is_superuser)"
            class="form-check form-switch">
            <input class="form-check-input" id="editswitch" type="checkbox" v-model="editState" />
            <label class="form-check-label" for="editswitch">Enable Editing</label>
          </div>
          <div v-if="task">
            <div class="task-header">
              <div class="row">
                <h1>
                  <input class="task-name-input" :disabled="!editState" type="text" v-model="task.name" />
                </h1><br>
                <textarea class="task-description-textarea" :disabled="!editState"
                  oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
                  v-model="task.description"></textarea>
              </div>
            </div>
            <br>
            <div class="task-details">
              <div class="row">
                <div class="row py-2">
                  <div class="col col-4">
                    Priority :
                  </div>
                  <div class="col col-8">
                    <b>{{ priorityLabel }}</b>
                  </div>
                </div>
                <div class="row py-2">
                  <div class="col col-4">
                    Job Name :
                  </div>
                  <div class="col col-8">
                    <b>
                      {{ task.job_name ? task.job_name : 'N/A' }}
                    </b>
                  </div>
                </div>
                <div class="row py-2">
                  <div class="col col-4">
                    Created By :
                  </div>
                  <div v-if="task" class="col col-8">
                    <UserBadge :user-id="createdById" />
                  </div>
                </div>
                <div class="row py-2">
                  <div class="col col-4">
                    Started By :
                  </div>
                  <div v-if="task" class="col col-8">
                    <UserBadge :user-id="startedById" />
                  </div>
                </div>
                <br>
                <div class="row py-2">
                  <div class="col col-4">
                    Assigned To :
                  </div>
                  <div class="col col-8">
                    <span v-for=" assignment  in  task.assignments " v-bind:key="assignment.id"
                      class="userBadgeContainer">
                      <UserBadge v-if="assignment?.user" :user-id="assignment?.user" />
                    </span>
                    <span class="float-end">
                      <Modal :show-button-text="editState ? '+' : undefined" title="Select Users">
                        <span v-for=" assignment  in  task.assignments " v-bind:key="assignment.id"
                          class="userBadgeContainer">
                          <UserBadge v-if="assignment?.user" :user-id="assignment?.user" />
                        </span>
                        <span class="form-check" v-for=" us  in  employees " :key="us.user.id">
                          <input class="form-check-input" type="checkbox"
                            :checked="task.assignments.find(assignment => assignment.user == us.user.id) ? true : false"
                            @change="(ev) => modifyAssignment(ev, us.user.id)" :id="'user' + us.user.id">
                          <label class="form-check-label" :for="'user' + us.user.id">
                            <UserBadge :user-id="us.user.id" />
                          </label>+
                        </span>
                      </Modal>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="row py-2">
                  <div class="col col-4">
                    Work Center :
                  </div>
                  <div class="col col-8">
                    <select class="form-control border-0 customDropdown" v-model="selectedWorkCenter"
                      :disabled="!editState" @change="event => { updateWorkCenter() }" style="--bs-bg-opacity: 0.9;">
                      <option :value="null"><em>None</em></option>
                      <option v-for=" wc  in  work_centers " :key="wc?.id" :value="wc.id">{{ wc.name }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row py-2">
                <div class="col col-4">
                  Expected Start Date :
                </div>
                <div class="col col-8">
                  <DateTimeBadge :time="task?.expected_start" />
                </div>
              </div>
              <div class="row py-2">
                <div class="col col-4">
                  Start Date :
                </div>
                <div class="col col-8">
                  <DateTimeBadge :time="task.started" />
                </div>
              </div>
              <div class="row py-2">
                <div class="col col-4">
                  Expected Conclusion Date :
                </div>
                <div class="col col-8">
                  <DateTimeBadge :time="task?.expected_concluded" />
                </div>
              </div>
              <div class="row py-2">
                <div class="col col-4">
                  Concluded Date :
                </div>
                <div class="col col-8">
                  <DateTimeBadge :time="task.concluded" />
                </div>
              </div>
              <div class="row py-2">
                <div class="col col-4">
                  Man Hours<br>(HH/MM/SS) :
                </div>
                <div class="col col-8">
                  {{ truncateMilliseconds(task.total_active_duration) }}
                </div>
              </div>
              <div v-if="employeeStore.currentUser.user?.is_superuser" class="row py-2">
                <div class="col col-4">
                  Total Cost :
                </div>
                <div class="col col-8">
                  ${{ task?.labor_cost }}
                </div>
              </div>
            </div>
            <div class="row actionButtons">
              <button class="btn btn-primary customSaveButton" @click="save">Save</button>
              <button class="btn btn-primary" v-if="saved == SaveState.Saving" disabled>Saving...</button>
              <span class="alert alert-success rounded" v-if="saved == SaveState.Saved" disabled>Saved</span>
              <span class="alert alert-danger rounded" v-if="saved == SaveState.SaveFailed" disabled>Save Failed</span>
            </div>
          </div>
        </div>
        <br>
        <div v-if="isCurrentUserAssignedToTask" class="task-action">
          <div v-if="taskId">
            <h2>Active Task</h2>
            <div>
              <TaskInline :taskId="taskId" />
              <div class="timer" v-if="task">
                <b>
                  <TimeSince :duration="formattedTaskDuration" :eventType="lastEventType" :taskId="task.id" />
                </b>
              </div>
            </div>
          </div>
          <div v-else>
            <h2>No Active Task</h2>
          </div>
        </div>
        <br>
        <br>
        <div class="task-event">
          <h2>Task Events</h2>
          <TaskEventInline :events="task?.events || []" />
        </div>
      </div>
    </div>
    <div v-else class="loading-container">
      <p></p>
    </div>
  </div>
</template>

<script setup lang="ts">
import Modal from '@/components/Modal.vue';
import TaskEventInline from '@/components/TaskEventInline.vue';
import UserBadge from '@/components/UserBadge.vue';
import TaskInline from '@/components/TaskInline.vue'
import TimeSince from '@/components/TimeSince.vue';
import DateTimeBadge from '@/components/DateTimeBadge.vue';
import { TaskPriority } from '@/types/Ops';
import { useOpsSupplementStore } from '@/stores/OpsSupplement';
import { useTaskStore } from '@/stores/Tasks';
import { useEmployeeStore } from '@/stores/UserEmployee';
import { storeToRefs } from 'pinia';
import { computed, ref, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { fetchTaskById } from '@/api/api';

const editState = ref(false);
const taskStore = useTaskStore();
const employeeStore = useEmployeeStore();
const { employees, currentUser } = storeToRefs(employeeStore);
const opsSupp = useOpsSupplementStore();
const { work_centers } = storeToRefs(opsSupp);
const route = useRoute();
const taskId = ref(parseInt(route.params.id as string));

const task = computed(() => taskStore.getTaskById(taskId.value));
const selectedWorkCenter = ref(task.value?.work_center?.id || null);
const startedById = computed(() => task.value?.started_by ?? -1);
const createdById = computed(() => task.value?.created_by ?? -1);

const isCurrentUserAssignedToTask = computed(() => {
  const assignedUserIds = task.value?.assignments.map(assignment => assignment.user);
  return assignedUserIds?.includes(currentUser.value.id);
});

enum SaveState {
  NoChanges = 0,
  Changed = 1,
  Saving = 2,
  SaveFailed = 3,
  Saved = 4
}

function truncateMilliseconds(durationStr) {
  return durationStr ? durationStr.split('.')[0] : '00:00:00';
}

const saved = ref<SaveState>(SaveState.NoChanges);

const formattedTaskDuration = computed(() => {
  const duration = task.value?.total_active_duration;
  if (duration) {
    const durationStr = typeof duration === 'number' ? duration.toString() : duration;
    const durationSeconds = parseDurationToSeconds(durationStr);
    return convertSecondsToHHMMSS(durationSeconds);
  }
  return '00:00:00';
});

function parseDurationToSeconds(durationStr: string): number {
  const parts = durationStr.match(/(\d+):(\d+):(\d+)/);
  if (parts) {
    const hours = parseInt(parts[1], 10);
    const minutes = parseInt(parts[2], 10);
    const seconds = parseInt(parts[3], 10);
    return hours * 3600 + minutes * 60 + seconds;
  }
  return 0;
}

function convertSecondsToHHMMSS(seconds) {
  const pad = (num) => String(num).padStart(2, '0');
  const hours = pad(Math.floor(seconds / 3600));
  const minutes = pad(Math.floor((seconds % 3600) / 60));
  const secs = pad(seconds % 60);
  return `${hours}:${minutes}:${secs}`;
}

const lastEventType = computed(() => {
  const lastEvent = task.value?.events.slice(-1)[0]?.event_type || '';
  return lastEvent;
});

watch(() => task, (nextTask, lastTask) => {
  if (lastTask.value != undefined)
    saved.value = SaveState.Changed;
}, { deep: true })

watch(() => route.params, (nextParam, lastParam) => {
  taskId.value = parseInt(nextParam.id as string);
});

watch(() => task.value, (newTaskValue) => {
  selectedWorkCenter.value = newTaskValue?.work_center?.id || null;
});

watch(() => task.value?.events, () => {
  // The logic here will now just update the lastEventType
  // console.log("Last event type:", lastEventType.value);
}, { deep: true });

const getWorkCenterName = (workCenterId: number | null) => {
  const wc = work_centers.value.find(w => w.id === workCenterId);
  return wc ? wc.name : 'None';
};

const priorityLabel = computed(() => {
  switch (task.value?.priority) {
    case TaskPriority.Low:
      return 'Low';
    case TaskPriority.Medium:
      return 'Medium';
    case TaskPriority.High:
      return 'High';
    default:
      return 'Not Set';
  }
});


onMounted(async () => {
  await employeeStore.fetch();
  await opsSupp.fetch();
  try {
    if (!taskStore.isLoadingTaks) {
      await taskStore.fetch();
    }
    const taskDetails = await fetchTaskById(taskId.value);
    selectedWorkCenter.value = taskDetails.work_center ? taskDetails.work_center.id : null;
    // console.log("Initial task value after fetching: ", task.value);
  } catch (error) {
    console.error("Error fetching task details: ", error);
  }
});

const updateWorkCenter = async () => {
  try {
    if (selectedWorkCenter.value !== null) {
      taskStore.updateWorkCenter(taskId.value, selectedWorkCenter.value);
    }
  } catch (error) {
    console.error("Error updating work center: ", error);
  }
};

const modifyAssignment = async (event: Event, uid: number) => {
  let newAssignment = task.value.assignments.map(a => a.user);
  let existing = newAssignment.findIndex(auid => auid == uid);
  if ((event.target as HTMLInputElement).checked && existing < 0) {
    newAssignment.push(uid);
  } else if (!(event.target as HTMLInputElement).checked && existing >= 0) {
    newAssignment.splice(existing, 1);
  }
  await taskStore.setAssignment(taskId.value, newAssignment);
}

const save = () => {
  editState.value = false;
  saved.value = SaveState.Saving;
  taskStore.save(taskId.value)
    .then(() => {
      console.log("Task was successfully saved.");
      saved.value = SaveState.Saved;
    }, (error) => {
      console.error("Error while saving task:", error);
      editState.value = true;
      saved.value = SaveState.SaveFailed;
    });
}
</script>

<style scoped>
.home {
  padding: 20px;
  display: flex;
  /* margin: 2% 20%; */
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.taskWrapper {
  margin: 2% 20%;
  display: flex;
  flex-direction: row;
}

.whole-task {
  flex-grow: 1;
}

.taskId {
  flex-basis: 10%;
  width: 100px;
  padding-right: 10px;
}

.taskContent {
  flex-basis: 90%;
  width: 100%;
  padding: 0.5rem;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0.1, 0, 0, 0.1);
}

.task-header {
  color: #000000;
  padding: 0.5rem;
  margin-bottom: 10px;
  width: 100%;
  border: 0;
  display: inline-block;
  background-color: rgba(89, 100, 100, 0.1);
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0.1, 0, 0, 0.1);
}

.task-name-input {
  color: #000000;
  margin: 0.25rem;
  padding: 0.25rem;
  border: none;
  background-color: transparent;
  width: 100%;
}

.task-description-textarea {
  color: #000000;
  margin: 0.25rem;
  padding: 0.25rem;
  border: none;
  background-color: transparent;
  width: 100%;
  resize: none;
}

.task-details {
  color: #000000;
  padding: 0.5rem;
  width: 100%;
  border: 0;
  display: inline-block;
  background-color: rgba(207, 226, 255, 1.000);
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0.1, 0, 0, 0.1);
}

.userBadgeContainer {
  display: inline-block;
  margin-right: 5px;
}

.customDropdown {
  max-width: 300px;
}

.actionButtons {
  justify-content: center;
}

.customSaveButton {
  margin-top: 20px;
  width: 100px;
  padding-left: 20px;
  padding-right: 20px;
}

.task-action {
  color: #000000;
  padding: 0.5rem;
  width: 100%;
  border: 0;
  display: inline-block;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0.1, 0, 0, 0.1);
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 20px;
  max-width: 150%;
}

h2 {
  color: #007bff;
  background-color: white;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 0;
  text-align: center;
}

.task-event {
  color: #000000;
  padding: 0.5rem;
  width: 100%;
  border: 0;
  display: inline-block;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0.1, 0.1, 0.1, 0.1);
}

.timer {
  font-size: 40px;
  color: #007bff;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-container::after {
  content: '';
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
