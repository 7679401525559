import { UserEmployee } from '@/types/Ops';
import { fetchFromUrl, fetchEmployeeById, fetchAllTasksWithAssignments } from '@/api/api'
import { defineStore } from 'pinia'


interface UserEmployeeState {
    currentUser: UserEmployee | null,
    employees: UserEmployee[] | null,
    isAuth: boolean | null
}

export const useEmployeeStore = defineStore('userEmployee', {
    state: (): UserEmployeeState => ({ currentUser: null, employees: null, isAuth: null }),
    actions: {
        async fetch() {
            const currentReq = fetchFromUrl<UserEmployee>("/ops/api/user/current/")
                .then(user => {
                    this.currentUser = user;
                    this.isAuth = true;
                }).catch(() => {
                    this.isAuth = false;
                });
            const allReq = fetchFromUrl<UserEmployee[]>("/ops/api/users/")
                .then(users => {
                    // console.log("Loaded employees:", users);  // <-- Added this line
                    this.employees = users;
                });
            return Promise.all([currentReq, allReq]);
        },
         async fetchEmployeeDetails(employeeId) {
            try {
                const employeeDetails = await fetchEmployeeById(employeeId);
                const allTasks = await fetchAllTasksWithAssignments();
                const employeeTasks = allTasks.filter(task =>
                    task.assignments.some(assignment => assignment.user === employeeId)
                );
                return {
                    ...employeeDetails,
                    tasks: employeeTasks
                };
            } catch (error) {
                console.error('Error fetching employee details:', error);
                throw error;
            }
        },
        async fetchCurrentUser() {
            try {
                const user = await fetchFromUrl<UserEmployee>("/ops/api/user/current/");
                this.currentUser = user;
                this.isAuth = true;
            } catch (error) {
                console.error("Error fetching current user:", error);
                this.currentUser = null;
                this.isAuth = false;
                throw error;
            }
        },
    },
    getters: {
        getById: (state) => (id: number) => {
            return state.employees?.find(em => em.id == id);
        },
        getByIdforFullName: (state) => (userId: number) => {
            return state.employees?.find(em => em.id === userId);
        },
        hasPermission: (state) => (id: number, perm: string) => {
            if (state.employees?.find(em => em.user.id == id).user.user_permissions.find(p => p[0] == perm))
                return true;
            return false;
        },
        userRole: (state) => {
            // Determine user role based on the currentUser's attributes
            if (state.currentUser?.user.is_superuser || (state.currentUser?.user.is_superuser && state.currentUser?.user.is_staff)) {
                return 'admin';
            } else if (state.currentUser?.user.is_staff && !state.currentUser?.user.is_superuser) {
                return 'manager';
            } else {
                return 'employee';
            }
        },
    }
})