<template>
  <div class="overlay-background" v-if="!isLoading">
    <div class="user-edit-card">
      <div class="close-button" @click="navigateBack">&#10005;</div>
      <h2>Edit Employee Details</h2>
      <div class="toggle-switch">
        <input id="editToggle" type="checkbox" v-model="isEditing">
        <label for="editToggle" class="switch"></label>
      </div>
      <form @submit.prevent="saveEmployeeDetails">
        <div class="form-group">
          <label for="username">Username</label>
          <input id="username" v-model="employee.username" :readonly="!isEditing" type="text">
        </div>
        <div class="form-group">
          <label for="firstName">First Name</label>
          <input id="firstName" v-model="employee.first_name" :readonly="!isEditing" type="text">
        </div>
        <div class="form-group">
          <label for="lastName">Last Name</label>
          <input id="lastName" v-model="employee.last_name" :readonly="!isEditing" type="text">
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input id="email" v-model="employee.email" :readonly="!isEditing" type="email">
        </div>
        <div v-if="employeeStore.currentUser.user?.is_superuser" class=" form-group">
          <label for="hourlyPay">Hourly Pay</label>
          <input id="hourlyPay" v-model="employee.hourly_pay" :readonly="!isEditing" type="number" step="0.01">
        </div>
        <div class="form-group">
          <label for="dateJoined">Date of Joining</label>
          <input id="dateJoined" v-model="employee.date_joined" :readonly="!isEditing" type="date">
        </div>
        <div v-if="employeeStore.currentUser.user?.is_superuser" class="checkbox-group">
          <input type="checkbox" id="isSuperuser" v-model="employee.is_superuser" :disabled="!isEditing">
          <label for="isSuperuser">Is Admin</label>
          <input type="checkbox" id="isStaff" v-model="employee.is_staff" :disabled="!isEditing">
          <label for="isStaff">Is Manager</label>
          <input type="checkbox" id="isActive" v-model="employee.is_active" :disabled="!isEditing">
          <label for="isActive">Is Active</label>
        </div>
        <button type="submit" v-if="isEditing">Save</button>
      </form>
    </div>
  </div>
  <div v-else class="loading-container">
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, defineProps } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useEmployeeStore } from '@/stores/UserEmployee';
import { useUserStore } from '@/stores/UserStore';
import { fetchEmployeeById } from '@/api/api';
import { patchToUrl, fetchWorkCenters as fetchWorkCentersApi } from '@/api/api';

interface UserEmployee {
  id: number | null;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  date_joined: Date | string;
  is_staff: boolean;
  is_active: boolean;
  is_superuser: boolean;
  hourly_pay: number | null;
  work_center: number | string;
}

const route = useRoute();
const router = useRouter();
const employeeStore = useEmployeeStore();
const userStore = useUserStore();
const employeeData = ref(null);
const isEditing = ref(false);
const isLoading = ref(true);


const employee = ref<UserEmployee>({
  id: null,
  username: '',
  first_name: '',
  last_name: '',
  email: '',
  date_joined: '',
  hourly_pay: null,
  work_center: '',
  is_staff: false,
  is_active: false,
  is_superuser: false,
});

const navigateBack = () => {
  const userEmployeeId = Number(route.params.userEmployeeId);
  if (userEmployeeId) {
    router.push({ name: 'employeeDetail', params: { userEmployeeId } });
  } else {
    console.error('UserEmployee ID is missing');
  }
};

const workCenters = ref([]);

const fetchWorkCenters = async () => {
  workCenters.value = await fetchWorkCentersApi();
};

onMounted(async () => {
  await fetchEmployeeDetails();
  await fetchWorkCenters();
});

const toggleEditing = () => {
  isEditing.value = !isEditing.value;
};


const fetchEmployeeDetails = async () => {
  const userEmployeeId = Number(route.params.userEmployeeId);
  if (!userEmployeeId) {
    console.error("Invalid or missing employee ID");
    return;
  }

  try {
    await employeeStore.fetch();
    const fetchedEmployee = employeeStore.getById(userEmployeeId);

    if (fetchedEmployee) {
      const hourlyPay = fetchedEmployee.hourly_pay || null;
      const workCenterId = fetchedEmployee.work_center?.id || null;

      employee.value = {
        ...employee.value,
        id: fetchedEmployee.user.id,
        username: fetchedEmployee.user.username,
        first_name: fetchedEmployee.user.first_name,
        last_name: fetchedEmployee.user.last_name,
        email: fetchedEmployee.user.email,
        date_joined: formatDate(fetchedEmployee.user.date_joined),
        hourly_pay: hourlyPay,
        work_center: workCenterId,
        is_staff: Boolean(fetchedEmployee.user.is_staff),
        is_active: Boolean(fetchedEmployee.user.is_active),
        is_superuser: Boolean(fetchedEmployee.user.is_superuser),
      };
    } else {
      console.error('Employee not found');
    }
  } catch (error) {
    console.error('Error fetching employee details:', error);
  } finally {
    isLoading.value = false;
  }
};

const findWorkCenterName = (workCenterId) => {
  const center = workCenters.value.find(c => c.id === workCenterId);
  return center ? center.name : 'Not Assigned';
};


const formatDate = (dateStr) => {
  if (!dateStr) return '';
  const date = new Date(dateStr);
  return date.toISOString().split('T')[0];
};

const saveEmployeeDetails = async () => {
  console.log('Employee data:', employee.value);

  if (!employee.value.id) {
    console.error('Employee ID is undefined:', employee.value);
    alert("Employee ID is not found.");
    return;
  }

  try {
    const url = `/ops/api/Updateuser/${employee.value.id}/`;
    const updatedUserData = {
      username: employee.value.username,
      first_name: employee.value.first_name,
      last_name: employee.value.last_name,
      email: employee.value.email,
      date_joined: employee.value.date_joined,
      is_superuser: employee.value.is_superuser,
      is_staff: employee.value.is_staff,
      is_active: employee.value.is_active,
      hourly_pay: employee.value.hourly_pay,
      work_center: employee.value.work_center,
    };

    console.log('Sending request to backend to update user...');
    const response = await patchToUrl(url, updatedUserData);

    if (response && response.id) {
      console.log('User update successful.');
      alert("User updated successfully.");
      isEditing.value = false;

    } else {
      console.error('User update failed with response:', response);
      alert("Failed to update user. Please check the provided data.");
    }
  } catch (error) {
    console.error('Error updating user:', error);
    alert("Failed to update user. Error: " + error.message);
  }
};

onMounted(fetchEmployeeDetails);

</script>
<style scoped>
.user-edit-card {
  position: relative;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 90%;
  margin: auto;
  margin-top: var(--navbar-height);
}

.overlay-background {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="date"] {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.checkbox-group {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.checkbox-group input[type="checkbox"] {
  accent-color: #007bff;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 10px 0;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.switch:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked+.switch {
  background-color: #2196F3;
}

input:checked+.switch:before {
  transform: translateX(26px);
}

button[type="submit"] {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  color: #333;
  background-color: #fff;
  border-radius: 50%;
  padding: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-container::after {
  content: '';
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
