import { fetchFromUrl, postToUrl, patchToUrl, fetchTasksByJobId, fetchAllTaks, fetchTaskById } from '@/api/api'
import { defineStore } from 'pinia'
import { Task, TaskEvent, TaskEventType, WORKING } from '@/types/Ops'

interface TasksState {
    my_tasks?: number,
    all_tasks?: Task[],
    current_task?: number,
    isLoadingTaks: boolean;
    timerStartTimestamp?:number;
    isTimerRunning?:boolean;
}

export const useTaskStore = defineStore('tasks', {
    state: () => ({ my_tasks: undefined, current_task: undefined, all_tasks: undefined, isLoadingTaks:true, timerStartTimestamp:null
    ,isTimerRunning:false } as TasksState),
    actions: {
        getAllTasks() {
            this.isLoadingTaks = true;
            fetchAllTaks()
                .then(tasks => {
                    this.all_tasks = tasks;
                    // console.log("[fetchAllTaks] Tasks fetched successfully:", this.all_tasks);
                }).catch(error =>{
                    console.error("Error fetching all tasks:",error)
                })
                .finally(()=>{
                    this.isLoadingTaks=false;
                });
        },
        async setAssignment(id: number, assignment: number[]) {
            const task = this.all_tasks.find(t => t.id == id);
            if (!task) {
                console.error("Task not found for assignment");
                return;
            }
            // console.log("Attempting to assign users:", assignment, "to task:", task);
            const data = {
                task_id: id,
                user_ids: assignment
            };
            try {
                const response = await postToUrl("/ops/api/set_task_assignment/", data);
                // console.log("Response from assignment update:", response);
                // Refresh the task in the state after updating assignments
                const updatedTask = await fetchTaskById(id);
                this.$patch((state) => {
                    const index = state.all_tasks.findIndex(t => t.id == updatedTask.id);
                    if (index !== -1) {
                        state.all_tasks.splice(index, 1, updatedTask);
                    }
                });
        
            } catch (error) {
                console.error("Error while updating task assignments:", error);
            }
        },
        updateWorkCenter(taskId: number, workCenterId: number) {
            const task = this.all_tasks.find(t => t.id == taskId);
            if (!task) {
                console.error("Task not found");
                return;
            }
            // console.log("Attempting to update work center to ID:", workCenterId, "for task:", task);        
            const updatedTask = { ...task, work_center_write: workCenterId };
            // console.log("Payload for PATCH request:", updatedTask);
            patchToUrl("/ops/api/tasks/" + taskId + "/", updatedTask)
                .then((returnedTask) => {
                    // console.log("Task returned from backend after updating work center:", returnedTask);
                    this.$patch((state) => {
                        const index = state.all_tasks.findIndex(t => t.id == returnedTask.id);
                        if (index !== -1) {
                            state.all_tasks.splice(index, 1, returnedTask);
                        }
                    });
                })
                .catch((error) => {
                    console.error("Failed to update work center in backend:", error);
                });
        },      
        async save(id: number | string) {
            const taskToUpdate = this.getTaskById(id);
            if (!taskToUpdate) {
                console.error(`Task with ID ${id} not found for saving.`);
                return;
            }
            // console.log("Attempting to save task:", taskToUpdate);
            try {
                const response = await patchToUrl("/ops/api/tasks/" + id + "/", taskToUpdate);
                
                if (response && (response.status === 200 || response.status === 201)) {  // Check for success status codes
                    // console.log("Task returned from backend after saving:", response.data);
                    this.$patch((state) => {
                        const index = state.all_tasks.findIndex(task => task.id == response.data.id);
                        if (index !== -1) {
                            state.all_tasks.splice(index, 1, response.data);
                        }
                    });
                }else{
                    // console.error("Response is undefined or not in the expected format.");
                }
            } catch (error) {
                console.error("Error while trying to save task in backend:", error);
            }
        },        
        async fetch(): Promise<boolean> {
            this.isLoadingTaks = true;
            try {
                const tasks = await fetchFromUrl<Task[]>("/ops/api/mytasks/");
                this.my_tasks = tasks;
                const current = await fetchFromUrl<number | undefined>("/ops/api/task/current/");
                this.current_task = current;
                this.isLoadingTaks = false;
                return true;
            } catch (error) {
                // console.error("Error fetching tasks:", error);
                this.isLoadingTaks = false;
                return false;
            }
        },
        getLastCurrentUserEvent(taskId: number, employeeId: number) {
            const task = this.getTaskById(taskId) as Task;
            const evArr = task?.events ? [...task.events] : [];
            evArr.sort((a, b) => (new Date(b.timestamp).getTime()) - (new Date(a.timestamp)).getTime());
            return evArr.find(ev => ev.employee == employeeId);
        },
        async getTasksForJob(jobId: number) {
            try {
                const tasksForJob = await fetchTasksByJobId(jobId);
                this.$patch((state) => {
                    state.all_tasks = tasksForJob;
                });
            } catch (error) {
                console.error("Error fetching tasks for job:", error);
            }
        },
        async addEvent(event: TaskEvent): Promise<boolean> {
            try {
            // console.log("Sending event data to server:", event);
            await postToUrl<TaskEvent>("/ops/api/task/action/", event);
            const updatedTask = await fetchTaskById(event.task);
            this.$patch((state) => {
                const index = state.all_tasks.findIndex(t => t.id == updatedTask.id);
                if (index !== -1) {
                state.all_tasks.splice(index, 1, updatedTask);
                }
            });
            // console.log(`Event of type ${event.event_type} added. Updated task fetched.`);
            return true;
            } catch (error) {
            console.error("Error adding event or fetching updated task:", error);
            return false;
            }
        },
        startOrResumeTask(taskId: number) {
            const task = this.all_tasks.find(t => t.id === taskId);
            if (task) {
                // console.log(`[TaskStore] Starting/Resuming task ${taskId}. Total active duration: ${task.total_active_duration}`);
        const durationParts = task.total_active_duration.split(':').map(part => parseFloat(part));
        const totalDurationMs = (durationParts[0] * 3600 + durationParts[1] * 60 + durationParts[2]) * 1000;
        this.timerStartTimestamp = Date.now() - totalDurationMs; // Set to the correct timestamp
        this.isTimerRunning = true;
            } else {
                console.error(`[TaskStore] Task with ID ${taskId} not found.`);
            }
        },
        pauseOrStopTask(taskId: number) {
            // console.log(`[TaskStore] Pausing/Stopping task ${taskId}.`);
            this.isTimerRunning = false;
        },
    },
    getters: {
        taskEmployeeState() {
            return (taskId: number, employeeId: number): TaskEventType | undefined => {
                const task = this.getTaskById(taskId);
                if (task == undefined)
                    return undefined;
                const employeeEvents = task.events?.filter(t => t.employee == employeeId) ?? [];

                employeeEvents.sort((evl, evr) => ((new Date(evr.timestamp ?? "")).getTime() ?? 0) - ((new Date(evl.timestamp ?? "")).getTime() ?? 0));
                return employeeEvents[0]?.event_type;
            }
        },
        getMyTasks() {
            return this.all_tasks?.filter(task => this.my_tasks?.findIndex(mtid => mtid == task.id) != -1);
        },
        getTaskById: (state) => (id: number): Task | undefined => {
            const task = state.all_tasks?.find(task => task.id == id);
            if (task)
                return task;
            return undefined;
        },
        getTasksByEmployeeId: (state) => (employeeId: number) => {
            return state.all_tasks?.filter(task =>
              task.assignments.some(assignment => assignment.user === employeeId)
            );
        },
        sortedTasksByLatestEvent: (state) => {
            if (!state.all_tasks) return [];
            return state.all_tasks.slice().sort((a, b) => {
            const lastEventA = a.events?.slice(-1)[0]?.timestamp || 0;
            const lastEventB = b.events?.slice(-1)[0]?.timestamp || 0;
            return new Date(lastEventB).getTime() - new Date(lastEventA).getTime();
            });
        },
    }
})