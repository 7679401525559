<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <Button :text="showButtonText" v-if="showButtonText" :on-click="() => internalShow = !internalShow" />
    <div :class="'modal ' + ((show || internalShow) ? 'd-block show' : '')" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ props.title }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" @click="doClose"
                        aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>{{ bodyText }}</p>
                    <slot />
                </div>
                <div class="modal-footer">
                    <button v-if="!hideClose" @click="doClose" type="button" class="btn btn-secondary"
                        data-bs-dismiss="modal">Close</button>
                    <button v-if="onConfirm" @click="doConfirm" type="button" class="btn btn-primary">{{ confirmText ||
                        "Confirm" }}</button>

                    <button v-for="button in props.customButtons" :key="button.text" @click="button.onClick" type="button"
                        :class="'btn btn-' + button.color">{{ button.text }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { defineProps, defineEmits, ref } from 'vue';
import Button from './Button.vue';


interface ButtonOption {
    color: string,
    text: string,
    onClick: () => void,
}

const props = defineProps<{
    title?: string,
    bodyText?: string,
    show?: boolean,
    showButtonText?: string,
    hideClose?: boolean,
    onClose?: () => boolean,
    onConfirm?: () => (boolean | Promise<boolean>),
    confirmText?: string,
    customButtons?: ButtonOption[]
}>();

const internalShow = ref(false);
const emit = defineEmits(['update:show'])

const doConfirm = () => {
    if (props.onConfirm && props.onConfirm()) {
        doClose();
    }
}

const doClose = () => {
    if (props.onClose && props.onClose()) {
        emit("update:show", false);
        internalShow.value = false;
    }
    else {
        emit("update:show", false);
        internalShow.value = false;
    }
}

</script>