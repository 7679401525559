import { defineStore } from 'pinia';
import { fetchGanttChartData } from '@/api/api';
import { GanttTask } from '@/types/Ops';

interface GanttChartState {
  tasks: GanttTask[];
  isLoading: boolean;
}

export const useGanttChartStore = defineStore('ganttChart', {
  state: (): GanttChartState => ({
    tasks: [],
    isLoading: false,
  }),
  actions: {
    async fetchGanttChartTasks() {
      this.isLoading = true;
      try {
        const ganttTasks = await fetchGanttChartData();
        this.tasks = ganttTasks;
      } catch (error) {
        console.error('Failed to fetch Gantt chart data:', error);
        // Handle error appropriately, possibly by setting an error state
      } finally {
        this.isLoading = false;
      }
    },
  },
  getters: {
    // Add getters as needed to retrieve specific slices of Gantt chart data
  },
});


/*
import { defineStore } from 'pinia';
import { fetchGanttChartData } from '@/api/api';
import { GanttTask } from '@/types/Ops';

interface GanttChartState {
  tasks: GanttTask[];
  isLoading: boolean;
}

export const useGanttChartStore = defineStore('ganttChart', {
  state: (): GanttChartState => ({
    tasks: [],
    isLoading: false,
  }),
  actions: {
 // This function will organize tasks into groups based on the parent field.
 groupTasksByJobId(tasks) {
  // Create a map of parent job IDs to tasks
  const taskGroups = new Map();
  tasks.forEach(task => {
    if (!taskGroups.has(task.parent)) {
      taskGroups.set(task.parent, []);
    }
    taskGroups.get(task.parent).push(task);
  });

  // Convert the map into a structured array for Gantt
  const groupedTasks = [];
  taskGroups.forEach((groupTasks, parent) => {
    // Create a 'project' task that will act as a group header
    if (parent) {
      groupedTasks.push({
        id: `group_${parent}`,
        text: `Job ID: ${parent}`,
        start_date: this.getEarliestStartDate(groupTasks),
        type: 'project',
        open: true
      });
    }
    
    // Add the tasks to the group
    groupedTasks.push(...groupTasks);
  });

  return groupedTasks;
},

getEarliestStartDate(tasks) {
  // Get the earliest start date from a list of tasks
  return tasks.reduce((earliest, task) => {
    const taskDate = new Date(task.start_date);
    return taskDate < earliest ? taskDate : earliest;
  }, new Date()).toISOString();
},

async fetchGanttChartTasks() {
  this.isLoading = true;
  try {
    const ganttTasks = await fetchGanttChartData();
    this.tasks = this.groupTasksByJobId(ganttTasks);
  } catch (error) {
    console.error('Failed to fetch Gantt chart data:', error);
  } finally {
    this.isLoading = false;
  }
},

},
  getters: {
    // Add getters as needed to retrieve specific slices of Gantt chart data
  },
});


*/