<template>
  <div class="home" data-bs-theme="light">
    <div class="user-info">
      <h3>{{ greeting }}, {{ currentUser?.user.full_name }}</h3>
      <h3>All Jobs</h3>
    </div>
    <div v-if="isLoadingJobs" class="loading-container"></div>
    <div v-else class="jobtablecontainer">
      <div v-if="userEmployeeStore.currentUser?.user.is_superuser">
        <JobList :jobs="all_jobs"
          :visible-columns="['id', 'name', 'description', 'task_count', 'status', 'total_active_duration', 'total_cost', 'assigned', 'created_by', 'actions']">
        </JobList>
      </div>
      <div v-if="userEmployeeStore.currentUser?.user.is_staff && !userEmployeeStore.currentUser?.user.is_superuser">
        <JobList :jobs="all_jobs"
          :visible-columns="['id', 'name', 'description', 'task_count', 'status', 'total_active_duration', 'assigned', 'created_by', 'actions']">
        </JobList>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import JobList from '@/components/JobList.vue';
import { useEmployeeStore } from '@/stores/UserEmployee';
import { useJobStore } from '@/stores/Jobs';
import { storeToRefs } from 'pinia';
import { watch, computed, onMounted, watchEffect } from 'vue';

const userEmployeeStore = useEmployeeStore();
const jobStore = useJobStore();
const { all_jobs, isLoadingJobs } = storeToRefs(jobStore);
const currentUser = computed(() => userEmployeeStore.currentUser);

const greeting = computed(() => {
  const hour = new Date().getHours();
  if (hour < 12) return 'Good Morning';
  if (hour < 18) return 'Good Afternoon';
  return 'Good Evening';
});

onMounted(() => {
  // console.log("Current user at mounted:", userEmployeeStore.currentUser);
  watchEffect(() => {
    // console.log("Current user in watchEffect:", userEmployeeStore.currentUser);
    if (userEmployeeStore.currentUser?.user.is_superuser || userEmployeeStore.currentUser?.user.is_staff) {
      console.log("Admin confirmed, fetching jobs.");
      jobStore.getAllJobs();
    }
  });
});


watch(all_jobs, (newJobs) => {
  if (newJobs) {
    // You can perform additional actions here if needed,
    // such as logging or handling the data differently.
  }
}, { immediate: true });
</script>

<style scoped>
.home {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white !important;
  min-height: 100vh;
  padding-bottom: 30px;
}

.user-info {
  text-align: center;
}

.jobtablecontainer {
  width: 85%;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-container::after {
  content: '';
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>