<template>
    <div class="task-container">
        <div class="filtering-buttons">
            <button type="button" class="apple-button all-tasks" :class="{ 'active-filter': filterAllTasks }"
                @click="setFilterAll">
                Show All Tasks
            </button>
            <button type="button" class="apple-button completed-task" :class="{ 'active-filter': filterCompletedTasks }"
                @click="setFilterCompleted">
                Completed Tasks
            </button>
            <button type="button" class="apple-button active-task" :class="{ 'active-filter': filterActiveTasks }"
                @click="setFilterActive">
                Active Tasks
            </button>
            <button type="button" class="apple-button not-started-task"
                :class="{ 'active-filter': filterNotStartedTasks }" @click="setFilterNotStarted">
                Not Started
            </button>
            <div class="dropdown">
                <button type="button" class="apple-button sort-button" @click="toggleSortDropdown">
                    Sort By <span v-if="sortKey">{{ sortKey }} {{ sortArrow }}</span>
                </button>
                <div class="dropdown-content" v-show="sortDropdownVisible">
                    <button class="apple-button id" @click="updateSort('Id')">Id</button>
                    <button class="apple-button name" @click="updateSort('Name')">Name</button>
                    <button class="apple-button started" @click="updateSort('Started')">Started</button>
                    <button class="apple-button cost" @click="updateSort('Cost')">Cost</button>
                    <button class="apple-button duration" @click="updateSort('Duration')">Duration</button>
                </div>
            </div>
        </div>
        <div class="search-bar">
            <input class="search-input" type="text" v-model="searchQuery"
                placeholder="Search Name of Task, Description, Work Center or the Job Name...">
        </div>
        <div class="table-container">
            <table class="styled-table">
                <thead>
                    <tr>
                        <th scope="col" v-if="visibleColumns.includes('id')">Sr. No.</th>
                        <th scope="col" v-if="visibleColumns.includes('name')">ID | Task</th>
                        <th scope="col" v-if="visibleColumns.includes('description')">Description</th>
                        <th scope="col" v-if="visibleColumns.includes('job_name')">Job Name</th>
                        <th scope="col" v-if="visibleColumns.includes('work_center')">Work Center</th>
                        <th scope="col" v-if="visibleColumns.includes('started')">Start Date</th>
                        <th scope="col" v-if="visibleColumns.includes('concluded')">End Date</th>
                        <th scope="col" v-if="visibleColumns.includes('total_active_duration')">ActiveDuration</th>
                        <th scope="col" v-if="visibleColumns.includes('labor_cost')">Labor Cost</th>
                        <th scope="col" v-if="visibleColumns.includes('assigned')">Assigned To</th>
                        <th scope="col" v-if="visibleColumns.includes('actions')">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="paginatedTasks.length > 0">
                        <tr v-for="(task, index) in paginatedTasks" :key="task.id"
                            :class="getPriorityClass(task.priority)">
                            <td v-if="visibleColumns.includes('id')">{{ initialSerialNumbers[task.id] }}</td>
                            <td v-if="visibleColumns.includes('name')">{{ task.id }} | {{ task.name }}</td>
                            <td v-if="visibleColumns.includes('description')">
                                <div class="task-description" @mouseover="showDescription = task.id"
                                    @mouseout="showDescription = null">
                                    {{ truncateDescription(task.description) }}
                                    <div v-if="showDescription === task.id" class="popover">Task Description:<br>{{
                task.description }}</div>
                                </div>
                            </td>
                            <td v-if="visibleColumns.includes('job_name')">{{ task.job_name ? task.job_name : 'N/A' }}
                            </td>
                            <td v-if="visibleColumns.includes('work_center')">
                                {{ task.work_center ? task.work_center.name : 'Not Available' }}
                            </td>
                            <td v-if="visibleColumns.includes('started')">
                                <DateTimeBadge :time="task.started" />
                            </td>
                            <td v-if="visibleColumns.includes('concluded')">
                                <DateTimeBadge :time="task.concluded" />
                            </td>
                            <td v-if="visibleColumns.includes('total_active_duration')">{{
                truncateMilliseconds(task.total_active_duration) }}</td>
                            <td v-if="visibleColumns.includes('labor_cost')"><b>$</b> {{ task.labor_cost }}</td>
                            <td v-if="visibleColumns.includes('assigned')">
                                <span v-for=" assignment  in  task.assignments " v-bind:key="assignment.id">
                                    <UserBadge :user-id="assignment.user" />
                                </span>
                            </td>
                            <td v-if="visibleColumns.includes('actions')">
                                <router-link class="apple-button view-button"
                                    :to="{ name: 'task-view', params: { id: task.id } }">View</router-link>
                                <button
                                    v-if="employeeStore.currentUser.user?.is_superuser || employeeStore.currentUser.user?.is_staff"
                                    type="button" class="apple-button delete-button"
                                    @click="deleteTask(task.id, index)"><u>Delete</u></button>
                            </td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr>
                            <td colspan="8" class="no-tasks-message">
                                <h5>No Tasks in this category.</h5>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <nav class="pagination-controls">
            <ul class="pagination">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                    <button class="page-link" @click="goToPrevPage" :disabled="currentPage === 1">Previous</button>
                </li>
                <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
                    <button class="page-link" @click="goToPage(page)">{{ page }}</button>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                    <button class="page-link" @click="goToNextPage" :disabled="currentPage === totalPages">Next</button>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script setup lang="ts">
import UserBadge from '@/components/UserBadge.vue'
import DateTimeBadge from '@/components/DateTimeBadge.vue';
import { defineProps, ref, onMounted, computed, watch, PropType } from 'vue';
import { Task } from '@/types/Ops';
import { deleteFromUrl } from '@/api/api';
import { useEmployeeStore } from '@/stores/UserEmployee';

const props = defineProps({
    tasks: {
        type: Array as PropType<Task[]>,
        default: undefined
    },
    visibleColumns: {
        type: Array as PropType<string[]>,
        default: () => ['id', 'name', 'description', 'work_center', 'started', 'concluded', 'total_active_duration', 'labor_cost', 'assigned', 'actions']
    }
});

const tasks = ref<Task[]>([]);
const employeeStore = useEmployeeStore();

let tasksLoaded = false;
const showDescription = ref(null);
const sortKey = ref('id');
const sortOrder = ref(1);
const searchQuery = ref('');
const initialSerialNumbers = ref({});
const selectedUsername = ref('');
const filterAllTasks = ref(true);
const filterActiveTasks = ref(false);
const filterCompletedTasks = ref(false)
const filterNotStartedTasks = ref(false);
const sortDropdownVisible = ref(false);
const pageSize = ref(15);
const currentPage = ref(1);

const toggleSortDropdown = () => {
    sortDropdownVisible.value = !sortDropdownVisible.value;
};

function updateSort(key) {
    if (sortKey.value === key) {
        sortOrder.value = -sortOrder.value;
    } else {
        sortKey.value = key;
        sortOrder.value = 1;
    }
    sortDropdownVisible.value = false;
}

const truncateDescription = (description) => {
    const maxLength = 50;
    if (description.length > maxLength) {
        return description.substring(0, maxLength) + '...';
    }
    return description;
}

function truncateMilliseconds(durationStr) {
    return durationStr.split('.')[0];
}

const goToPage = (pageNumber) => {
    currentPage.value = pageNumber;
};

const goToPrevPage = () => {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
};

const goToNextPage = () => {
    if (currentPage.value < totalPages.value) {
        currentPage.value++;
    }
};

const paginatedTasks = computed(() => {
    const start = (currentPage.value - 1) * pageSize.value;
    const end = start + pageSize.value;
    return filteredTasks.value.slice(start, end);
});

const totalPages = computed(() => {
    return Math.ceil(filteredTasks.value.length / pageSize.value);
});

const serialNumbers = computed(() => {
    const map = {};
    sortedTasks.value.forEach((task, index) => {
        map[task.id] = index + 1;
    });
    return map;
});

const sortArrow = computed(() => {
    return sortOrder.value === 1 ? '↓' : '↑';
});

const getPriorityClass = (priority) => {
    switch (priority) {
        case 1: return 'low-priority';
        case 2: return 'medium-priority';
        case 3: return 'high-priority';
        default: return '';
    }
};

function durationStringToMilliseconds(durationStr) {
    let totalMilliseconds = 0;

    const parts = durationStr.split(' ');
    const timeParts = parts.pop().split(':');
    const dayPart = parts.pop() || '0';

    totalMilliseconds += parseInt(dayPart, 10) * 24 * 60 * 60 * 1000;
    totalMilliseconds += parseInt(timeParts[0], 10) * 60 * 60 * 1000;
    totalMilliseconds += parseInt(timeParts[1], 10) * 60 * 1000;
    totalMilliseconds += parseFloat(timeParts[2]) * 1000;

    return totalMilliseconds;
}

const userIdToNameMap = computed(() => {
    const map = {};
    employeeStore.employees.forEach(employee => {
        map[employee.user.id] = employee.user.username; // Use 'username' here
    });
    return map;
});


const sortedTasks = computed(() => {
    return tasks.value.slice().sort((a, b) => {
        if (sortKey.value === 'Name') {
            return sortOrder.value * a.name.localeCompare(b.name);
        } else if (sortKey.value === 'Started') {
            if (!a.started && !b.started) {
                return sortOrder.value * (a.id - b.id);
            }
            if (!a.started) return 1;
            if (!b.started) return -1;
            return sortOrder.value * (new Date(a.started).getTime() - new Date(b.started).getTime());
        } else if (sortKey.value === 'Cost') {
            const costA = a.labor_cost ?? 0;
            const costB = b.labor_cost ?? 0;
            return sortOrder.value * (costA - costB);
        } else if (sortKey.value === 'Duration') {
            const durationA = a.total_active_duration ? durationStringToMilliseconds(a.total_active_duration) : 0;
            const durationB = b.total_active_duration ? durationStringToMilliseconds(b.total_active_duration) : 0;
            return sortOrder.value * (durationA - durationB);
        }
        return sortOrder.value * (a.id - b.id);
    });
});

const filteredTasks = computed(() => {
    let filtered = sortedTasks.value;
    if (searchQuery.value) {
        const searchLower = searchQuery.value.toLowerCase();
        filtered = filtered.filter(task => {
            const nameMatch = task.name.toLowerCase().includes(searchLower);
            const descriptionMatch = task.description.toLowerCase().includes(searchLower);
            const workCenterMatch = task.work_center?.name?.toLowerCase().includes(searchLower) || false;
            const jobNameMatch = task.job_name?.toLowerCase().includes(searchLower) || false;
            return nameMatch || descriptionMatch || workCenterMatch || jobNameMatch;
        });
    }
    if (filterActiveTasks.value) {
        filtered = filtered.filter(task => task.started && (task.concluded === null && !task.concluded));
    } else if (filterCompletedTasks.value) {
        filtered = filtered.filter(task => task.concluded !== null && task.concluded !== undefined);
    } else if (filterNotStartedTasks.value) {
        filtered = filtered.filter(task => !task.started && !task.concluded);
    }
    if (selectedUsername.value) {
        filtered = filtered.filter(task =>
            task.assignments.some(assignment =>
                userIdToNameMap.value[assignment.user] === selectedUsername.value
            )
        );
    }
    return filtered;
});

watch(selectedUsername, (newUsername, oldUsername) => {
    console.log(`Username changed from ${oldUsername} to ${newUsername}`);
    // Additional logic can be added here if needed
});


function formatDateTime(dateTimeStr) {
    if (!dateTimeStr) {
        return 'NA';
    }
    const dateObj = new Date(dateTimeStr);
    const date = dateObj.toISOString().split('T')[0];
    const time = dateObj.toTimeString().split(' ')[0];
    return `${date}<br>(${time})`;
}

const setFilterAll = () => {
    filterAllTasks.value = true;
    filterActiveTasks.value = false;
    filterCompletedTasks.value = false;
    filterNotStartedTasks.value = false;
};

const setFilterActive = () => {
    filterAllTasks.value = false;
    filterActiveTasks.value = true;
    filterCompletedTasks.value = false;
    filterNotStartedTasks.value = false;
};

const setFilterCompleted = () => {
    filterAllTasks.value = false;
    filterActiveTasks.value = false;
    filterCompletedTasks.value = true;
    filterNotStartedTasks.value = false;
};

const setFilterNotStarted = () => {
    filterAllTasks.value = false;
    filterActiveTasks.value = false;
    filterCompletedTasks.value = false;
    filterNotStartedTasks.value = true;
};

const deleteTask = async (taskId, index) => {
    if (confirm('Are you sure you want to delete this task?')) {
        try {
            await deleteFromUrl(`/ops/api/tasks/${taskId}/`);
            tasks.value.splice(index, 1);
        } catch (error) {
            console.error('Failed to delete task from backend:', error);
        }
    }
};

onMounted(() => {
    if (props.tasks) {
        tasks.value = props.tasks.filter(task => !task.name.includes("template"));
        tasks.value = [...props.tasks].sort((a, b) => a.id - b.id);
        tasks.value.forEach((task, index) => {
            initialSerialNumbers.value[task.id] = index + 1;
        });
        tasksLoaded = true;
    } else {
        // console.error("Tasks are not provided or undefined!");
    }
    employeeStore.fetch();
});

watch(() => props.tasks, (newTasks) => {
    if (newTasks) {
        tasks.value = newTasks.filter(task => !task.name.includes("template"));
        tasks.value = tasks.value.sort((a, b) => a.id - b.id);
        initialSerialNumbers.value = {};
        tasks.value.forEach((task, index) => {
            initialSerialNumbers.value[task.id] = index + 1;
        });
    }
}, { immediate: true });

</script>

<style scoped>
.task-container {
    margin-top: 20px;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0.1, 0.1, 0.1, 0.2);
    overflow-y: auto;
    overflow-x: hidden;
}

.filtering-buttons {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.filtering-buttons .dropdown:last-child {
    margin-left: auto;
    position: relative;
}

.search-bar {
    color: black;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.search-input {
    width: 100%;
    padding: 10px;
    background-color: #f3f3f3;
    border: 1px solid #ddd;
    color: black;
    font-size: 16px;
    border-radius: 10px;
}

.table-container {
    overflow-x: auto;
}

.styled-table {
    width: 100%;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
    font-size: 0.9em;
    border: 1px solid #ddd;
    border-collapse: collapse;
    border-spacing: 0;
    border-radius: 10px;
}

.styled-table thead tr {
    background-color: rgb(75, 82, 219);
    color: #ffffff;
    text-align: left;
    border-radius: 10px;
    height: 70px;
}

.styled-table thead tr:first-child th:first-child {
    border-top-left-radius: 10px;
}

.styled-table thead tr:first-child th:last-child {
    border-top-right-radius: 10px;
}

.styled-table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}

.styled-table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table tbody tr {
    border-bottom: 2px solid #dddddd;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #f3f3f3;
}

.styled-table tbody tr:hover {
    background-color: #e8e8e8;
}

.task-description {
    position: relative;
    cursor: pointer;
}

.task-description .popover {
    color: black;
    display: none;
    position: absolute;
    top: -5px;
    left: 100%;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 10px;
    z-index: 100;
    border-radius: 5px;
    width: 300px;
    word-wrap: break-word;
}

.task-description:hover .popover {
    display: block;
}

.apple-button {
    display: inline-block;
    padding: 10px 20px;
    margin: 10px 10px 10px 10px;
    border: none;
    border-radius: 25px;
    background-color: #f7f4f4;
    color: black;
    text-align: center;
    text-decoration: none;
    font-weight: 600;
    transition: all 0.3s ease;
    transform: scale(1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.apple-button.all-tasks:hover,
.apple-button.sort-button:hover,
.apple-button.view-button:hover,
.apple-button.id:hover,
.apple-button.name:hover,
.apple-button.cost:hover,
.apple-button.duration:hover,
.apple-button.started:hover {
    color: #FFFFFF;
    background-color: rgb(75, 82, 219);
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.apple-button.active-task:hover {
    color: #FFFFFF;
    background-color: rgb(219, 80, 75);
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.apple-button.completed-task:hover {
    color: #FFFFFF;
    background-color: rgb(102, 211, 102);
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.apple-button.not-started-task:hover {
    color: #000000;
    background-color: rgb(255, 255, 153);
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.apple-button.delete-button:hover {
    color: #FFFFFF;
    background-color: rgb(219, 80, 75);
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.apple-button.active-task:active {
    transform: scale(0.95);
}

.apple-button.completed-task:active {
    transform: scale(0.95);
}

.all-tasks.active-filter {
    color: #FFFFFF;
    background-color: rgb(75, 82, 219);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.active-task.active-filter {
    color: #FFFFFF;
    background-color: rgb(219, 80, 75);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.completed-task.active-filter {
    background-color: rgb(102, 211, 102);
}

.not-started-task.active-filter {
    background-color: rgb(255, 255, 153);
}

.pagination-controls {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    align-items: center;
}

.pagination {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0px;
}

.pagination .page-item {
    margin: 0 5px;
}

.pagination .page-link {
    border: 1px solid #007bff;
    padding: 5px 10px;
    color: #007bff;
    cursor: pointer;
}

.pagination .page-link:hover {
    background-color: #007bff;
    color: white;
}

.pagination .disabled .page-link {
    color: #ccc;
    cursor: not-allowed;
    border: 1px solid #ccc;
}

.pagination .active .page-link {
    background-color: #007bff;
    color: white;
}

.no-tasks-message {
    text-align: center;
    align-items: center;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: rgb(231, 231, 231);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1;
    padding: 10px;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.low-priority {
    background-color: #e7f3fe;
    border-left: 4px solid #2d99ff;
    border-right: 4px solid #007bff;
}

.medium-priority {
    background-color: #fffbea;
    border-left: 4px solid #ffdf50;
    border-right: 4px solid #ffdf50;
}

.high-priority {
    background-color: #ffe3e3;
    border-left: 4px solid #ff4848;
    border-right: 4px solid #ff4848;
}

.low-priority td:first-child {
    border-left: 4px solid #2d99ff;
}

.low-priority td:last-child {
    border-right: 4px solid #007bff;
}

.medium-priority td:first-child {
    border-left: 4px solid #ffdf50;
}

.medium-priority td:last-child {
    border-right: 4px solid #ffdf50;
}

.high-priority td:first-child {
    border-left: 4px solid #ff4848;
}

.high-priority td:last-child {
    border-right: 4px solid #ff4848;
}
</style>
