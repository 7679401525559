<template>
  <div class="whole-dashboard">
    <div class="admin-dashboard">
      <h1>Admin Dashboard</h1>
      <div>
        <PieChartComponent />
        <div class="flex-container">
          <div class="table-wrapper">
            <h2>Jobs</h2>
            <div v-if="isLoadingJobs">Loading Jobs...</div>
            <JobList v-else :jobs="all_jobs" :visible-columns="['id', 'name', 'total_active_duration', 'total_cost']" />
          </div>
          <div class="table-wrapper">
            <h2>Tasks</h2>
            <div v-if="isLoadingTaks">Loading Tasks...</div>
            <TaskList v-else :tasks="all_tasks"
              :visible-columns="['id', 'name', 'job_name', 'total_active_duration', 'labor_cost', 'assigned']" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TaskList from '@/components/TaskList.vue';
import JobList from '@/components/JobList.vue';
import PieChartComponent from '@/components/PieChartComponent.vue';
import { useEmployeeStore } from '@/stores/UserEmployee';
import { useJobStore } from '@/stores/Jobs';
import { useTaskStore } from '@/stores/Tasks'
import { storeToRefs } from 'pinia';
import { watch, computed, onMounted } from 'vue';

const employeeStore = useEmployeeStore();
const jobStore = useJobStore();
const taskStore = useTaskStore();
const { all_jobs, isLoadingJobs } = storeToRefs(jobStore);
const { all_tasks, isLoadingTaks } = storeToRefs(taskStore);


const getUserById = (userId: number) => {
  const user = employeeStore.getById(userId);
  return user ? user : { id: userId, username: `User ${userId}` };
};

function truncateMilliseconds(durationStr) {
  return durationStr.split('.')[0];
}

onMounted(() => {
  jobStore.getAllJobs();
  taskStore.getAllTasks()
});

watch(all_tasks, (newTasks) => {
  if (newTasks) {
    // You can perform additional actions here if needed,
    // such as logging or handling the data differently.
  }
}, { immediate: true });

watch(all_jobs, (newTasks) => {
  if (newTasks) {
    // You can perform additional actions here if needed,
    // such as logging or handling the data differently.
  }
}, { immediate: true });

</script>

<style scoped>
.whole-dashboard {
  flex-grow: 1;
}

.admin-dashboard {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0.1, 0.1, 0.1, 0.2);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white !important;
  min-height: 100vh;
}

.flex-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  justify-content: center;
}

.table-wrapper {
  margin: 20px;
  margin-top: 30px;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0.1, 0, 0, 0.1);
}
</style>
