<template>
  <div class="userlist-container" v-if="usersLoaded">
    <h1>User List</h1>
    <div class="filtering-buttons">
      <button type="button" class="apple-button show-all" @click="setFilterAllUser">Show All</button>
      <button type="button" class="apple-button user" @click="setFilterAdmins">Admins</button>
      <button type="button" class="apple-button user" @click="setFilterManagers">Managers</button>
      <button type="button" class="apple-button useremployee" @click="setFilterEmployees">Employees</button>
    </div>
    <div class="search-bar">
      <input type="text" v-model="searchQuery" placeholder="Search user..." class="search-input">
    </div>
    <div class="table-container">
      <table class="styled-table">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Name</th>
            <th>Email</th>
            <th>Date of Joining</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="paginatedUsers.length > 0">
            <tr v-for="(userEmployee, index) in paginatedUsers" :key="userEmployee.id">
              <td>{{ calculateSerialNumber(index) }}</td>
              <td>{{ userEmployee.user.full_name }} </td>
              <td>{{ userEmployee.user.email }}</td>
              <td>
                <DateTimeBadge :time="userEmployee.user?.date_joined" />
              </td>
              <td>
                <button class="apple-button view-button" @click="viewUser(userEmployee.id)">View</button>
                <button v-if="employeeStore.currentUser.user?.is_superuser" class="apple-button delete-button"
                  @click="deleteUser(userEmployee, index)">Delete</button>
                <button v-if="employeeStore.currentUser.user?.is_superuser" class="apple-button report-button"
                  @click="generateReportForUser(userEmployee.id)">Generate
                  Report</button>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="5" class="no-users-message">No Users in this category.</td>
            </tr>
          </template>
        </tbody>
      </table>
      <nav class="pagination-controls">
        <ul class="pagination">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <button class="page-link" @click="goToPrevPage" :disabled="currentPage === 1">Previous</button>
          </li>
          <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
            <button class="page-link" @click="goToPage(page)">{{ page }}</button>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <button class="page-link" @click="goToNextPage" :disabled="currentPage === totalPages">Next</button>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <div v-else class="loading-container">
  </div>
</template>


<script setup lang="ts">
import DateTimeBadge from '@/components/DateTimeBadge.vue';
import { ref, computed, onMounted } from 'vue';
import { useUserStore } from '@/stores/UserStore';
import { useEmployeeStore } from '@/stores/UserEmployee';
import { deleteFromUrl } from '@/api/api';
import { useRouter } from 'vue-router';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const userStore = useUserStore();
const employeeStore = useEmployeeStore();
const router = useRouter();

const usersLoaded = ref(false);
const searchQuery = ref('');
const filterAllUsers = ref(true);
const filterAdmin = ref(false);
const filterManager = ref(false)
const filterEmployee = ref(false);
const pageSize = ref(10);
const currentPage = ref(1);
const userDetails = ref(null)


const calculateSerialNumber = (index) => {
  return (currentPage.value - 1) * pageSize.value + index + 1;
};

const setFilterAllUser = () => {
  filterAllUsers.value = true;
  filterAdmin.value = false;
  filterManager.value = false;
  filterEmployee.value = false;
}

const setFilterAdmins = () => {
  filterAllUsers.value = false;
  filterAdmin.value = true;
  filterManager.value = false;
  filterEmployee.value = false;
}

const setFilterManagers = () => {
  filterAllUsers.value = false;
  filterAdmin.value = false;
  filterManager.value = true;
  filterEmployee.value = false;
}

const setFilterEmployees = () => {
  filterAllUsers.value = false;
  filterAdmin.value = false;
  filterManager.value = false;
  filterEmployee.value = true;
}

const goToPage = (pageNumber) => {
  currentPage.value = pageNumber;
};

const goToPrevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const goToNextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const paginatedUsers = computed(() => {
  if (filteredUsers.value.length === 0) return [];
  const start = (currentPage.value - 1) * pageSize.value;
  const end = start + pageSize.value;
  return filteredUsers.value.slice(start, end);
});

const totalPages = computed(() => {
  return Math.ceil(filteredUsers.value.length / pageSize.value);
});

const viewUser = (userEmployeeId) => {
  router.push({ name: 'employeeDetail', params: { userEmployeeId } });
};

const filteredUsers = computed(() => {
  let userEmployees = employeeStore.employees;
  if (searchQuery.value) {
    userEmployees = userEmployees.filter(ue =>
      ue.user.full_name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      ue.user.email.toLowerCase().includes(searchQuery.value.toLowerCase())
    );
  }
  // Filter by role using User data within each UserEmployee
  if (filterAdmin.value) {
    userEmployees = userEmployees.filter(ue => ue.user.is_superuser);
  } else if (filterManager.value) {
    userEmployees = userEmployees.filter(ue => ue.user.is_staff && !ue.user.is_superuser);
  } else if (filterEmployee.value) {
    userEmployees = userEmployees.filter(ue => (!ue.user.is_staff && !ue.user.is_superuser));
  }

  return userEmployees;
});


const deleteUser = async (userEmployee, index) => {
  if (confirm('Are you sure you want to delete this user?')) {
    try {
      const userEmployeeId = userEmployee.id;
      const userId = userEmployee.user.id;
      await deleteFromUrl(`/ops/api/users/${userEmployeeId}/`);
      await deleteFromUrl(`/ops/api/siteusers/${userId}/`);

      employeeStore.employees.splice(index, 1);
      employeeStore.employees.splice(index, 1);

      alert('User and UserEmployee successfully deleted.');
    } catch (error) {
      console.error('Error deleting user or user employee:', error);
      alert('Cannot delete user or user employee. There may be assigned tasks or other dependencies.');
    }
  }
};

const generateReportForUser = async (userEmployeeId) => {
  try {
    userDetails.value = await employeeStore.fetchEmployeeDetails(userEmployeeId);
    if (userDetails.value) {
      generatePDF(userDetails);
    } else {
      console.error('Error fetching user details');
    }
  } catch (error) {
    console.error('Error generating report:', error);
  }
};

function truncateMilliseconds(durationStr) {
  return durationStr.split('.')[0];
}

const dateOfGenerating = new Date().toISOString().split('T')[0];

const addFooter = async (doc) => {
  const footerStartY = 280; // Adjust as needed for positioning
  // Footer text content setup
  doc.setFontSize(10);
  doc.setTextColor(100);
  const imageUrl = '/static/img/mudbots-master-logo-lg.png';
  doc.addImage(imageUrl, 'PNG', 20, doc.internal.pageSize.height - 27, 40, 20);
  doc.setFont('helvetica', 'bold');
  doc.text('OFFICE HOURS', 70, footerStartY);
  doc.setFont('helvetica', 'normal');
  doc.text('09:00 - 05:00 MST\nMountain Standard Time', 70, footerStartY + 5);
  doc.setFont('helvetica', 'bold');
  doc.text('CALL US', 120, footerStartY);
  doc.setFont('helvetica', 'normal');
  doc.text('800-733-2302\nMonday - Friday', 120, footerStartY + 5);
  doc.setFont('helvetica', 'bold');
  doc.text('LOCATION', 170, footerStartY);
  doc.setFont('helvetica', 'normal');
  doc.text('40 Iron St\nUT 84074', 170, footerStartY + 5);
};

const generatePDF = (userDetails) => {
  // console.log('Generating PDF for:', employeeDetails.value);
  if (!userDetails.value) {
    alert("Please select a user and wait for data to load");
    return;
  }
  let serialNumber = 1;
  const tableData = userDetails.value.tasks.map(task => {
    return {
      serialNumber: serialNumber++,
      task: task.name,
      jobName: task.job_name,
      startDate: task.started ? new Date(task.started).toLocaleDateString() : "N/A",
      endDate: task.concluded ? new Date(task.concluded).toLocaleDateString() : "N/A",
      workCenter: task.work_center?.name || "N/A",
      costCenter: task.cost_center?.name || "N/A",
      totalHours: truncateMilliseconds(task.total_active_duration) || "0",
      laborCost: task.labor_cost || "0",
    };
  });

  const doc = new jsPDF();
  doc.setFontSize(20);
  doc.text('User Report', 20, 20);
  const pageWidth = doc.internal.pageSize.getWidth();
  const imageWidth = 30;
  const topMargin = 18;

  const addTopRightCornerImage = async () => {
    const imageUrl = '/static/img/userprofile.png';
    const rightXCoordinate = pageWidth - imageWidth - 15;
    await doc.addImage(imageUrl, 'PNG', rightXCoordinate, topMargin, imageWidth, 30);
  };

  addTopRightCornerImage();

  const userDataStartY = 30;
  // Set font to bold for labels and normal for values
  doc.setFontSize(12);
  // Name label and value
  doc.setFont('helvetica', 'bold');
  doc.text('Name:', 20, userDataStartY);
  doc.setFont('helvetica', 'normal');
  doc.text(`${userDetails.value.user.full_name}`, 38, userDataStartY);
  // Email label and value
  doc.setFont('helvetica', 'bold');
  doc.text('Email:', 20, userDataStartY + 6);
  doc.setFont('helvetica', 'normal');
  doc.text(`${userDetails.value.user.email}`, 38, userDataStartY + 6);
  // Hourly Pay label and value
  doc.setFont('helvetica', 'bold');
  doc.text('Hourly Pay $ :', 20, userDataStartY + 12);
  doc.setFont('helvetica', 'normal');
  doc.text(`${userDetails.value.hourly_pay}`, 50, userDataStartY + 12);
  // Joined Date label and value
  doc.setFont('helvetica', 'bold');
  doc.text('Joined Date:', 20, userDataStartY + 18);
  doc.setFont('helvetica', 'normal');
  const dateOfJoining = new Date(userDetails.value.user.date_joined);
  const formattedDateOfJoining = `${dateOfJoining.getFullYear()}-${(dateOfJoining.getMonth() + 1).toString().padStart(2, '0')}-${dateOfJoining.getDate().toString().padStart(2, '0')}`;
  doc.text(formattedDateOfJoining, 50, userDataStartY + 18);

  const tableColumn = [
    { header: "S.No", dataKey: "serialNumber" },
    { header: "Task", dataKey: "task" },
    { header: "Job Name", dataKey: "jobName" },
    { header: "Start Date (MM/DD/YY)", dataKey: "startDate" },
    { header: "End Date (MM/DD/YY)", dataKey: "endDate" },
    { header: "Work Center", dataKey: "workCenter" },
    { header: "Cost Center", dataKey: "costCenter" },
    { header: "Total Hours", dataKey: "totalHours" },
    { header: "Labor Cost", dataKey: "laborCost" },
  ];

  const mapTasksToTableData = (tasks) => tasks.map((task, index) => {
    return {
      serialNumber: index + 1,
      task: task.name,
      jobName: task.job_name,
      startDate: task.started ? new Date(task.started).toLocaleDateString() : "N/A",
      endDate: task.concluded ? new Date(task.concluded).toLocaleDateString() : "N/A",
      workCenter: task.work_center?.name || "N/A",
      costCenter: task.cost_center?.name || "N/A",
      totalHours: truncateMilliseconds(task.total_active_duration) || "0",
      laborCost: task.labor_cost || "0",
    };
  });

  const completedTasks = userDetails.value.tasks.filter(task => task.started && task.concluded);
  const activeTasks = userDetails.value.tasks.filter(task => task.started && !task.concluded);
  const notStartedTasks = userDetails.value.tasks.filter(task => !task.started);

  const categories = [
    { title: 'Completed Tasks', tasks: completedTasks },
    { title: 'Active Tasks', tasks: activeTasks },
    { title: 'Not Started Tasks', tasks: notStartedTasks },
  ];

  let startY = userDataStartY + 37;
  categories.forEach((category, index) => {
    if (category.tasks.length > 0) {
      doc.setFontSize(16);
      doc.text(category.title, 20, startY);
      startY += 6

      const tableData = mapTasksToTableData(category.tasks, index * 1000);
      doc.autoTable({
        columns: tableColumn,
        body: tableData,
        startY: startY,
        theme: 'striped',
        headStyles: { fillColor: [75, 82, 219] },
        styles: { fontSize: 10, cellPadding: 3, overflow: 'linebreak' },
        margin: { bottom: 30 },
      });
      startY = doc.lastAutoTable.finalY + 20
    }
  });
  doc.setPage(doc.internal.getNumberOfPages());
  addFooter(doc);
  const fileName = `Report ${userDetails.value.user.full_name} ${dateOfGenerating}.pdf`;
  doc.save(fileName);
};

onMounted(async () => {
  try {
    await userStore.fetchAllUsers();
    await employeeStore.fetch();
    usersLoaded.value = true
  } catch (error) {
    console.error('Error loading users:', error);
  }
});

</script>

<style scoped>
.userlist-container {
  margin: 0 7.5%;
  padding: 20px;
  margin-bottom: 30px;
  width: 85%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0.1, 0.1, 0.1, 0.2);
  overflow-y: auto;
  overflow-x: hidden;

}

.filtering-buttons {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 20px;
}

.search-bar {
  color: black;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: #f3f3f3;
  border: 1px solid #ddd;
  color: black;
  font-size: 16px;
  border-radius: 10px;
}

.table-container {
  overflow-x: auto;
}

.styled-table {
  width: 100%;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  font-size: 0.9em;
  border: 1px solid #ddd;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px;
}

.styled-table thead tr {
  background-color: rgb(75, 82, 219);
  color: #ffffff;
  text-align: left;
  border-radius: 10px;
  height: 70px;
}

.styled-table thead tr:first-child th:first-child {
  border-top-left-radius: 10px;
}

.styled-table thead tr:first-child th:last-child {
  border-top-right-radius: 10px;
}

.styled-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.styled-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.styled-table tbody tr td:first-child {
  border-left: 1px solid #ddd;
}

.styled-table tbody tr td:last-child {
  border-right: 1px solid #ddd;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #f3f3f3;
}

.styled-table tbody tr:hover {
  background-color: #e8e8e8;
}


.apple-button {
  display: inline-block;
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 25px;
  background-color: #f7f4f4;
  color: black;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
  transform: scale(1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.apple-button.show-all:hover,
.apple-button.user:hover,
.apple-button.useremployee:hover,
.apple-button.view-button:hover {
  color: #FFFFFF;
  background-color: rgb(75, 82, 219);
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.apple-button.delete-button:hover {
  color: #FFFFFF;
  background-color: rgb(219, 80, 75);
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.apple-button.report-button:hover {
  color: #000000;
  background-color: #ffff99;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.apple-button.active-filter {
  transform: scale(0.95);
  color: #FFFFFF;
  background-color: rgb(75, 82, 219);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.pagination-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
}

.pagination {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0px;
}

.pagination .page-item {
  margin: 0 5px;
}

.pagination .page-link {
  border: 1px solid #007bff;
  padding: 5px 10px;
  color: #007bff;
  cursor: pointer;
}

.pagination .page-link:hover {
  background-color: #007bff;
  color: white;
}

.pagination .disabled .page-link {
  color: #ccc;
  cursor: not-allowed;
  border: 1px solid #ccc;
}

.pagination .active .page-link {
  background-color: #007bff;
  color: white;
}

.no-users-message {
  text-align: center;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-container::after {
  content: '';
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
