<template>
    <div class="whole-charts">
        <div class="charts-container">
            <div class="chart-wrapper">
                <h3>Job Chart</h3>
                <canvas ref="jobsCanvasRef"></canvas>
                <div class="chart-info">
                    <p>Total Jobs: {{ chartStore.jobsChartData.notStarted + chartStore.jobsChartData.inProgress +
                        chartStore.jobsChartData.completed }}
                        <br>
                        Completed: {{ chartStore.jobsChartData.completed }}
                        <br>
                        In Progress: {{ chartStore.jobsChartData.inProgress }}
                        <br>
                        Not Started: {{ chartStore.jobsChartData.notStarted }}
                    </p>
                </div>
            </div>
            <div class="chart-wrapper">
                <h3>Task Chart</h3>
                <canvas ref="tasksCanvasRef"></canvas>
                <div class="chart-info">
                    <p>Total Tasks: {{ chartStore.tasksChartData.notStarted + chartStore.tasksChartData.inProgress +
                        chartStore.tasksChartData.completed }}
                        <br>
                        Completed: {{ chartStore.tasksChartData.completed }}
                        <br>
                        In Progress: {{ chartStore.tasksChartData.inProgress }}
                        <br>
                        Not Started: {{ chartStore.tasksChartData.notStarted }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import { useChartStore } from '@/stores/PieChart';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const chartStore = useChartStore();
let jobsChart: Chart | null = null;
let tasksChart: Chart | null = null;

const jobsCanvasRef = ref<HTMLCanvasElement | null>(null);
const tasksCanvasRef = ref<HTMLCanvasElement | null>(null);

const createChart = (context: CanvasRenderingContext2D, chartData: number[]) => {
    return new Chart(context, {
        type: 'doughnut',
        data: {
            labels: ['Not Started', 'In Progress', 'Completed'],
            datasets: [{
                data: chartData,
                backgroundColor: ['#ff6f61', '#ffff99', '#61a2ff'],
            }],
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
        },
    }) as Chart<"doughnut", number[], string>;
};

onMounted(() => {
    if (jobsCanvasRef.value && tasksCanvasRef.value) {
        const jobsContext = jobsCanvasRef.value.getContext('2d');
        const tasksContext = tasksCanvasRef.value.getContext('2d');

        if (jobsContext && tasksContext) {
            jobsChart = createChart(jobsContext, [0, 0, 0]) as Chart<"doughnut", number[], string>;
            tasksChart = createChart(tasksContext, [0, 0, 0]) as Chart<"doughnut", number[], string>;
        }
    }
    chartStore.fetchAndProcessJobsData();
    chartStore.fetchAndProcessTasksData();
});

watch(() => chartStore.jobsChartData, (newData) => {
    if (jobsChart) {
        jobsChart.data.datasets[0].data = [
            newData.notStarted,
            newData.inProgress,
            newData.completed
        ];
        jobsChart.update();
    }
}, { deep: true });

watch(() => chartStore.tasksChartData, (newData) => {
    if (tasksChart) {
        tasksChart.data.datasets[0].data = [
            newData.notStarted,
            newData.inProgress,
            newData.completed
        ];
        tasksChart.update();
    }
}, { deep: true });

</script>

<style scoped>
.whole-charts {
    flex-grow: 1;
}

.charts-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    justify-content: center;
}

.chart-wrapper {
    margin: 20px;
    margin-top: 30px;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0.1, 0.1, 0.1, 0.1);
}


.chart-wrapper canvas {
    display: block;
    width: 100% !important;
    height: auto !important;
    max-height: 500px;
}
</style>
