<template>
  <div class="job-and-task">
    <h1>Job and Task Template</h1>
    <div class="flex-container">
      <!-- Job Templates Section -->
      <div class="table-wrapper">
        <div class="title-with-action">
          <h2>Job Templates</h2>
          <button class="action-button add" @click="addJob">➕ Add Job Template</button>
        </div>
        <table class="styled-table" v-if="!isLoadingJobs">
          <thead>
            <tr>
              <th>Sr. No</th>
              <th>Name</th>
              <th>Description</th>
              <th>Part ref.</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(job, index) in jobTemplates" :key="job.id">
              <td>{{ index + 1 }}</td>
              <td>{{ job?.name }}</td>
              <td>
                <div class="description" @mouseover="showDescription = job.id" @mouseout="showDescription = null">
                  {{ truncateDescription(job?.description) }}
                  <div v-if="showDescription === job.id" class="popover">Job Description:<br>{{ job?.description }}
                  </div>
                </div>
              </td>
              <td>{{ job?.part_reference }}</td>
              <td>
                <button class="action-button delete" @click="deleteJob(job.id)">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="loading-container"></div>
      </div>
      <!-- Task Templates Section -->
      <div class="table-wrapper">
        <div class="title-with-action">
          <h2>Task Templates</h2>
          <button class="action-button add" @click="addTask">➕ Add Task Template</button>
        </div>
        <table class="styled-table" v-if="!isLoadingTasks">
          <thead>
            <tr>
              <th>Sr. No</th>
              <th>Name</th>
              <th>Description</th>
              <th>Work Center</th>
              <th>Cost Center</th>
              <th>JT Name</th>
              <th>JT Order</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(task, index) in taskTemplates" :key="task.id">
              <td>{{ index + 1 }}</td>
              <td>{{ task?.name }}</td>
              <td>
                <div class="description" @mouseover="showDescription = task.id" @mouseout="showDescription = null">
                  {{ truncateDescription(task?.description) }}
                  <div v-if="showDescription === task.id" class="popover">Template Description:<br>{{
            task?.description }}</div>
                </div>
              </td>
              <td>{{ task.work_center ? task.work_center.name : 'N/A' }}</td>
              <td>{{ task.cost_center ? task.cost_center.name : 'N/A' }}</td>
              <td>{{ task?.job_template_name }}</td>
              <td>{{ task?.job_template_order }}</td>
              <td>
                <button class="action-button delete" @click="deleteTask(task.id)">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="loading-container"></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { fetchAllTaskTemplates, fetchAllJobTemplates, deleteFromUrl } from '@/api/api';

const taskTemplates = ref([]);
const jobTemplates = ref([]);
const isLoadingTasks = ref(true);
const isLoadingJobs = ref(true);
const showDescription = ref(null);
const router = useRouter();

onMounted(async () => {
  await fetchTemplates();
});

const fetchTemplates = async () => {
  taskTemplates.value = await fetchAllTaskTemplates();
  jobTemplates.value = await fetchAllJobTemplates();
  isLoadingTasks.value = false;
  isLoadingJobs.value = false;
};

const truncateDescription = (description) => {
  const maxLength = 50;
  if (description.length > maxLength) {
    return description.substring(0, maxLength) + '...';
  }
  return description;
}

const addTask = () => {
  router.push("/ops/create-task-template");
};

const deleteTask = async (taskId) => {
  if (confirm("Are you sure you want to delete this task?")) {
    try {
      await deleteFromUrl(`/ops/api/tasktemplates/${taskId}/`);
      alert("Task deleted successfully.");
      await fetchTemplates();
    } catch (error) {
      alert("Failed to delete task.");
      console.error("Error deleting task:", error);
    }
  }
};

const addJob = () => {
  router.push("/ops/create-job-template");
};

const deleteJob = async (jobId) => {
  if (confirm("Are you sure you want to delete this job?")) {
    try {
      await deleteFromUrl(`/ops/api/jobtemplates/${jobId}/`);
      alert("Job deleted successfully.");
      await fetchTemplates();
    } catch (error) {
      alert("Failed to delete job.");
      console.error("Error deleting job:", error);
    }
  }
};
</script>

<style scoped>
.job-and-task {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0.1, 0.1, 0.1, 0.2);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.flex-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  justify-content: center;
  width: 80%;
}

.table-wrapper {
  margin: 20px;
  margin-top: 30px;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0.1, 0, 0, 0.1);
}

.styled-table {
  width: 100%;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  font-size: 0.9em;
  border: 1px solid #ddd;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px;
}

.styled-table thead tr {
  background-color: rgb(75, 82, 219);
  color: #ffffff;
  text-align: left;
  border-radius: 10px;
  height: 70px;
}

.styled-table thead tr:first-child th:first-child {
  border-top-left-radius: 10px;
}

.styled-table thead tr:first-child th:last-child {
  border-top-right-radius: 10px;
}

.styled-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.styled-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.styled-table tbody tr td:first-child {
  border-left: 1px solid #ddd;
}

.styled-table tbody tr td:last-child {
  border-right: 1px solid #ddd;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #f3f3f3;
}

.styled-table tbody tr:hover {
  background-color: #e8e8e8;
}

.description {
  position: relative;
  cursor: pointer;
}

.description .popover {
  color: black;
  display: none;
  position: absolute;
  top: -5px;
  left: 100%;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 10px;
  z-index: 100;
  border-radius: 5px;
  width: 300px;
  word-wrap: break-word;
}

.description:hover .popover {
  display: block;
}


.action-button {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px 10px 10px 10px;
  border: none;
  border-radius: 25px;
  background-color: #f7f4f4;
  color: black;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
  transform: scale(1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.action-button.add:hover {
  color: #FFFFFF;
  background-color: rgb(102, 211, 102);
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.action-button.delete:hover {
  color: #FFFFFF;
  background-color: rgb(219, 80, 75);
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.title-with-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-container::after {
  content: '';
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>