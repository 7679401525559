<template>
  <div class="home">
    <div v-if="!jobStore.isLoadingJobs" class="jobWrapper d-flex flex-row">
      <div class="jobId">
        <h1 class="col">{{ job?.id + " |" }}</h1>
      </div>
      <div class="whole-job">
        <div class="jobContent">
          <div
            v-if="employeeStore.currentUser.user?.is_superuser || (employeeStore.currentUser.user?.is_staff && !employeeStore.currentUser.user?.is_superuser)"
            class="form-check form-switch">
            <input class="form-check-input" id="editswitch" type="checkbox" v-model="editState" />
            <label class="form-check-label" for="editswitch">Enable Editing</label>
          </div>
          <div v-if="job" class="job-header">
            <div class="row">
              <h1>
                <input class="job-name-input" :disabled="!editState" type="text" v-model="job.name" />
              </h1><br>
              <textarea class="job-description-textarea" :disabled="!editState"
                oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
                v-model="job.description"></textarea>
            </div>
          </div>
          <div v-if="job" class="job-details">
            <div class="row">
              <div class="row py-2">
                <div class="col col-4">
                  Priority :
                </div>
                <div class="col col-8">
                  <b>{{ priorityLabel }}</b>
                </div>
              </div>
              <div class="row py-2">
                <div class="col col-4">
                  Created By :
                </div>
                <div v-if="job" class="col col-8">
                  <UserBadge :user-id="createdById" />
                </div>
              </div>
              <div class="row py-2">
                <div class="col col-4">
                  Start Date :
                </div>
                <div class="col col-8">
                  <DateTimeBadge :time="job?.started" />
                </div>
              </div>
              <div class="row py-2">
                <div class="col col-4">
                  End Date :
                </div>
                <div class="col col-8">
                  <DateTimeBadge :time="job?.completed" />
                </div>
              </div>
              <div class="row py-2">
                <div class="col col-4">
                  Man Hours :<br>(HH/MM/SS)
                </div>
                <div class="col col-8">
                  {{ truncateMilliseconds(job?.total_active_duration) }}
                </div>
              </div>
              <div v-if="employeeStore.currentUser.user?.is_superuser" class="row py-2">
                <div class="col col-4">
                  Total Cost :
                </div>
                <div class="col col-8">
                  ${{ job?.total_labor_cost }}
                </div>
              </div>
            </div>
            <br>
          </div>
          <div class="row actionButtons">
            <button class="btn btn-primary customSaveButton"
              v-if="saved === SaveState.Changed || saved === SaveState.SaveFailed" @click="save">Save</button>
            <button class="btn btn-primary" v-if="saved === SaveState.Saving" disabled>Saving...</button>
            <span class="alert alert-success rounded mx-2" v-if="saved === SaveState.Saved" disabled>Saved</span>
            <span class="alert alert-danger rounded mx-2" v-if="saved === SaveState.SaveFailed" disabled>Save
              Failed</span>
          </div>
          <div class="task-table">
            <div v-if="employeeStore.currentUser.user?.is_superuser">
              <TaskList :tasks="job.tasks"
                :visibleColumns="['id', 'name', 'description', 'started', 'concluded', 'total_active_duration', 'labor_cost', 'assigned', 'actions']" />
            </div>
            <div v-if="employeeStore.currentUser.user?.is_staff && !employeeStore.currentUser.user?.is_superuser">
              <TaskList :tasks="job.tasks"
                :visibleColumns="['id', 'name', 'description', 'started', 'concluded', 'total_active_duration', 'assigned', 'actions']" />
            </div>
          </div>
        </div>
      </div>

    </div>
    <div v-else class="loading-container">
      <p></p>
    </div>
  </div>
</template>

<script setup lang="ts">
import Modal from '@/components/Modal.vue';
import TaskList from '@/components/TaskList.vue';
import DateTimeBadge from '@/components/DateTimeBadge.vue';
import UserBadge from '@/components/UserBadge.vue';
import { useOpsSupplementStore } from '@/stores/OpsSupplement';
import { useJobStore } from '@/stores/Jobs';
import { useEmployeeStore } from '@/stores/UserEmployee';
import { storeToRefs } from 'pinia';
import { computed, ref, watch, onMounted, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { Job, Task, JobPriority } from '@/types/Ops'
import { fetchJobDetails } from '@/api/api';

const editState = ref(false);
const jobStore = useJobStore();
const isLoadingJobs = computed(() => jobStore.isLoadingJobs);
const employeeStore = useEmployeeStore();
const { employees } = storeToRefs(employeeStore);
const opsSupp = useOpsSupplementStore();
const { work_centers } = storeToRefs(opsSupp);
const route = useRoute();
const jobId = ref(parseInt(route.params.id as string));
const userStore = useEmployeeStore();
const currentUser = computed(() => userStore.currentUser);
const createdById = computed(() => job.value?.created_by ?? -1);

// console.log(route.params);

const job = computed(() => {
  if (jobStore.isLoadingJobs) {
    return undefined;
  }
  return jobStore.getJobById(jobId.value);
});

enum SaveState {
  NoChanges = 0,
  Changed = 1,
  Saving = 2,
  SaveFailed = 3,
  Saved = 4
}
const saved = ref<SaveState>(SaveState.NoChanges);

function truncateMilliseconds(durationStr) {
  return durationStr ? durationStr.split('.')[0] : '00:00:00';
}

watch(() => job, (nextJob, lastJob) => {
  if (lastJob.value != undefined) saved.value = SaveState.Changed;
}, { deep: true });

watch(() => route.params, (nextParam, lastParam) => {
  jobId.value = parseInt(nextParam.id as string);
});


const priorityLabel = computed(() => {
  switch (job.value?.priority) {
    case JobPriority.Low:
      return 'Low';
    case JobPriority.Medium:
      return 'Medium';
    case JobPriority.High:
      return 'High';
    default:
      return 'Not Set';
  }
});

onMounted(async () => {
  if (!jobStore.all_jobs?.length) {
    jobStore.getAllJobs();
  }
  try {
    const jobDetails = await fetchJobDetails(jobId.value);
    // console.log("Fetched Job Details in JobView:", jobDetails);
    jobStore.updateJob(jobDetails);
  } catch (error) {
    console.error('Error fetching job details:', error);
  }
});

const save = () => {
  editState.value = false;
  saved.value = SaveState.Saving;
  jobStore.saveJob(jobId.value)
    .then(() => {
      saved.value = SaveState.Saved;
    })
    .catch(() => {
      editState.value = true;
      saved.value = SaveState.SaveFailed;
    });
};
</script>

<style scoped>
.home {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white !important;
  min-height: 100vh;
}

.jobWrapper {
  margin: 2% 10%;
  display: flex;
  flex-direction: row;
}

.whole-job {
  flex-grow: 1;
}

.jobId {
  /* flex-basis: 10%; */
  width: 100px;
  padding-right: 10px;
}

.actionButtons {
  justify-content: center;
}

.jobContent {
  flex-basis: 90%;
  width: 100%;
  padding: 0.5rem;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0.1, 0, 0, 0.1);
}

.customSaveButton {
  margin-top: 30px;
  width: 100px;
  padding-left: 20px;
  padding-right: 20px;
}

.job-header {
  color: #000000;
  padding: 0.5rem;
  margin-bottom: 10px;
  width: 100%;
  border: 0;
  display: inline-block;
  background-color: rgba(89, 100, 100, 0.1);
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0.1, 0, 0, 0.1);
}

.job-name-input {
  color: #000000;
  margin: 0.25rem;
  padding: 0.25rem;
  border: none;
  background-color: transparent;
  width: 100%;
}

.job-description-textarea {
  color: #000000;
  margin: 0.25rem;
  padding: 0.25rem;
  border: none;
  background-color: transparent;
  width: 100%;
  resize: none;
}

.job-details {
  color: #000000;
  padding: 0.5rem;
  width: 100%;
  border: 0;
  display: inline-block;
  background-color: rgba(207, 226, 255, 1.000);
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0.1, 0, 0, 0.1);
}

.task-table {
  width: 80vw;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-container::after {
  content: '';
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>