<template>
    <span class="custom-badge">
        <template v-if="isValidDate">
            {{ formattedDate }}
            <br>
            {{ formattedTime }}
        </template>
        <template v-else>
            NA
        </template>
    </span>
</template>

<script setup lang="ts">
import { defineProps, computed } from 'vue';

const props = defineProps<{ time?: Date | string }>();

const isValidDate = computed(() => {
    return props.time && !isNaN(new Date(props.time).getTime());
});

const formattedDate = computed(() => {
    if (isValidDate.value) {
        const date = new Date(new Date(props.time).getTime() + new Date().getTimezoneOffset() * 60000);
        return date.toLocaleDateString('en-us', {
            weekday: "short", year: "numeric",
            month: "numeric", day: "numeric"
        });
    }
    return '';
});

const formattedTime = computed(() => {
    if (isValidDate.value) {
        const date = new Date(new Date(props.time).getTime() + new Date().getTimezoneOffset() * 60000);
        return date.toLocaleTimeString('en-us', {
            hour: 'numeric', minute: 'numeric', hour12: true
        });
    }
    return '';
});
</script>

<style scoped>
.custom-badge {
    font-size: 0.9em;
    white-space: pre;
}
</style>
