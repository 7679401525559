<template>
  <div>
    <div v-if="!isEditing" class="card p-4">
      <div class="image d-flex flex-column justify-content-center align-items-center">
        <button class="btn btn-secondary">
          <img src="@/assets/userprofile.png" height="100" width="100" />
        </button>
        <span class="mt-3" v-if="userEmployeeStore.currentUser?.user.is_superuser">Admin</span>
        <span class="mt-3"
          v-if="userEmployeeStore.currentUser?.user.is_staff && !userEmployeeStore.currentUser?.user.is_superuser">Manager</span>
        <span class="mt-3"
          v-if="!userEmployeeStore.currentUser?.user.is_superuser && !userEmployeeStore.currentUser?.user.is_staff">Employee</span>
        <span class="name">{{ userProfile.name }}</span>
        <span class="email">{{ userProfile.email }}</span>
        <div class="d-flex mt-2">
          <button class="btn1 btn-dark" style="border-radius: 25px;" @click="navigateToEditProfile">Edit
            Profile</button>
        </div>
        <div class="px-2 rounded mt-4 date">
          <span class="join">Joined -{{ userProfile.joinedDate }}</span>
        </div>
        <div class="mt-3">
          <a class="log-out" href="/accounts/logout/">Log Out</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useEmployeeStore } from '@/stores/UserEmployee';
import UserEditProfile from '@/views/UserEditProfile.vue';

const userEmployeeStore = useEmployeeStore();

// Define a type for userProfile
type UserProfileType = {
  name: string;
  email: string;
  joinedDate: string;
};

// Component setup
const userProfile = ref<UserProfileType>({
  name: '',
  email: '',
  joinedDate: ''
});
const isEditing = ref(false);
const router = useRouter();

const formatDate = (dateInput: Date | string): string => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let date: Date;

  if (dateInput instanceof Date) {
    date = dateInput;
  } else {
    date = new Date(dateInput);
  }

  return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
};

const fetchUserData = async (): Promise<void> => {
  try {
    const userStore = useEmployeeStore();
    await userStore.fetch();
    const currentUser = userStore.currentUser;
    if (currentUser) {
      userProfile.value.name = currentUser.user.full_name;
      userProfile.value.email = currentUser.user.email;
      userProfile.value.joinedDate = formatDate(currentUser.user.date_joined);
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
  }
};

const navigateToEditProfile = (): void => {
  router.push({ name: 'edit-profile' });
};

onMounted(() => {
  fetchUserData();
});
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #000;
}

a {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px 10px 10px 10px;
  border-radius: 25px;
  background-color: #f7f4f4;
  color: #333;
  text-align: left;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
  transform: scale(1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.log-out:hover {
  color: #FFFFFF;
  background-color: rgb(219, 80, 75);
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card {
  width: 350px;
  background-color: #efefef;
  border: 1px solid #d5d3d3;
  cursor: pointer;
  transition: all 2s ease;
  transform: scale(1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.image img {
  transition: all 0.5s;
}

.card:hover .image img {
  transform: scale(1.5);
}

.btn {
  height: 140px;
  width: 140px;
  border-radius: 50%;
}

.name {
  font-size: 22px;
  font-weight: bold;
}

.email {
  font-size: 16px;
  font-weight: 500;
  color: #444444;
}

.btn1 {
  height: 40px;
  width: 150px;
  border: none;
  background-color: #000;
  color: #aeaeae;
  font-size: 15px;
}

.icons span {
  font-size: 24px;
  margin-right: 10px;
}

.join {
  font-size: 14px;
  color: #a0a0a0;
  font-weight: bold;
}

.date {
  background-color: #ccc;
}

.image img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
}
</style>
