<template>
  <div class="home" data-bs-theme="light">
    <div v-if="isCreatingTask" class="loading-overlay">
      Creating Jobs and Tasks...
      <br>
      <div class="loading-container"></div>
    </div>
    <div v-if="job" class="jobWrapper">
      <h1>Create new Job from Job Template</h1>
      <br>
      <strong>
        <p>
          This is a predefined blueprint for a job that is expected to be repeated. Creating a new job from a job
          template
          will keep a link to the original template, which helps in maintaining a standard process and makes job
          creation
          more efficient.
        </p>
      </strong>
      <hr>
      <br>
      <h2>Job Template Details</h2>
      <br>
      <div class="row">
        <span class="col-3"><label for="jobTemplateType">Job Template Type<span class="required-asterisk">*</span> :
          </label></span>
        <span class="col"><select id="jobTemplateType" class="form-select" v-model="selectedJobTemplateId"
            @change="updateDisplayedJobTemplate" @input="clearErrorMsg" required>
            <option value="" disabled>Select a template</option>
            <option v-for="jt in allJobTemplates" :key="jt.id" :value="jt.id">
              {{ jt.name }}
            </option>
          </select>
          <div v-if="!selectedJobTemplateId">
            <p>Select a job template to view details.</p>
          </div>
        </span>
      </div>
      <div v-if="!jobTemplate">
        <p>Error: Unable to load job template.</p>
      </div>
      <div v-else>
        <div class="row my-1">
          <span class="col-3 my-1">Job Template Name: </span>
          <span class="col">{{ displayedJobTemplate.name }}</span>
        </div>
        <div class="row">
          <span class="col-3 my-1">Job Template Description: </span>
          <span class="col">{{ displayedJobTemplate.description }}</span>
        </div>
        <div class="row">
          <span class="col-3 my-1">Job Template Part Reference: </span>
          <span class="col">{{ displayedJobTemplate.part_reference }}</span>
        </div>
      </div>
      <br>
      <hr>
      <h2>Job Details</h2>
      <br>
      <div class="input-group mb-3">
        <span class="col-3 input-group-text">Name<span class="required-asterisk">*</span></span>
        <input class="form-control" v-model="job.name" type="text" required>
      </div>
      <div class="input-group mb-3">
        <span class="col-3 input-group-text">Description<span class="required-asterisk">*</span></span>
        <textarea class="form-control" v-model="job.description" required></textarea>
      </div>
      <div class="input-group mb-3">
        <span class="col-3 input-group-text">Priority <span class="required-asterisk">*</span></span>
        <select v-model="job.priority" class="form-select" required>
          <option value="" disabled selected>Select Priority</option>
          <option value="1">Low</option>
          <option value="2">Medium</option>
          <option value="3">High</option>
        </select>
      </div>
      <br>
      <hr>
      <br>
      <h2>Tasks</h2>
      <h5>Task Template Details : </h5>
      <div class="row my-1">
        <span class="col-3"><label for="taskTemplateType">Task Template Type<span class="required-asterisk">*</span>:
          </label></span>
        <span class="col"><select id="taskTemplateType" class="form-select" v-model="selectedTaskTemplateId"
            @change="updateDisplayedTaskTemplate" required>
            <option value="" disabled>Select a task template to view details.</option>
            <option v-for="tmpl in allTaskTemplates" :key="tmpl.id" :value="tmpl.id">
              {{ tmpl.name }}
            </option>
          </select>
          <div class="row" v-if="!selectedTaskTemplateId">
            <p>Select a task template to view details.</p>
          </div>
        </span>
      </div>
      <div v-if="!taskTemplate">
        <p>Error: Unable to load task template.</p>
      </div>
      <div v-else>
        <div class="row my-1">
          <span class="col-3 my-1">Template Name: </span>
          <span class="col">{{ displayedTaskTemplate.name }}</span>
        </div>
        <div class="row my-1">
          <span class="col-3 my-1">Template Description: </span>
          <span class="col">{{ displayedTaskTemplate.description }}</span>
        </div>
        <div class="row my-1">
          <span class="col-3 my-1">Template Work Center: </span>
          <span class="col">{{ displayedTaskTemplate.work_center ? displayedTaskTemplate.work_center.name : 'N/A'
            }}</span>
        </div>
        <div class="row my-1">
          <span class="col-3 my-1">Template Cost Center: </span>
          <span class="col">{{ displayedTaskTemplate.cost_center ? displayedTaskTemplate.cost_center.name : 'N/A'
            }}</span>
        </div>
        <br>
        <hr>
        <br>
        <h2>Task Details</h2>
        <div v-for="(task, index) in tasks" :key="index">
          <h3>Task {{ index + 1 }}</h3>
          <div class="input-group mb-3">
            <span class="col-3 input-group-text">Task Name<span class="required-asterisk">*</span></span>
            <input class="form-control task-name-input" type="text" v-model="task.name" required>
          </div>
          <div class="input-group mb-3">
            <span class="col-3 input-group-text">Task Description<span class="required-asterisk">*</span></span>
            <textarea class="form-control task-description-input" v-model="task.description" required></textarea>
          </div>
          <div class="input-group mb-3">
            <label class="col-3 input-group-text">Employee <span class="required-asterisk">*</span></label>
            <select v-model="task.assignedEmployee" class="form-select" required>
              <option value="" disabled selected>Select an Employee</option>
              <option v-for="employee in filteredEmployees" :key="employee.id" :value="employee.id">
                {{ employee.displayName }}
              </option>
            </select>
          </div>
          <div class="input-group mb-3">
            <span class="col-3 input-group-text">Priority <span class="required-asterisk">*</span></span>
            <select v-model="task.priority" class="form-select" required>
              <option value="" disabled selected>Select Priority</option>
              <option value="1">Low</option>
              <option value="2">Medium</option>
              <option value="3">High</option>
            </select>
          </div>
          <div class="input-group mb-3">
            <span class="col-3 input-group-text" for="expectedStartDate">Expected Start<span
                class="required-asterisk">*</span></span>
            <input id="expectedStartDate" v-model="task.expected_start" type="date" class="form-control" required>
          </div>
          <div class="input-group mb-3">
            <span class="col-3 input-group-text" for="expectedConclusionDate">Expected Conclusion <span
                class="required-asterisk">*</span></span>
            <input id="expectedConclusionDate" v-model="task.expected_concluded" type="date" class="form-control"
              required>
          </div>
          <button class="btn btn-danger remove-task-btn" @click="removeTask(index)">Remove Task</button>
        </div>
      </div>
      <button class="btn btn-info add-task-btn" @click="addTask">Add Task</button>
      <button class="btn btn-primary create-job-btn" @click="createJob" :disabled="!isFormValid">Create Job</button>
      <div v-if="errorMsg" class="alert alert-danger">{{ errorMsg }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { fetchAllJobTemplates, fetchAllTaskTemplates, postToUrl } from '@/api/api';
import { Job, JobTemplate, Task, TaskTemplate } from '@/types/Ops';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useEmployeeStore } from '@/stores/UserEmployee';

const route = useRoute();
const isCreatingTask = ref(false);
const jobTemplateId = ref(parseInt(route.params.templateId as string));
const employeeStore = useEmployeeStore();
const allJobTemplates = ref<JobTemplate[]>([]);
const selectedJobTemplateId = ref(null);
const allTaskTemplates = ref<TaskTemplate[]>([]);
const selectedTaskTemplateId = ref(null);
const errorMsg = ref('');


const job = ref<Job>({
  id: 0,
  name: '',
  description: '',
  build_order_reference: "",
  tasks: [],
  work_center: undefined,
  priority: 2,
  created_by: employeeStore.currentUser?.id,
});

const jobTemplate = ref<JobTemplate>({
  id: 0,
  name: '',
  description: '',
  part_reference: '',
  value: undefined,
  task_templates: [],
  work_center: 0
});

const tasks = ref<Task[]>([
  {
    id: 0,
    name: '',
    description: '',
    job: 0,
    employees: null,
    assignedEmployee: null,
    expected_start: '',
    expected_concluded: '',
    priority: 2,
  }
]);

const taskTemplate = ref<TaskTemplate>({
  id: 0,
  name: '',
  description: '',
  work_center: null,
  cost_center: null,
  job_template: 0,
  job_template_order: 0
});

const clearErrorMsg = () => {
  errorMsg.value = '';
};

watch(() => route.params, (nextParam, lastParam) => {
  jobTemplateId.value = parseInt(nextParam.templateId as string);
});


const filteredEmployees = computed(() => {
  return employeeStore.employees?.map(employee => {
    let rolePrefix;
    if (employee.user.is_superuser) {
      rolePrefix = "AD"; // Admin
    } else if (employee.user.is_staff) {
      rolePrefix = "MA"; // Manager
    } else {
      rolePrefix = "EM"; // Employee
    }
    const displayName = `${rolePrefix} - ${employee.user.full_name}`;
    return {
      ...employee,
      rolePrefix,
      displayName,
    };
  }) || [];
});

onMounted(async () => {
  try {
    allJobTemplates.value = await fetchAllJobTemplates();
    allTaskTemplates.value = await fetchAllTaskTemplates();
    await employeeStore.fetch();
  } catch (error) {
    console.error("Error fetching job templates:", error);
  }
});

const displayedJobTemplate = computed(() => {
  return selectedJobTemplateId.value
    ? jobTemplate.value
    : {
      name: '',
      description: '',
      part_reference: ''
    };
});

const displayedTaskTemplate = computed(() => {
  return selectedTaskTemplateId.value
    ? taskTemplate.value
    : {
      name: '',
      description: '',
      work_center: null,
      cost_center: null,
    };
});

watch(selectedJobTemplateId, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    updateDisplayedJobTemplate();
  }
});

watch(selectedTaskTemplateId, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    updateDisplayedTaskTemplate();
  }
});

const updateDisplayedJobTemplate = () => {
  const selectedTemplate = allJobTemplates.value.find(jt => jt.id === selectedJobTemplateId.value);
  if (selectedTemplate) {
    jobTemplate.value = selectedTemplate;
  }
};

const updateDisplayedTaskTemplate = () => {
  const selectedTemplate = allTaskTemplates.value.find(t => t.id === selectedTaskTemplateId.value);
  if (selectedTemplate) {
    taskTemplate.value = selectedTemplate;
  }
};

const addTask = () => {
  tasks.value.push({
    id: 0,
    name: '',
    description: '',
    employees: null,
    assignedEmployee: null,
    expected_start: '',
    expected_concluded: '',
    priority: 2,
  });
};


const removeTask = (index: number) => {
  tasks.value.splice(index, 1);
};

const resetTask = () => {
  tasks.value = [
    {
      id: 0,
      name: '',
      description: '',
      employees: null,
      value: '',
      work_center: null,
      cost_center: null,
      job: 0,
      started: undefined,
      started_by: 0,
      concluded: undefined,
      concluded_by: 0,
      successful: false,
      conclusion_notes: '',
      events: [],
      perpetual: false,
      task_template: 0,
      job_order: 0,
      task: undefined,
      completed: '',
      expected_start: '',
      expected_concluded: ''
    }
  ];
};


const isFormValid = computed(() => {
  if (!job.value.name.trim() || !job.value.description.trim()) {
    return false;
  }
  return tasks.value.every(task =>
    (task.name?.trim() || '') &&
    (task.description?.trim() || '') &&
    (task.expected_start?.trim() || '') &&
    task.assignedEmployee &&
    selectedTaskTemplateId.value
  );
});

const createJob = async () => {
  if (!isFormValid.value) {
    errorMsg.value = 'Please fill out all required fields for the job and each task.';
    return;
  }
  isCreatingTask.value = true;
  try {
    // console.log("Sending job data:", job.value);
    const jobResponse = await postToUrl(`/ops/api/template_to_job/${selectedJobTemplateId.value}/`, job.value);
    // console.log("Job creation response:", jobResponse);
    const jobId = jobResponse.id;
    // console.log("the job id is : ", jobId);
    for (const task of tasks.value) {
      if (!task.name && !task.description) {
        continue;
      }
      const taskPayload = {
        name: task.name,
        description: task.description,
        job: jobId,
        task_template: selectedTaskTemplateId.value,
        employees: task.assignedEmployee ? [task.assignedEmployee] : [],
        expected_start: task.expected_start,
        expected_concluded: task.expected_concluded,
        created_by: employeeStore.currentUser?.id,
        priority: task.priority,
      };
      // console.log('Task Payload:', taskPayload);
      await postToUrl(`/ops/api/create-task/${selectedTaskTemplateId.value}/`, taskPayload);
    }
    alert("Job and tasks have been successfully created.");
  } catch (error) {
    alert("Error occurred while creating job and tasks.");
  } finally {
    isCreatingTask.value = false;
    resetTask();
  }
};

</script>

<style scoped>
.home {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0.1, 0.1, 0.1, 0.2);
  margin: 2% 20%;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white !important;
  min-height: 100vh;

}

.jobWrapper {
  padding: 10px 20px;
  max-width: 800px;
  width: 100%;
}

.add-task-btn {
  float: left;
  border: none;
  margin: 10px 0;
  background-color: #90EE90;
}

.create-job-btn {
  display: block;
  margin: 50px auto;
}

.required-asterisk {
  color: red;
}

.form-control[type="date"] {
  color: black;
  background-color: #ccc;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-container::after {
  content: '';
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>