import { defineStore } from 'pinia';
import { fetchFromUrl } from '@/api/api';
import { User } from '@/types/Ops';

interface UserState {
    Users: User[] | null,
    currentUser: User | null,
}

export const useUserStore = defineStore('User', {
    state: (): UserState => ({
        Users: null,
        currentUser: null,
    }),
    actions: {
        async fetchCurrentUser() {
            try {
                const currentUser = await fetchFromUrl<User>("/ops/api/user/current/");
                this.currentUser = currentUser;
            } catch (error) {
                console.error('Error fetching current User:', error);
            }
        },
        async fetchAllUsers() {
            try {
                const allUsers = await fetchFromUrl<User[]>("/ops/api/siteusers/");
                this.Users = allUsers;
            } catch (error) {
                console.error('Error fetching all Users:', error);
            }
        },
        async fetchUserById(userId: number) {
            try {
                const user = await fetchFromUrl<User>(`/ops/api/siteusers/${userId}`);
                return user; 
            } catch (error) {
                console.error('Failed to fetch user by ID:', error);
            }
        },
        async setCurrentUser(userId: number) {
            try {
                const user = await fetchFromUrl<User>(`/ops/api/siteusers/${userId}`);
                this.currentUser = user;
            } catch (error) {
                console.error('Failed to fetch user:', error);
            }
        }
    
    },
    
    getters: {
        getUserById: (state) => (id: number) => {
            return state.Users?.find(User => User.id === id);
        },
    }
});
