<template>
    <div class="create-employee-form">
        <h2>Employee Salaries</h2>
        <form @submit.prevent="createEmployee">
            <div class="form-group">
                <label for="user">User</label>
                <select id="user" v-model="selectedUserId" @change="updateDisplayedUser" @input="clearUserErrorMsg">
                    <option v-for="user in users" :key="user.id" :value="user.id">
                        {{ user.full_name }}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label for="hourly_pay">Hourly Pay</label>
                <input id="hourly_pay" v-model="employee.hourly_pay" type="number" required>
            </div>
            <div class="form-group">
                <label for="workCenter">Work Center</label>
                <select id="workCenter" v-model="selectedWorkCenterId" @change="updateWorkCenter">
                    <option v-for="workCenter in work_centers" :key="workCenter.id" :value="workCenter.id">
                        {{ workCenter.name }}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label for="active">Active</label>
                <input id="active" type="checkbox" v-model="employee.active">
            </div>
            <button type="submit">Save</button>
        </form>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import { useUserStore } from '@/stores/UserStore';
import { useEmployeeStore } from '@/stores/UserEmployee';
import { postToUrl } from '@/api/api';
import { User } from '@/types/Ops';

interface UserEmployee {
    user: number;
    hourly_pay: number;
    work_centers: number[];
    active: boolean;
}

const userStore = useUserStore();
const employeeStore = useEmployeeStore();
const users = ref<User[]>([]);
const work_centers = ref<WorkCenter[]>([]);
const selectedUserId = ref<number | null>(null);
const selectedWorkCenterId = ref<number | null>(null);
const employee = ref<UserEmployee>({
    user: 0,
    hourly_pay: 0,
    work_centers: [],
    active: false
});

const errorMsg = ref('');

const clearUserErrorMsg = () => {
    errorMsg.value = '';

};

const fetchWorkCenters = async () => {
    try {
        const response = await fetch('/ops/api/workcenters/');
        if (response.ok) {
            work_centers.value = await response.json();
        } else {
            console.error('Failed to fetch work centers');
        }
    } catch (error) {
        console.error('Network error:', error);
    }
};

onMounted(async () => {
    await userStore.fetchAllUsers();
    users.value = userStore.Users;
    await fetchWorkCenters();
    await employeeStore.fetch();
    users.value = userStore.Users.filter(user => {
        const userEmployee = employeeStore.getById(user.id);
        return !userEmployee || userEmployee.hourly_pay === null;
    });
});

const updateDisplayedUser = () => {
    const selectedUser = users.value.find(u => u.id === selectedUserId.value);
    if (selectedUser) {
        employee.value.user = selectedUser.id; // Update the user_id property
    }
};

const updateWorkCenter = () => {
    if (selectedWorkCenterId.value) {
        employee.value.work_centers = [selectedWorkCenterId.value];
    }
};

watch(selectedUserId, (newVal, oldVal) => {
    if (newVal !== oldVal) {
        updateDisplayedUser();
    }
});

watch(selectedWorkCenterId, (newVal) => {
    if (newVal !== null) {
        updateWorkCenter();
    }
});

const resetForm = () => {
    selectedUserId.value = null;
    selectedWorkCenterId.value = null;
    employee.value = { user: 0, hourly_pay: 0, work_centers: [], active: false };
};

const createEmployee = async () => {
    const selectedUserIdValue = selectedUserId.value;
    if (selectedUserIdValue !== null && !isNaN(selectedUserIdValue) && selectedWorkCenterId.value !== null) {
        const userId = Number(selectedUserIdValue); // Convert to number
        const workCenterId = Number(selectedWorkCenterId.value); // Convert to number
        if (employee.value.user !== userId) {
            console.error('Mismatched user ID:', employee.value.user, '!==', userId);
            return;
        }
        const postData = {
            user: userId,
            hourly_pay: employee.value.hourly_pay,
            active: employee.value.active,
            work_centers: [workCenterId],
        };
        try {
            const response = await postToUrl('/ops/api/create_user_employee/', postData);
            alert('User employee created successfully!');
            resetForm();
        } catch (error) {
            console.error('Error creating employee:', error);
            alert('Error creating employee: ' + error.message);
        }
    } else {
        console.error('User or Work Center not selected');
        alert('Please select both a user and a work center');
    }

};

</script>

<style scoped>
.create-employee-form {
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white !important;
    min-height: 100vh;
    min-width: 500px;
}

.create-employee-form h2 {
    text-align: center;
    color: #333;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input[type="text"],
.form-group input[type="number"],
.form-group input[type="password"],
.form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #FFFFFF;
    /* Set background color to white */
    color: #000000;
    /* Optional: Set text color to black if needed */
}

.form-group input[type="checkbox"] {
    margin-top: 10px;
}

button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}
</style>