<template>
  <div class="create-task-template-form" data-bs-theme="light">
    <div class="templateWrapper">
      <h1>Create Task Template</h1>
      <br>
      <strong>
        <p>
          A Task template represents a repeatable task. When creating a new task from the template, the new task retains
          a
          reference to its template. Task templates are used within job templates to define the tasks needed for the
          job.
        </p>
        <p>
          Task Template provides some predefined attributes( Work Center, Cost Center)
          to the task so that every time we create a new task we don't have to assign
          those attributes to the task. These templates will also help us to Group the
          Tasks according to the template to which they belong to.
        </p>
      </strong>
      <hr>
      <br>
      <form @submit.prevent="saveTaskTemplate">
        <div class="form-group">
          <label for="templateName">Template Name<span class="required-asterisk">*</span></label>
          <input id="templateName" type="text" v-model="taskTemplate.name" required>
        </div>
        <div class="form-group">
          <label for="templateDesc">Template Description<span class="required-asterisk">*</span></label>
          <textarea id="templateDesc" cols="30" rows="5" v-model="taskTemplate.description" required></textarea>
        </div>
        <div class="row form-group">
          <label for="templateWorkcenter">Work Center<span class="required-asterisk">*</span> <br>
            <p>Select a work center for this task template</p>
          </label>
          <select id="templateWorkcenter" class="form-select" v-model="taskTemplate.workCenterId" required>
            <option v-for="workCenter in work_centers" :key="workCenter.id" :value="workCenter.id">{{ workCenter.name }}
            </option>
          </select>
        </div>
        <div class="row form-group">
          <label for="templateCostcenter">Cost Center<span class="required-asterisk">*</span> <br>
            <p>Select a cost center for this task template</p>
          </label>
          <select id="templateCostcenter" class="form-select" v-model="taskTemplate.costCenterId" required>
            <option v-for="costCenter in cost_centers" :key="costCenter.id" :value="costCenter.id">{{ costCenter.name }}
            </option>
          </select>
        </div>
        <div class="row form-group">
          <label for="jobTemplateName">Job Template Name <span class="required-asterisk">*</span><br>
            <p>Select a Job Template to which this Task Template will belong to</p>
          </label>
          <select id="jobTemplateName" class="form-select" v-model="taskTemplate.jobTemplateId" required>
            <option v-for="jobTemplate in jobTemplates" :key="jobTemplate.id" :value="jobTemplate.id">
              {{ jobTemplate.name }}
            </option>
          </select>
        </div>
        <div class="row form-group">
          <label for="jobTemplateOrder">Job Template Order <span class="required-asterisk">*</span> <br>
            <p>Select a Job Template of this Task Template</p>
          </label>
          <input id="jobTemplateOrder" class="form-control" v-model.number="taskTemplate.jobTemplateOrder" type="number"
            required>
        </div>
        <div class="form-group">
          <button class="btn btn-primary create-tasktemplate-btn" type="submit">Save</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted } from 'vue';
import { fetchAllJobTemplates, postToUrl } from '@/api/api';
import { useOpsSupplementStore } from '@/stores/OpsSupplement';
import { JobTemplate } from '@/types/Ops';
import { storeToRefs } from 'pinia';

const opsSupp = useOpsSupplementStore();

const taskTemplate = reactive({
  name: '',
  description: '',
  workCenterId: null,
  costCenterId: null,
  jobTemplateId: null,
  jobTemplateOrder: 0
});

const { work_centers, cost_centers } = storeToRefs(opsSupp);
const jobTemplates = ref<JobTemplate[]>([]);

const resetForm = () => {
  taskTemplate.name = '';
  taskTemplate.description = '';
  taskTemplate.workCenterId = null;
  taskTemplate.costCenterId = null;
  taskTemplate.jobTemplateId = null;
  taskTemplate.jobTemplateOrder = 0;
};

onMounted(async () => {
  await opsSupp.fetch();
  jobTemplates.value = await fetchAllJobTemplates();
});

const saveTaskTemplate = async () => {
  try {
    const payload = {
      name: taskTemplate.name,
      description: taskTemplate.description,
      work_center_id: taskTemplate.workCenterId,
      cost_center_id: taskTemplate.costCenterId,
      job_template: taskTemplate.jobTemplateId,
      job_template_order: taskTemplate.jobTemplateOrder
    };
    // console.log("Sending Payload:", payload); // Log the payload
    const response = await postToUrl('/ops/api/tasktemplates/', payload);
    // console.log("Task Template Created:", response);
    alert("Task Template created successfully!");
    resetForm();
  } catch (error) {
    // console.error("Error creating task template:", error);
    alert("Failed to create Task Template. Please check the data and try again.");
  }
};
</script>

<style scoped>
.create-task-template-form {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0.1, 0.1, 0.1, 0.2);
  margin: 2% 20%;
  padding: 10 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white !important;
  min-height: 100vh;
}

.templateWrapper {
  padding: 10px 20px;
  max-width: 800px;
  width: 100%;
}

.form-group {
  margin-bottom: 15px;
  padding: 0 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.required-asterisk {
  color: red;
}

p {
  margin-bottom: 0%;
}

.create-tasktemplate-btn {
  display: block;
  margin: 50px auto;
}
</style>