<template>
  <div class="view-user-form">
    <h2>Edit User Profile</h2>
    <div class="toggle-switch">
      <input id="editToggle" type="checkbox" v-model="isEditing">
      <label for="editToggle" class="switch"></label>
    </div>
    <form>
      <div class="form-group">
        <label for="username">Username</label>
        <input id="username" v-model="editableUser.user.username" :readonly="!isEditing" type="text">
      </div>
      <div class="form-group">
        <label for="firstName">First Name</label>
        <input id="firstName" v-model="editableUser.user.first_name" :readonly="!isEditing" type="text">
      </div>
      <div class="form-group">
        <label for="lastName">Last Name</label>
        <input id="lastName" v-model="editableUser.user.last_name" :readonly="!isEditing" type="text">
      </div>
      <div class="form-group">
        <label for="email">Email</label>
        <input id="email" v-model="editableUser.user.email" :readonly="!isEditing" type="email">
      </div>
      <div class="form-group">
        <label style="font-size: 1.2em;">Do you want to change your password?</label>
        <div class="password-change-options">
          <input type="radio" id="yes" value="Yes" v-model="passwordChange" :disabled="!isEditing">
          <label for="yes">Yes</label>
          <input type="radio" id="no" value="No" v-model="passwordChange" :disabled="!isEditing">
          <label for="no">No</label>
        </div>
      </div>
      <div v-if="passwordChange === 'Yes' && isEditing">
        <div class="form-group">
          <label for="oldPassword">Old Password</label>
          <input id="oldPassword" type="password" v-model="oldPassword" placeholder="Enter your old password">
          <button @click="verifyOldPassword" :disabled="verifying" class="verify-button">Verify</button>
          <span v-if="verifying" class="loader"></span>
          <span v-if="passwordVerified" class="verification-icon">&#10004;</span>
        </div>
        <div v-if="passwordVerified" class="success-message">
          <span>Password verified successfully!</span>
        </div>
        <div v-if="passwordVerificationFailed" class="failure-message">
          <span>Password does not match. Please try again.</span>
        </div>
        <div v-if="!passwordVerified" class="form-group">
          <input type="checkbox" id="forgotPassword" v-model="forgotPassword" @change="handleForgotPassword">
          <label for="forgotPassword">I do not remember my old password</label>
        </div>
        <div v-if="passwordVerified" class="form-group">
          <label for="newPassword">New Password</label>
          <input id="newPassword" type="password" v-model="newPassword" placeholder="Create your new password">
        </div>
      </div>
      <div class="form-group" v-if="isEditing">
        <button type="button" class="save-changes-btn" @click="saveUserProfile">Save Changes</button>
      </div>
    </form>
  </div>
</template>


<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useEmployeeStore } from '../stores/UserEmployee';
import { patchToUrl, postToUrl } from '../api/api';

interface PasswordVerificationRequest {
  password: string;
}

interface PasswordVerificationResponse {
  isPasswordCorrect: boolean;
}

interface UserData {
  id?: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  password?: string;
}

const router = useRouter();
const employeeStore = useEmployeeStore();
const editableUser = computed(() => employeeStore.currentUser);
const isEditing = ref(false);
const passwordChange = ref('No');
const oldPassword = ref('');
const newPassword = ref('');
const forgotPassword = ref(false);
const verifying = ref(false);
const passwordVerified = ref(false);
const passwordVerificationFailed = ref(false);

onMounted(async () => {
  if (!employeeStore.currentUser) {
    await employeeStore.fetch();
  }
});

const handleForgotPassword = () => {
  if (forgotPassword.value) {
    const passwordResetUrl = '/custom-password-reset/';
    window.location.href = passwordResetUrl;
  }
};

const verifyOldPassword = async () => {
  verifying.value = true;
  passwordVerified.value = false;
  passwordVerificationFailed.value = false;

  const requestData: PasswordVerificationRequest = {
    password: oldPassword.value
  };

  try {
    const response = await postToUrl('/ops/api/verify-password/', requestData);

    if (typeof response === 'object' && 'isPasswordCorrect' in response) {
      const verificationResponse = response as PasswordVerificationResponse;
      passwordVerified.value = verificationResponse.isPasswordCorrect;
      passwordVerificationFailed.value = !verificationResponse.isPasswordCorrect;
    } else {
      console.error('Unexpected response format:', response);
      passwordVerificationFailed.value = true;
    }
  } catch (error) {
    console.error('Error during password verification:', error);
    passwordVerificationFailed.value = true;
  } finally {
    verifying.value = false;
  }
};


const saveUserProfile = async () => {
  const userData: UserData = {
    id: editableUser.value.user.id,
    username: editableUser.value.user.username,
    first_name: editableUser.value.user.first_name,
    last_name: editableUser.value.user.last_name,
    email: editableUser.value.user.email
  };

  if (passwordChange.value === 'Yes' && passwordVerified.value) {
    userData.password = newPassword.value;
  }

  try {
    const url = `/ops/api/Updateuser/${editableUser.value.user.id}/`;
    const response = await patchToUrl(url, userData);
    if (response && response.id) {
      alert('User profile updated successfully.');
      isEditing.value = false;
    } else {
      alert('Failed to update user profile. Please check the provided data.');
    }
  } catch (error) {
    alert('Failed to update user profile. Error: ' + error.message);
  }
};

const toggleEdit = () => {
  isEditing.value = !isEditing.value;
};
</script>

<style scoped>
.view-user-form {
  max-width: 400px;
  margin: 30px auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: white;
  font-size: 14px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: white;
  color: black;
}

.save-changes-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
}

.save-changes-btn:hover {
  background-color: #0056b3;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 10px 0;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.switch:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked+.switch {
  background-color: #2196F3;
}

input:checked+.switch:before {
  transform: translateX(26px);
}

input[type="text"],
input[type="email"],
input[type="password"] {
  font-weight: bold;
}

.password-change-options {
  display: flex;
  align-items: center;
  gap: 10px;
}

.password-change-options label {
  margin: 0;
}

.verify-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
  margin-top: 5px;
}

.verify-button:hover {
  background-color: #0056b3;
}

.success-message {
  color: green;
  margin-top: 10px;
  font-weight: bold;
}

.failure-message {
  color: red;
  margin-top: 10px;
  font-weight: bold;
}

.loader {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 3px solid #000000;
  animation: spin 2s linear infinite;
  margin-left: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.verification-icon {
  color: green !important;
  font-size: 20px;
}
</style>

