<template>
    <span v-if="employeeFullName" class="badge badge-pill bg-primary custom-badge">{{ employeeFullName }}</span>
    <!-- <span v-else class="badge badge-pill bg-secondary custom-badge">NA</span> -->
</template>

<script setup lang="ts">
import { useEmployeeStore } from '@/stores/UserEmployee';
import { computed, defineProps } from 'vue';

const employeeStore = useEmployeeStore();
const props = defineProps<{ userId?: number }>();

const employeeFullName = computed(() => {
    const employee = employeeStore.getByIdforFullName(props.userId ?? -1);
    if (!employee) {
        return 'NA'; // Return 'NA' if no employee is found
    }

    // Determine the role prefix based on user properties
    let rolePrefix;
    if (employee.user.is_superuser) {
        rolePrefix = "AD"; // Admin
    } else if (employee.user.is_staff) {
        rolePrefix = "MA"; // Manager
    } else {
        rolePrefix = "EM"; // Employee
    }

    // Concatenate the role prefix with the user's full name
    const displayName = `${rolePrefix} - ${employee.user.full_name}`;
    return displayName;
});

</script>

<style scoped>
.custom-badge {
    font-size: 1em;
}
</style>
