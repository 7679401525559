<template>
    <span style="position:relative">
        <span :class="(show ? 'text-wrap' : 'text-nowrap lh-1') + ' overflow-hidden w-100 d-inline-block endfade'"
            @mouseover="show = true" @mouseleave="show = false">{{
                text }}</span>
    </span>
</template>
<style>
.endfade:before {
    content: '';
    width: 300%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(to right, transparent 90%, white 100%);
}

.text-wrap,
.text-nowrap {
    transition: all 0.3s ease-in-out;
}

.text-nowrap {
    transition-delay: 0.2s;
}
</style>

<script setup lang="ts">
import { defineProps, ref } from 'vue';
const props = defineProps<{ text?: string }>();
const show = ref(false);
</script>