<template>
  <h3>Gantt Chart</h3>
  <div id="tooltip"
    style="display:none; position:absolute; top: -5px; left: 100%; background:white; border:1px solid #d4d4d4; padding:10px; z-index:100;border-radius: 5px;">
  </div>
  <div id="column-visibility-dropdown" class="dropdown" @click="toggleDropdown">
    <button class="dropdown-button">Columns</button>
    <div class="dropdown-content">
      <label><input type="checkbox" value="id" v-model="visibleColumns" /> ID</label>
      <label><input type="checkbox" value="text" v-model="visibleColumns" /> Task Name</label>
      <label><input type="checkbox" value="start_date" v-model="visibleColumns" /> Start Date</label>
      <label><input type="checkbox" value="end_date" v-model="visibleColumns" /> End Date</label>
      <label><input type="checkbox" value="duration" v-model="visibleColumns" /> Duration</label>
    </div>
  </div>
  <div ref="ganttContainer" id="gantt_here" class="gantt_container--material" style="width:100%;height: 780px;"></div>
</template>
    
<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch, reactive } from 'vue';
import { useGanttChartStore } from '@/stores/GanttChartStore';
import 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css';
import { gantt } from 'dhtmlx-gantt';

const ganttContainer = ref<HTMLElement | null>(null);
const store = useGanttChartStore();
const dropdownVisible = ref(false);
const visibleColumns = ref(['id', 'text', 'start_date', 'end_date', 'duration']);
const tasksForGantt = reactive({ data: [] });

function getDefaultStartDate() {
  return new Date().toISOString();
}

function getDefaultEndDate() {
  return new Date().toISOString();
}

function initGanttChart() {
  if (ganttContainer.value) {
    gantt.config.order_branch = true;
    gantt.config.autosize = "y";
    gantt.templates.task_class = function (start, end, task) {
      const taskId = String(task.id);

      if (taskId.startsWith("job-")) {
        return "job-task"; 
      } else if (taskId.startsWith("task-")) {
        return "regular-task"; 
      }
      return "";
    };
    gantt.init(ganttContainer.value);
    gantt.parse({ data: tasksForGantt.data });
    gantt.sort('id', false);
    gantt.render();
  }
}

function toggleDropdown(event) {
  event.stopPropagation();
  dropdownVisible.value = !dropdownVisible.value;
}
function closeDropdown() {
  dropdownVisible.value = false;
}
function handleClickOutside(event) {
  const dropdownElement = document.getElementById('column-visibility-dropdown');
  if (dropdownElement && !dropdownElement.contains(event.target)) {
    closeDropdown();
  }
}

watch(visibleColumns, (newColumns) => {
  gantt.config.columns = newColumns.map(column => {
    switch (column) {
      case 'id':
        return { name: "id", label: "ID", width: 100, align: "center" };
      case 'text':
        return {
          name: "text",
          label: "Task name",
          width: 200,
          tree: true,
          resize: true,
          template: function (task) {
            return `<div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width:200px;">${task.text}</div>`;
          }
        };
      case 'start_date':
        return { name: "start_date", label: "Start time", align: "center", width: 100 };
      case 'end_date':
        return { name: "end_date", label: "End time", align: "center", width: 100 };
      case 'duration':
        return { name: "duration", label: "Duration(Days)", align: "center", width: 100 };
      default:
        return null;
    }
  }).filter(Boolean);
  initGanttChart();
});

function updateTasksForGantt() {
  tasksForGantt.data = store.tasks.map(task => {
    const start_date = task.start_date && task.start_date !== "Not started"
      ? new Date(task.start_date)
      : new Date(getDefaultStartDate());

    const end_date = task.end_date && task.end_date !== "In progress"
      ? new Date(task.end_date)
      : new Date(getDefaultEndDate());

    return {
      ...task,
      start_date: start_date,
      end_date: end_date,
      duration: task.duration
    };
  });
}

onMounted(async () => {
  await store.fetchGanttChartTasks();
  // console.log('Tasks for Gantt Chart:', store.tasks);
  updateTasksForGantt();
  gantt.config.columns = [
    { name: "id", label: "ID", width: 100, align: "center" },
    {
      name: "text",
      label: "Task name",
      width: 200,
      tree: true,
      template: function (task) {
        return `<div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width:200px;">${task.text}</div>`;
      }
    },
    { name: "start_date", label: "Start date", align: "center", width: 100 },
    { name: "end_date", label: "End date", align: "center", width: 100 },
    { name: "duration", label: "Duration(Days)", width: 100, align: "center" }
  ];
  gantt.templates.task_text = function (start, end, task) {
    return `#${task.id} ${task.text}`;
  };

  initGanttChart();

  const tooltip = document.getElementById('tooltip');
  gantt.attachEvent("onMouseMove", function (id, e) {
    if (!id) {
      tooltip.style.display = "none";
      return;
    }
    const task = gantt.getTask(id);
    const startDate = task.start_date ? new Date(task.start_date).toLocaleDateString() : 'Not set';
    const endDate = task.end_date ? new Date(task.end_date).toLocaleDateString() : 'Not set';
    const duration = task.duration ? task.duration + ' days' : 'Not set';

    tooltip.innerHTML = `
    <strong>Task:</strong> ${task.text}<br>
    <strong>Start Date:</strong> ${startDate}<br>
    <strong>End Date:</strong> ${endDate}<br>
    <strong>Duration:</strong> ${duration}
  `;
    tooltip.style.display = "block";
    tooltip.style.top = e.clientY + 15 + 'px';
    tooltip.style.left = e.clientX + 5 + 'px';
  });
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<style>
h3 {
  text-align: center;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content input[type="checkbox"] {
  margin-right: 10px;
}

.dropdown-content label {
  display: block !important;
  padding: 5px;
  cursor: pointer;
}

.job-task {
  background-color: #ff9f89;
  border-color: #ff9f89;
}

.regular-task {
  background-color: #2196f3;
}

.job-task {
  background-color: #ff9f89;
  border-color: #ff9f89;
}

.regular-task {
  background-color: #2196f3;
}
</style>
