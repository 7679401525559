<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <button class="btn btn-primary rounded" @click="doClick">{{ props.text }}</button>
    <div v-if="isWaiting" class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</template>

<script setup lang="ts">
import { ref, defineProps } from 'vue';


const isWaiting = ref(false);

const props = defineProps<{
    onClick: (() => void) | (() => Promise<boolean>),
    waitText?: string,
    text: string
}>();

const doClick = () => {
    console.log("button clicked");
    isWaiting.value = true;
    const result = props.onClick();

    // Check if result is a Promise
    if (result && typeof result.then === 'function' && typeof result.catch === 'function') {
        result.then(() => {
            isWaiting.value = false;
        }).catch(() => {
            isWaiting.value = false;
        });
    } else {
        isWaiting.value = false;
    }
}


</script>