<template>
  <div class="home">
    <div v-if="!taskStore.isLoadingTaks">
      <div v-if="mostRecentTask" class="taskWrapper mostRecentTask">
        <h2>Last Task</h2>
        <component :is="isMobileView ? TaskInlineMobile : TaskInline" :task="mostRecentTask" />
        <div class="timer">
          <b>
            <TimeSince :taskId="mostRecentTask.id" :duration="formattedTaskDuration" :eventType="recentevent" />
          </b>
        </div>
      </div>
      <div v-else class="taskWrapper mostRecentTask">
        <h2>Last Task</h2>
        <h3>There are no recent active tasks.</h3>
      </div>
      <div class="taskWrapper">
        <h2>Recent Tasks</h2>
        <template v-if="otherRecentTasks.length > 0">
          <div v-for="task in otherRecentTasks" :key="task.id" class="element">
            <component :is="isMobileView ? TaskInlineMobile : TaskInline" :task="task" />
          </div>
        </template>
        <template v-else>
          <h3>There are no recent active tasks.</h3>
          <h4>To See The Most Recent Task You Must Start a Task First.</h4>
        </template>
      </div>
    </div>
    <div v-else class="loading-container"></div>
  </div>
</template>

<script setup lang="ts">
import TaskInline from '@/components/TaskInline.vue';
import TaskInlineMobile from '@/components/TaskInlineMobile.vue';
import TimeSince from '@/components/TimeSince.vue';
import { useTaskStore } from '@/stores/Tasks';
import { useEmployeeStore } from '@/stores/UserEmployee';
import { defineComponent, ref, onUnmounted, computed, onMounted, watch } from 'vue';

const taskStore = useTaskStore();
const employeeStore = useEmployeeStore();
const currentTime = ref(Date.now());

const intervalId = setInterval(() => {
  currentTime.value = Date.now();
}, 1000);

const isMobileView = ref(window.innerWidth < 768);
const updateView = () => {
  isMobileView.value = window.innerWidth < 768;
};


onMounted(() => {
  taskStore.getAllTasks();
  window.addEventListener('resize', updateView);
});

onUnmounted(() => {
  clearInterval(intervalId);
  window.removeEventListener('resize', updateView);
});

const sortedAndAssignedTasks = computed(() => {
  const currentUserID = employeeStore.currentUser?.id;
  return taskStore.sortedTasksByLatestEvent.filter(task =>
    task.assignments?.some(assignment => assignment.user === currentUserID)
  );
});

const mostRecentTask = computed(() => sortedAndAssignedTasks.value[0]);
const otherRecentTasks = computed(() => sortedAndAssignedTasks.value.slice(1, 4));

const formattedTaskDuration = computed(() => {
  const duration = mostRecentTask.value?.total_active_duration;
  if (duration) {
    const durationStr = typeof duration === 'number' ? duration.toString() : duration;
    const durationSeconds = parseDurationToSeconds(durationStr);
    return convertSecondsToHHMMSS(durationSeconds);
  }
  return '00:00:00';
});

function parseDurationToSeconds(durationStr: string): number {
  const parts = durationStr.match(/(\d+):(\d+):(\d+)/);
  if (parts) {
    const hours = parseInt(parts[1], 10);
    const minutes = parseInt(parts[2], 10);
    const seconds = parseInt(parts[3], 10);
    return hours * 3600 + minutes * 60 + seconds;
  }
  return 0;
}

function convertSecondsToHHMMSS(seconds) {
  const pad = (num) => String(num).padStart(2, '0');
  const hours = pad(Math.floor(seconds / 3600));
  const minutes = pad(Math.floor((seconds % 3600) / 60));
  const secs = pad(seconds % 60);
  return `${hours}:${minutes}:${secs}`;
}

const recentevent = computed(() => {
  const lastEvent = mostRecentTask.value?.events.slice(-1)[0]?.event_type || '';
  return lastEvent;
});

</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  margin: 2% 20%;
}

h2 {
  color: #007bff;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 0;
  text-align: center;
}

.taskWrapper {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  width: 100%;
  padding: 15px;
  transition: all 0.3s ease;
}

.mostRecentTask {
  background-color: #e9f5ff;
  border-left: 4px solid #007bff;
  border-right: 4px solid #007bff;
  padding-left: 12px;
}

.recent-task-label h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.timer {
  font-size: 40px;
  color: #007bff;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
}

.element {
  margin-top: 20px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-container::after {
  content: '';
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 767px) {
  .home {
    margin: 2% 5%;
    /* Adjust the margin for mobile view */
  }
}
</style>
