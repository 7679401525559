<template>
  <div class="home" data-bs-theme="light">
    <div class="user-info">
      <h3>{{ greeting }}, {{ currentUser?.user.full_name }}</h3>
      <h3>All Tasks</h3>
    </div>
    <div v-if="isLoadingTaks" class="loading-container"></div>
    <div v-else class="tasktablecontainer">
      <div v-if="currentUser?.user.is_superuser">
        <TaskList :tasks="all_tasks"
          :visibleColumns="['id', 'name', 'description', 'job_name', 'started', 'concluded', 'total_active_duration', 'labor_cost', 'assigned', 'actions']">
        </TaskList>
      </div>
      <div v-if="currentUser?.user.is_staff && !currentUser?.user.is_superuser">
        <TaskList :tasks="all_tasks"
          :visibleColumns="['id', 'name', 'description', 'job_name', 'started', 'concluded', 'total_active_duration', 'assigned', 'actions']">
        </TaskList>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TaskList from "@/components/TaskList.vue";
import { useEmployeeStore } from "@/stores/UserEmployee";
import { useTaskStore } from "@/stores/Tasks";
import { storeToRefs } from "pinia";
import { onMounted, computed, watch } from "vue";

const userStore = useEmployeeStore();
const taskStore = useTaskStore();
const { all_tasks, isLoadingTaks } = storeToRefs(taskStore);
const { currentUser } = storeToRefs(userStore);

const greeting = computed(() => {
  const hour = new Date().getHours();
  if (hour < 12) return 'Good Morning';
  if (hour < 18) return 'Good Afternoon';
  return 'Good Evening';
});

onMounted(() => {
  // console.log("component mounted");
  taskStore.getAllTasks();
});

watch(all_tasks, (newTasks) => {
  if (newTasks) {
    // You can perform additional actions here if needed,
    // such as logging or handling the data differently.
  }
}, { immediate: true });
</script>

<style scoped>
.home {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white !important;
  min-height: 100vh;
  padding-bottom: 30px;
}

.user-info {
  text-align: center;
}

.tasktablecontainer {
  width: 85%;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-container::after {
  content: '';
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
