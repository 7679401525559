<template>
  <div class="job-container" style="margin-top: 20px;">
    <div class="filtering-buttons">
      <button type="button" class="apple-button all-jobs" :class="{ 'active-filter': filterAllJobs }"
        @click="setFilterAll">
        Show All Jobs
      </button>
      <button type="button" class="apple-button completed-jobs" :class="{ 'active-filter': filterCompletedJobs }"
        @click="setFilterCompleted">
        Completed Jobs
      </button>
      <button type="button" class="apple-button active-jobs" :class="{ 'active-filter': filterActiveJobs }"
        @click="setFilterActive">
        Active Jobs
      </button>
      <button type="button" class="apple-button not-started-jobs" :class="{ 'active-filter': filerNotStartedJobs }"
        @click="setFilterNotStarted">
        Not Started
      </button>
      <div class="dropdown">
        <button type="button" class="apple-button sort-button" @click="toggleSortDropdown">
          Sort By <span v-if="sortKey">{{ sortKey }} {{ sortArrow }}</span>
        </button>
        <div class="dropdown-content" v-show="sortDropdownVisible">
          <button class="apple-button id" @click="updateSort('Id')">Id</button>
          <button class="apple-button name" @click="updateSort('Name')">Name</button>
          <button class="apple-button started" @click="updateSort('Started')">Started</button>
          <button class="apple-button cost" @click="updateSort('Cost')">Cost</button>
          <button class="apple-button duration" @click="updateSort('Duration')">Duration</button>
        </div>
      </div>
    </div>
    <div class="search-bar">
      <input class="search-input" type="text" v-model="searchQuery" placeholder="Search Name of Job...">
    </div>
    <div class="table-container">
      <table class="styled-table">
        <thead>
          <tr>
            <th scope="col" v-if="visibleColumns.includes('id')">Sr. No.</th>
            <th scope="col" v-if="visibleColumns.includes('name')">Job Name</th>
            <th scope="col" v-if="visibleColumns.includes('description')">Description</th>
            <th scope="col" v-if="visibleColumns.includes('task_count')">Number of Tasks<br>(Completed/Total)</th>
            <th scope="col" v-if="visibleColumns.includes('status')">Status</th>
            <th scope="col" v-if="visibleColumns.includes('total_active_duration')">Active Duration</th>
            <th scope="col" v-if="visibleColumns.includes('total_cost')">Labor Cost</th>
            <th scope="col" v-if="visibleColumns.includes('created_by')">Created By</th>
            <th scope="col" v-if="visibleColumns.includes('actions')">Action</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="paginatedJobs.length > 0">
            <tr v-for="(job, index) in paginatedJobs" :key="job.id" :class="getPriorityClass(job.priority)">
              <td v-if="visibleColumns.includes('id')">{{ initialSerialNumbers[job.id] }}</td>
              <td v-if="visibleColumns.includes('name')">{{ job.name }}</td>
              <td v-if="visibleColumns.includes('description')">
                <div class="job-description" @mouseover="showDescription = job.id" @mouseout="showDescription = null">
                  {{ truncateDescription(job.description) }}
                  <div v-if="showDescription === job.id" class="popover">Job Description:<br>{{ job.description }}</div>
                </div>
              </td>
              <td v-if="visibleColumns.includes('task_count')">Tasks: {{ completedTasks(job.tasks) }}/{{
        job.tasks.length
      }}</td>
              <td v-if="visibleColumns.includes('status')">
                <div v-if="!job.started">Not Started</div>
                <div v-else-if="job.started && !job.completed">Ongoing<br>Started on
                  <DateTimeBadge :time="job?.started" />
                </div>
                <div v-else-if="job.completed">Completed on<br>
                  <DateTimeBadge :time="job?.completed" />
                </div>
              </td>
              <td v-if="visibleColumns.includes('total_active_duration')">{{
        truncateMilliseconds(job.total_active_duration) }}</td>
              <td v-if="visibleColumns.includes('total_cost')"><b>$</b> {{ job.total_labor_cost }}</td>
              <td v-if="visibleColumns.includes('created_by')">
                <UserBadge :user-id="job?.created_by" />
              </td>
              <td v-if="visibleColumns.includes('actions')">
                <router-link class="apple-button view-button"
                  :to="{ name: 'job-view', params: { id: job.id } }">View</router-link>
                <button v-if="employeeStore.currentUser.user?.is_superuser || employeeStore.currentUser.user?.is_staff"
                  type="button" class="apple-button delete-button" @click="deleteJob(job.id, index)">Delete</button>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="8" class="no-jobs-message">
                <h5>No Jobs in this category.</h5>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <nav class="pagination-controls">
      <ul class="pagination">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <button class="page-link" @click="goToPrevPage" :disabled="currentPage === 1">Previous</button>
        </li>
        <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
          <button class="page-link" @click="goToPage(page)">{{ page }}</button>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <button class="page-link" @click="goToNextPage" :disabled="currentPage === totalPages">Next</button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script setup lang="ts">
import DateTimeBadge from '@/components/DateTimeBadge.vue';
import UserBadge from '@/components/UserBadge.vue'
import { ref, onMounted, defineProps, watch, computed, PropType } from 'vue';
import { Job, Task } from '@/types/Ops'
import { deleteFromUrl } from '@/api/api';
import { useEmployeeStore } from '@/stores/UserEmployee';

const props = defineProps({
  jobs: {
    type: Array as PropType<Job[]>,
    default: undefined
  },
  visibleColumns: {
    type: Array as PropType<string[]>,
    default: () => ['id', 'name', 'description', 'task_count', 'status', 'total_active_duration', 'total_cost', 'actions']

  }
})

const jobs = ref<Job[]>([]);
const employeeStore = useEmployeeStore();
let jobLoaded = false;
const showDescription = ref(null);
const sortKey = ref('id');
const sortOrder = ref(1);
const searchQuery = ref('');
const initialSerialNumbers = ref({});
const filterAllJobs = ref(true);
const filterCompletedJobs = ref(false)
const filterActiveJobs = ref(false);
const filerNotStartedJobs = ref(false);
const sortDropdownVisible = ref(false);
const pageSize = ref(15);
const currentPage = ref(1);

const toggleSortDropdown = () => {
  sortDropdownVisible.value = !sortDropdownVisible.value;
};

function updateSort(key) {
  if (sortKey.value === key) {
    sortOrder.value = -sortOrder.value;
  } else {
    sortKey.value = key;
    sortOrder.value = 1;
  }
  sortDropdownVisible.value = false;
}

const truncateDescription = (description) => {
  const maxLength = 50;
  if (description.length > maxLength) {
    return description.substring(0, maxLength) + '...';
  }
  return description;
}

function truncateMilliseconds(durationStr) {
  return durationStr.split('.')[0];
}

const paginatedJobs = computed(() => {
  const start = (currentPage.value - 1) * pageSize.value;
  const end = start + pageSize.value;
  return filteredJobs.value.slice(start, end);
});

const totalPages = computed(() => {
  return Math.ceil(filteredJobs.value.length / pageSize.value);
});

const serialNumbers = computed(() => {
  const map = {};
  sortedJobs.value.forEach((task, index) => {
    map[task.id] = index + 1;
  });
  return map;
});

const sortArrow = computed(() => {
  return sortOrder.value === 1 ? '↓' : '↑';
});

function durationStringToMilliseconds(durationStr) {
  let totalMilliseconds = 0;

  const parts = durationStr.split(' ');
  const timeParts = parts.pop().split(':');
  const dayPart = parts.pop() || '0';

  totalMilliseconds += parseInt(dayPart, 10) * 24 * 60 * 60 * 1000;
  totalMilliseconds += parseInt(timeParts[0], 10) * 60 * 60 * 1000;
  totalMilliseconds += parseInt(timeParts[1], 10) * 60 * 1000;
  totalMilliseconds += parseFloat(timeParts[2]) * 1000;

  return totalMilliseconds;
}

const sortedJobs = computed(() => {
  return jobs.value.slice().sort((a, b) => {
    if (sortKey.value === 'Name') {
      return sortOrder.value * a.name.localeCompare(b.name);
    } else if (sortKey.value === 'Started') {
      if (!a.started && !b.started) {
        return sortOrder.value * (a.id - b.id);
      }
      if (!a.started) return 1;
      if (!b.started) return -1;
      return sortOrder.value * (new Date(a.started).getTime() - new Date(b.started).getTime());
    } else if (sortKey.value === 'Cost') {
      const costA = a.total_labor_cost ?? 0;
      const costB = b.total_labor_cost ?? 0;
      return sortOrder.value * (costA - costB);
    } else if (sortKey.value === 'Duration') {
      const durationA = a.total_active_duration ? durationStringToMilliseconds(a.total_active_duration) : 0;
      const durationB = b.total_active_duration ? durationStringToMilliseconds(b.total_active_duration) : 0;
      return sortOrder.value * (durationA - durationB);
    }
    return sortOrder.value * (a.id - b.id);
  });
});

function completedTasks(tasks: Task[]): number {
  let concludedTasksCount = 0;
  tasks.forEach(task => {
    if (task.concluded !== null && task.concluded !== undefined) {
      concludedTasksCount++;
    }
  });
  return concludedTasksCount;
}

const getPriorityClass = (priority) => {
  switch (priority) {
    case 1: return 'low-priority';
    case 2: return 'medium-priority';
    case 3: return 'high-priority';
    default: return '';
  }
};

const filteredJobs = computed(() => {
  let filtered = sortedJobs.value;
  if (searchQuery.value) {
    const searchLower = searchQuery.value.toLowerCase();
    filtered = filtered.filter(job => {
      const nameMatch = job.name.toLowerCase().includes(searchLower);
      const descriptionMatch = job.description.toLowerCase().includes(searchLower);
      const workCenterMatch = job.work_center?.name?.toLowerCase().includes(searchLower) || false;
      return nameMatch || descriptionMatch || workCenterMatch;
    });
  }
  if (filterActiveJobs.value) {
    filtered = filtered.filter(job => job.started && (job.completed === null && !job.completed));
  } else if (filterCompletedJobs.value) {
    filtered = filtered.filter(job => job.completed !== null && job.completed !== undefined);
  } else if (filerNotStartedJobs.value) {
    filtered = filtered.filter(job => !job.started && !job.completed);
  }

  return filtered;
})

const goToPage = (pageNumber) => {
  currentPage.value = pageNumber;
};

const goToPrevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const goToNextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const setFilterAll = () => {
  filterAllJobs.value = true;
  filterActiveJobs.value = false;
  filterCompletedJobs.value = false;
  filerNotStartedJobs.value = false;
};

const setFilterActive = () => {
  filterAllJobs.value = false;
  filterActiveJobs.value = true;
  filterCompletedJobs.value = false;
  filerNotStartedJobs.value = false;
};

const setFilterCompleted = () => {
  filterAllJobs.value = false;
  filterActiveJobs.value = false;
  filterCompletedJobs.value = true;
  filerNotStartedJobs.value = false;
};

const setFilterNotStarted = () => {
  filterAllJobs.value = false;
  filterActiveJobs.value = false;
  filterCompletedJobs.value = false;
  filerNotStartedJobs.value = true;
}

const deleteJob = async (jobId, index) => {
  if (confirm('Are you sure you to delete this job?')) {

    try {
      await deleteFromUrl(`/ops/api/jobs/${jobId}/`);
      jobs.value.splice(index, 1);
    } catch (error) {
      console.error('Error deleting job:', error);
    }
  }
}

onMounted(() => {
  if (props.jobs) {
    jobs.value = props.jobs.filter(job => !job.name.includes("template"));
    // console.log(jobs.value)
    jobs.value = [...props.jobs].sort((a, b) => a.id - b.id);
    jobs.value.forEach((job, index) => {
      initialSerialNumbers.value[job.id] = index + 1;
    })
    jobLoaded = true;
  } else {
    console.log("Jobs are not loading either they are not provided or undefined !(JobList.vue)");
  }
})

watch(() => props.jobs, (newTasks) => {
  if (newTasks) {
    jobs.value = newTasks.filter(job => !job.name.includes("template"));
    jobs.value = jobs.value.sort((a, b) => a.id - b.id);
    initialSerialNumbers.value = {};
    jobs.value.forEach((job, index) => {
      initialSerialNumbers.value[job.id] = index + 1;
    });
  }
}, { immediate: true });

</script>

<style scoped>
.job-container {
  margin: 20px;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0.1, 0.1, 0.1, 0.2);
  overflow-y: auto;
  overflow-x: hidden;
}

.filtering-buttons {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.filtering-buttons .dropdown:last-child {
  margin-left: auto;
  position: relative;
}

.search-bar {
  color: black;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  padding: 10px;
  background-color: #f3f3f3;
  border: 1px solid #ddd;
  color: black;
  font-size: 16px;
  border-radius: 10px;
}

.table-container {
  overflow-x: auto;
}

.styled-table {
  width: 100%;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  font-size: 0.9em;
  border: 1px solid #ddd;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 10px;
}

.styled-table thead tr {
  background-color: rgb(75, 82, 219);
  color: #ffffff;
  text-align: left;
  border-radius: 10px;
  height: 70px;
}

.styled-table thead tr:first-child th:first-child {
  border-top-left-radius: 10px;
}

.styled-table thead tr:first-child th:last-child {
  border-top-right-radius: 10px;
}

.styled-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.styled-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 2px solid #dddddd;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #f3f3f3;
}

.styled-table tbody tr:hover {
  background-color: #e8e8e8;
}

.job-description {
  position: relative;
  cursor: pointer;
}

.job-description .popover {
  color: black;
  display: none;
  position: absolute;
  top: -5px;
  left: 100%;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 10px;
  z-index: 10;
  border-radius: 5px;
  width: 300px;
  word-wrap: break-word;
}

.job-description:hover .popover {
  display: block;
}

.apple-button {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px 10px 10px 10px;
  border: none;
  border-radius: 25px;
  background-color: #f7f4f4;
  color: black;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
  transform: scale(1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.apple-button.all-jobs:hover,
.apple-button.sort-button:hover,
.apple-button.id:hover,
.apple-button.name:hover,
.apple-button.cost:hover,
.apple-button.duration:hover,
.apple-button.view-button:hover,
.apple-button.started:hover {
  color: #FFFFFF;
  background-color: rgb(75, 82, 219);
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.apple-button.active-jobs:hover {
  color: #FFFFFF;
  background-color: rgb(219, 80, 75);
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.apple-button.completed-jobs:hover {
  color: #FFFFFF;
  background-color: rgb(102, 211, 102);
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.apple-button.not-started-jobs:hover {
  color: #000000;
  background-color: rgb(255, 255, 153);
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.apple-button.delete-button:hover {
  color: #FFFFFF;
  background-color: rgb(219, 80, 75);
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.apple-button.active-jobs:active {
  transform: scale(0.95);
}

.apple-button.completed-jobs:active {
  transform: scale(0.95);
}

.all-jobs.active-filter {
  color: #FFFFFF;
  background-color: rgb(75, 82, 219);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.active-jobs.active-filter {
  color: #FFFFFF;
  background-color: rgb(219, 80, 75);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.completed-jobs.active-filter {
  background-color: rgb(102, 211, 102);
}

.not-started-jobs.active-filter {
  background-color: rgb(255, 255, 153);
}

.pagination-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
}

.pagination {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0px;
}

.pagination .page-item {
  margin: 0 5px;
}

.pagination .page-link {
  border: 1px solid #007bff;
  padding: 5px 10px;
  color: #007bff;
  cursor: pointer;
}

.pagination .page-link:hover {
  background-color: #007bff;
  color: white;
}

.pagination .disabled .page-link {
  color: #ccc;
  cursor: not-allowed;
  border: 1px solid #ccc;
}

.pagination .active .page-link {
  background-color: #007bff;
  color: white;

}

.no-jobs-message {
  text-align: center;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgb(231, 231, 231);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
  padding: 10px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.low-priority {
  background-color: #e7f3fe;
  border-left: 4px solid #2d99ff;
  border-right: 4px solid #007bff;
}

.medium-priority {
  background-color: #fffbea;
  border-left: 4px solid #ffdf50;
  border-right: 4px solid #ffdf50;
}

.high-priority {
  background-color: #ffe3e3;
  border-left: 4px solid #ff4848;
  border-right: 4px solid #ff4848;
}

.low-priority td:first-child {
  border-left: 4px solid #2d99ff;
}

.low-priority td:last-child {
  border-right: 4px solid #007bff;
}

.medium-priority td:first-child {
  border-left: 4px solid #ffdf50;
}

.medium-priority td:last-child {
  border-right: 4px solid #ffdf50;
}

.high-priority td:first-child {
  border-left: 4px solid #ff4848;
}

.high-priority td:last-child {
  border-right: 4px solid #ff4848;
}
</style>
