import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import OpsHome from "@/views/OpsHome.vue"
import TaskView from "@/views/TaskView.vue";
import TaskListView from "@/views/TaskListView.vue";
import CreateTaskFromTemplate from "@/views/CreateTaskFromTemplate.vue";
import CreateJobFromTemplate from "@/views/CreateJobFromTemplate.vue";
import JobListView from "@/views/JobListView.vue";
import JobTemplate from "@/views/JobTemplate.vue"
import JobView from "@/views/JobView.vue"
import GanttChartView from "@/views/GanttChartView.vue"
import AdminDashBoardVue from "@/components/AdminDashBoard.vue";
import Calender from "@/components/Calender.vue";
import UserListView from "@/views/UserListView.vue";
import CreateUser from "@/views/CreateUser.vue";
import AddUserEmployee from "@/components/AddUserEmployee.vue";
import EditEmployeeDetails from "@/components/EditEmployeeDetails.vue"
import EmployeeDetail from "@/views/EmployeeDetail.vue";
import EditUserProfile from "@/views/EditUserProfile.vue";
import CreateJobTemplate from "@/views/CreateJobTemplate.vue";
import CreateTaskTemplate from "@/views/CreateTaskTemplate.vue";
import WorkAndCostCenter from '@/components/WorkAndCostCenter.vue';
import PieChartComponent from "@/components/PieChartComponent.vue"
import JobAndTaskTemplate from "@/views/JobAndTaskTemplate.vue";
import WorkAndCostCenterView from '@/views/WorkAndCostCenterView.vue';
import EmployeeTaskCreation from "@/views/EmployeeTaskCreation.vue";



const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/ops/",
    name: "opshome",
    component: OpsHome,
  },
  {
    path: "/ops/task/:id",
    name: "task-view",
    component: TaskView,
  },
  {
    path: "/ops/alltasks",
    name: "opsalltasks",
    component: TaskListView,
  },

  {
    path: '/ops/job-task-template',
    name: 'JobAndTaskTemplate',
    component: JobAndTaskTemplate,
  },
  {
    path: "/ops/create-task/:templateId",
    name: "createTask",
    component: CreateTaskFromTemplate
  },
    {
    path: "/ops/create-task-employee/:templateId",
    name: "createTaskEmployee",
    component: EmployeeTaskCreation
  },
  {
    path: "/ops/create-job/:templateId",
    name: "createJob",
    component: CreateJobFromTemplate
  },
  {
    path: '/ops/jobtemplates/:id',
    name: "JobTemplate",
    component: JobTemplate,
  },
  {
  path: '/ops/create-job-template',
  name: 'CreateJobTemplate',
  component: CreateJobTemplate
  },
  {
    path: '/ops/create-task-template',
    name: 'CreateTaskTemplate',
    component: CreateTaskTemplate
  },

  {
    path: "/ops/alljobs",
    name: "JobListView",
    component: JobListView,
  },
  {
    path: "/ops/job/:id",
    name: "job-view",
    component: JobView,
  },
  {
    path: "/ops/gantt-chart",
    name: "gantt-chart",
    component: GanttChartView,
  },
  {
    path: "/ops/admindashboard",
    name: "admindashboard",
    component: AdminDashBoardVue,
  },
  {
    path: "/ops/calender",
    name: "Calender",
    component: Calender,
  },
  {
    path: "/ops/allusers",
    name: "allusers",
    component: UserListView,
  },
  {
    path: "/ops/create-user",
    name: "create-user",
    component: CreateUser,
  },
  {
    path: "/ops/Adduseremployee",
    name: "UserEmployee",
    component: AddUserEmployee,
  },
  
  {
    path: '/ops/employee-detail/:userEmployeeId',
    name: 'employeeDetail',
    component: EmployeeDetail,
    props: true,
  },

{
  path: '/ops/employee-details/:userEmployeeId', 
  name: 'edit-employeeDetails',
  component: EditEmployeeDetails,
  props: true,
},

  {
    path: '/ops/work-and-cost-center',
    name: 'WorkAndCostCenter',
    component: WorkAndCostCenterView,
  },

  {
    path: '/ops/work-cost-center',
    name: 'WorkCostCenter',
    component: WorkAndCostCenter,
  },
  {
    path: '/ops/edit-profile',
    name: 'edit-profile',
    component: EditUserProfile
  },
  {
    path: "/ops/piechart",
    name: "piechart",
    component: PieChartComponent,
  }
];

const router = createRouter({
  history: createWebHistory("/app/"),
  routes,
});

export default router;
