import { UserEmployee } from "@/types/Ops";
import { Job, JobTemplate, TaskTemplate, Task, GanttTask } from "@/types/Ops";

//CRUD api's

const csrfcookie = function () {
  try {
    let cookieValue = null;
    const name = 'csrftoken';
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) == (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  } catch (error) {
    console.error("Error while retrieving CSRF token:", error);
    throw error;
  }
};

export const fetchFromUrl = async <T>(url: string) => {
    try {
      const resp = await fetch(url);
      if (resp.status === 200) {
        return await resp.json() as Promise<T>;
      } else {
        throw new Error(`HTTP error! Status: ${resp.status}`);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  

  
export const patchToUrl = async <T>(url: string, data: T) => {
    try{
        const resp = await fetch(url, {
        method: "PATCH",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json',
            "X-CSRFToken": csrfcookie() ?? ""
        }
    });
    if(!resp.ok){
        const responseBody = await resp.text();
        console.error("Server responded with error status:", resp.status);
        console.error("Server response body:", responseBody);
        throw new Error(`HTTP Error api.ts patch to URL! Status:${resp.status}`)
    }
    return await (resp.json() as Promise<T>);
    } catch(error){
      if (error.response) {
        console.error('Backend returned:', error.response.data);
    }
    console.error("Failed to update assignment in backend:", error);
    }
}

export const postToUrl = async <T>(url: string, data: T) => {
  try {
      // console.log('URL for POST request:', url);

      const resp = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          body: JSON.stringify(data),
          headers: {
              "Content-Type": "application/json",
              'Accept': "application/json",
              "X-CSRFToken": csrfcookie() ?? ""
          }
      });

      if (!resp.ok) {
          const responseBody = await resp.text();
          console.error("Server responded with error status:", resp.status);
          console.error("Server response body:", responseBody);
          throw new Error(`HTTP error api.ts! status:${resp.status}. Response body: ${responseBody}`);
      }
      return await (resp.json() as Promise<T>);
  } catch (error) {
      console.error("Fetch error: ", error);
      throw error;
  }
}

export const deleteFromUrl = async (url: string) => {
  try {
      const resp = await fetch(url, {
          method: "DELETE",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
              "Content-Type": "application/json",
              'Accept': 'application/json',
              "X-CSRFToken": csrfcookie() ?? ""
          }
      });
      if (resp.ok) {
          if (resp.status !== 204) { 
              return await resp.json();
          }
          return true;
      } else {
          throw new Error(`HTTP Error api.ts delete from URL! Status: ${resp.status}`);
      }
  } catch (error) {
      console.error("Fetch error in deleteFromUrl:", error);
      throw error;
  }
}


//UserEmployee Api's

export const fetchAllEmployees = async (): Promise<UserEmployee[]> => {
  return fetchFromUrl<UserEmployee[]>("/ops/api/users/");
};

export const fetchEmployeeById = async (employeeId: number): Promise<UserEmployee> => {
  const response = await fetchFromUrl<UserEmployee>(`/ops/api/users/${employeeId}/`);
  return response;
};

// export const fetchCurrentUser = async (): Promise<UserEmployee> => {
//   const response = await fetchFromUrl<UserEmployee>('/ops/api/user/current/');
//   return response;
// };


//Job Api's

export const fetchAllJobTemplates = async (): Promise<JobTemplate[]> => {
  const response = await fetchFromUrl('/ops/api/jobtemplates/');
  return response as JobTemplate[];
};

export const fetchJobTemplate = (id: number) => {
    return fetchFromUrl<JobTemplate>("/ops/api/jobtemplates/" + id + "/");
}

export const fetchLinkedJobs = (templateId: number) => {
  return fetchFromUrl<Job[]>("/ops/api/linkedjobs/" + templateId + "/");
};

export const fetchAllJobs = async (): Promise<Job[]> => {
  return fetchFromUrl<Job[]>("/ops/api/jobs/")
  .then(jobs => {
    return jobs;
});
}

export const fetchJobDetails = async (jobId: number): Promise<Job> => {
  const response = await fetchFromUrl(`/ops/api/jobs/${jobId}/`);
  return response as Job;
};

export const fetchJobDetailsWithTasks = async (jobId: number): Promise<Job> => {
  const response = await fetchFromUrl<Job>(`/ops/api/jobs/`);
  return response;
};


//Task Api's

export const fetchAllTaskTemplates = (): Promise<TaskTemplate[]> => {
    return fetchFromUrl<TaskTemplate[]>("/ops/api/tasktemplates/");
}

export const fetchTaskTemplate = (id: number) => {
    return fetchFromUrl<TaskTemplate>("/ops/api/tasktemplates/" + id + "/");
}

export function fetchAllTaks(): Promise<Task[]>{
  return fetchFromUrl<Task[]>("/ops/api/tasks/")
   .then(tasks=>{
    return tasks;
   });
}

export async function fetchTaskById(id: number): Promise<Task> {
  const response = await fetchFromUrl(`/ops/api/tasks/${id}/`);
  return response as Task;
}

export const fetchTasksByJobId = async (jobId: number): Promise<Task[]> => {
  const response = await fetchFromUrl(`/ops/api/tasks/?job_id=${jobId}`);
  return response as Task[];
};

export function fetchAllTasksWithAssignments(): Promise<Task[]> {
  return fetchFromUrl<Task[]>("/ops/api/tasks/?include=assignments")
   .then(tasks => {
    return tasks;
   });
}


// Task Assignment Api's

export const deleteTaskAssignment = async (assignmentId: number) => {
  return deleteFromUrl(`/ops/api/taskassignments/${assignmentId}/`);
}

export const createTaskAssignment = async (taskId: number, userId: number) => {
  const data = {
      task: taskId,
      user: userId
  };
  return postToUrl("/ops/api/taskassignments/", data);
}


// Util Api's

export const fetchGanttChartData = async (): Promise<GanttTask[]> => {
  const response = await fetchFromUrl<GanttTask[]>('/ops/api/gantt-chart-data/');
  return response;
};

export const fetchCalendarData = async (): Promise<{jobs: Job[], tasks: Task[]}> => {
  return fetchFromUrl<{jobs: Job[], tasks: Task[]}>('/ops/api/calendar-data/');
};

export const fetchWorkCenters = async () => {
  try {
    const response = await fetch('/ops/api/workcenters/');
    if (response.ok) {
      return await response.json();
    } else {
      console.error('Failed to fetch work centers');
      return [];
    }
  } catch (error) {
    console.error('Network error:', error);
    return [];
  }
};

export const fetchCostCenters = async () => {
  try {
    const response = await fetch('/ops/api/costcenters/'); // Adjust the endpoint as needed
    if (response.ok) {
      return await response.json();
    } else {
      console.error('Failed to fetch cost centers');
      return [];
    }
  } catch (error) {
    console.error('Network error:', error);
    return [];
  }
};
